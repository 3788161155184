/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
/* eslint-disable vars-on-top */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// Api
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MUIDataTable from 'mui-datatables';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';

import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function TerminalTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const options = {
    print: false,
    selectableRows: 'none',
    resizableColumns: true,
  };

  const terminalheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        } else if (
          Date.parse(text) &&
          record.name !== 'Terminal Id' &&
          record.name !== 'TotalNumberOfTanks' &&
          record.name !== 'TotalCapacityBarrels'
        ) {
          return <>{dateFormat(text)}</>;
        } else {
          return <>{text}</>;
        }
      },
    },
  ];

  // Fetch Terminal Header
  /**
   * Fetches the terminal header data from the database and updates the component state.
   * @returns {Promise<void>}
   */
  const fnFetchTerminalHeader = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'terminalheader',
        id: props.TerminalId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        fnFetchGasDailyFlowYears(props.TerminalId, 'Terminal');

        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        const metadata = Object.keys(response.data[0].data[0]);

        const data = [];

        const CommodityHandled = [];

        metadata.forEach((element) => {
          if (element !== 'Terminal Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              if (
                element === 'CommodityHandled1' ||
                element === 'CommodityHandled2' ||
                element === 'CommodityHandled3' ||
                element === 'CommodityHandled4'
              ) {
                CommodityHandled.push(response.data[0].data[0][element]);
              } else {
                data[element] = response.data[0].data[0][element];
                // data.push({ name: element, value: response.data[0].data[0][element] });
              }
            }
          }

          // if (element === 'Longitude') {
          // 	data.push({
          // 		name: 'Google Maps',
          // 		value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          // 	});

          // }
        });

        if (CommodityHandled.length > 0) {
          data['CommodityHandled'] = CommodityHandled.join(' , ');
        }

        setFacilityHeader(data);

        const facility = [
          {
            Status: response.data[0].data[0].Status,
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Terminal Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0].Latitude,
            Longitude: response.data[0].data[0].Longitude,
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);

        fnFetchTerminalUnits();
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * This function is used to process the data.
   * It is called when the component is mounted.
   */
  const fnProcessData = () => {
    // //console.log(props.GasPlantId);

    // Fetch the terminal header data
    fnFetchTerminalHeader();
  };

  const terminalunitcolumns = [
    {
      name: 'TerminalUnitName',
      label: 'TerminalUnitName',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'CommodityHandled',
      label: 'CommodityHandled',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalCapacityBarrels',
      label: 'CapacityBarrels',
      options: {
        filter: true,
      },
    },
    {
      name: 'CommissioningDate',
      label: 'Commissioning Date',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value !== null ? dateFormat(value) : ''}</>;
        },
      },
    },
    {
      name: 'DecommissioningDate',
      label: 'Decommissioning Date',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value !== null ? dateFormat(value) : ''}</>;
        },
      },
    },
    {
      name: 'TotalNumberOfTanks',
      label: 'Total No. Of Tanks',
      options: {
        filter: true,
      },
    },
  ];

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        //   return (
        //     <Fragment>
        //       {value.data[0] === 0 ? 'False' : 'True'}
        //     </Fragment>
        //   );
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  const [terminalunit, setTerminalUnit] = React.useState([]);

  // Fetch Terminal Unit
  /**
   * Fetches the terminal unit data for the current terminal.
   *
   * This function is called when the component mounts and when the
   * terminal id changes.
   */
  const fnFetchTerminalUnits = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'terminalunit',
        id: props.TerminalId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        setTerminalUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Fetch GasDailyFlowYears
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowYears = async (id, type) => {
    dispatch(displaySpinner(true));

    try {
      setAssetType(type);

      const queryDetails = {
        id,
        type,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // setStatusBase({ show: true, message: 'An error occurred during execution.', variant: 'error' });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Toggles the state of the gas flow expanded section.
   * Used to expand or collapse the gas flow section.
   *
   * @return {void}
   */
  const fnExpandGasFlow = () => {
    /**
     * Toggles the state of the gas flow expanded section.
     * If the section is expanded, it will be collapsed and vice versa.
     *
     * @type {void}
     */
    setGasFlowExpanded(!gasflowexpanded);
  };

  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  /**
   * Fetches gas daily flow data from the server based on the provided value.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {Array} value - The array of years to fetch data for.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowData = async (value) => {
    if (value && value.length > 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.TerminalId,
          type: assettype,
          year: value.join(),
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };
        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="Terminal" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>
      <Accordion sx={{ m: 0, mt: '10px' }} expanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Terminal Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {terminalunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable data={terminalunit} columns={terminalunitcolumns} options={options} />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>
      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
