/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

// Api
import axios from 'axios';

export default function CCUSTemplateComp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  /**
   * Resets the error message state to hide the error message and clears the message and variant.
   *
   * @return {void} This function does not return anything.
   */
  const fnShowErrorMessage = () => {
    // Reset the error message state to hide the error message
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const ccusheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text)) {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  /**
   * Fetches the header data for a CCUS facility.
   *
   * @return {Promise<void>} - A Promise that resolves when the data is fetched successfully.
   */
  const fnFetchCCUSHeader = async () => {
    // Show the spinner while fetching data
    dispatch(displaySpinner(true));

    const queryDetails = {
      index: 'ccusheader',
      id: props.facilityid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Make a POST request to the API to fetch the header data
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner after data is fetched
      dispatch(displaySpinner(false));

      // Check for errors in the response data
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state if an error occurred
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the error message state if no records were found
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Define the facility types and metadata
        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];
        const metadata = Object.keys(response.data[0].data[0]);

        const data = [];

        // Iterate over the metadata and populate the data array
        metadata.forEach((element) => {
          if (element !== 'CCUS Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
            }
          }
        });

        // Set the facility header state
        setFacilityHeader(data);

        // Create the facility data array and set the facility data state
        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['CCUS Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Process the data by fetching the CCUS header.
   */
  const fnProcessData = () => {
    // Call function to fetch CCUS header data
    fnFetchCCUSHeader();
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && <FacilityHeaderComp facilitydata={facilitydata} type="CCUS" />}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
