import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CCUSTemplateComp from './templates/facilitytypes/CCUSTemplateComp';
import CO2StorageTemplateComp from './templates/facilitytypes/CO2StorageTemplateComp';
import CompressorStationTemplateComp from './templates/facilitytypes/CompressorStationTemplateComp';
import GasPlantTemplateComp from './templates/facilitytypes/GasPlantTemplateComp';
import GasStorageTemplateComp from './templates/facilitytypes/GasStorageTemplateComp';
import HydrogenPlantTemplateComp from './templates/facilitytypes/HydrogenPlantTemplateComp';
import IndustrialPlantTemplateComp from './templates/facilitytypes/IndustrialPlantTemplateComp';
import LiquefactionTemplateComp from './templates/facilitytypes/LiquefactionTemplateComp';
import PipelineAssemblyTemplateComp from './templates/facilitytypes/PipelineAssemblyTemplateComp';
import PowerPlantTemplateComp from './templates/facilitytypes/PowerPlantTemplateComp';
import PumpingStationTemplateComp from './templates/facilitytypes/PumpingStationTemplateComp';
import RefineryTemplateComp from './templates/facilitytypes/RefineryTemplateComp';
import TerminalTemplateComp from './templates/facilitytypes/TerminalTemplateComp';
import EmissionFacilityTemplateComp from './templates/facilitytypes/EmissionFacilityComp';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import { RESET_PIPELINETEMPLATEDETAILS, RESET_TEMPLATEDETAILS } from '../../redux/constants';
import { GAS_STORAGE } from '../../constants/facilitytypes';
import PipelineFacilityComp from './PipelineFacilityComp';
import PipelineTemplateComp from './templates/facilitytypes/PipelineTemplateComp';
import GatheringSystemTemplateComp from './templates/facilitytypes/GatheringSystemTemplateComp';

const useStyles = makeStyles((theme) => ({
  fullheight: {
    height: '100%',
  },
  componentappBar: {
    position: 'relative',
  },
  componenttitle: {
    marginLeft: '2px',
    color: '#ffffff',
    flex: 1,
  },
  closeicon: {
    color: theme.palette.text.primary,
  },
  main: {
    background: `${theme.palette.background.default} !important`,
    padding: '10px !important',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function FacilityInfoComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const templatevalues = useSelector((state) => state.FacilityReducer.templatevalues);

  // console.log('Template Values ' + JSON.stringify(templatevalues));

  const [loading, setLoading] = React.useState(true);
  const [componentwindow, setComponentWindow] = React.useState(false);

  const fnOpenComponent = () => {
    // this.setState({ componentwindow: true, selectedgasplantid: record.GasPlantId });
    setComponentWindow(true);
  };

  const fnCloseComponent = () => {
    dispatch({ type: RESET_TEMPLATEDETAILS });
    // dispatch({ type: RESET_PIPELINETEMPLATEDETAILS });
    // if (pipelinetemplatevalues === null) {
    // 	dispatch({ type: RESET_TEMPLATEDETAILS });
    // }
    setComponentWindow(false);
  };

  useEffect(() => {
    // console.log('FacilityComponent Loaded')
    setLoading(false);
  }, []);

  return (
    <>
      {templatevalues !== undefined && templatevalues !== null && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              {templatevalues !== undefined && templatevalues !== null ? (
                <Typography variant="h5" color="textprimary">
                  {templatevalues.index} Details
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === GAS_STORAGE ? (
            <GasStorageTemplateComp GasStorageId={templatevalues.id} />
          ) : null}

          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Gas Plant' ? (
            <GasPlantTemplateComp GasPlantId={templatevalues.id} />
          ) : null}

          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Refinery' ? (
            <RefineryTemplateComp RefineryId={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Liquefaction' ? (
            <LiquefactionTemplateComp LiquefactionId={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Terminal' ? (
            <TerminalTemplateComp TerminalId={templatevalues.id} />
          ) : null}

          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Compressor Station' ? (
            <CompressorStationTemplateComp CSId={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Pumping Station' ? (
            <PumpingStationTemplateComp PSId={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'CCUS' ? (
            <CCUSTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'CO2 Storage' ? (
            <CO2StorageTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Hydrogen Plant' ? (
            <HydrogenPlantTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Industrial Plant' ? (
            <IndustrialPlantTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Power Plant' ? (
            <PowerPlantTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'Pipeline Assembly' ? (
            <PipelineAssemblyTemplateComp PAId={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'EmissionFacilities' ? (
            <EmissionFacilityTemplateComp facilityid={templatevalues.id} />
          ) : null}
          {templatevalues !== undefined &&
          templatevalues !== null &&
          templatevalues.index === 'GatheringSystem' ? (
            <GatheringSystemTemplateComp facilityid={templatevalues.id} />
          ) : null}
        </>
      )}
    </>
  );
}
