/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';



import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import linq from 'linq';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

// Api
import { GATHERING_SYSTEM } from '../../../../constants/facilitytypes';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { PipelineTableOptions } from '../../../../constants/muitable';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  templatecontainer: {
    padding: '10px',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
  embedcontainer: {
    height: '900px',
    width: '100%',
  },
}));

export default function GatheringSystemTemplateComp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bottomRef = React.useRef(null);
  const classes = useStyles();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  // Resets the error message state to hide the error message and clears the message and variant.
  const fnShowErrorMessage = () => {
    // Reset the error message state to hide the error message
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const gatheringsystemheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text)) {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  // Fetches the header data for a Gathering System facility.
  const fnFetchGSHeader = async () => {
    // Show the spinner while fetching data
    dispatch(displaySpinner(true));

    const queryDetails = {
      index: 'gsheader',
      id: props.facilityid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Make a POST request to the API to fetch the header data
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner after data is fetched
      dispatch(displaySpinner(false));

      // Check for errors in the response data
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state if an error occurred
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the error message state if no records were found
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Define the facility types and metadata
        const facilitytypes = [
          'LifeCycleStatus',
          'Gathering System',
          'P5 Number',
          'T4 Permit',
          'Operator Name',
          'Owner Name',
          'Commodity Handled',
        ];
        const metadata = Object.keys(response.data[0].data[0]);

        const data = [];

        // Iterate over the metadata and populate the data array
        metadata.forEach((element) => {
          if (element !== GATHERING_SYSTEM && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
            }
          }
        });

        // Set the facility header state
        setFacilityHeader(data);

        // Create the facility data array and set the facility data state
        const facility = [
          {
            LifeCycleStatus: response.data[0].data[0]['LifeCycleStatus'],
            'Gathering System': response.data[0].data[0]['Gathering System'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'P5 Number': response.data[0].data[0]['P5 Number'],
            'T4 Permit': response.data[0].data[0]['T4 Permit'],
            // CountyCode: response.data[0].data[0]['CountyCode'],
            'Commodity Handled': response.data[0].data[0]['Commodity Handled'],
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Process the data by fetching the Gathering System header.
  const fnProcessData = () => {
    // Call function to fetch Gathering System header data
    fnFetchGSHeader();
  };

  const gssectionoptions = PipelineTableOptions();

  const gssectioncolumns = [
    {
      name: 'Id',
      options: {
        filter: false,
        display: false,
        download: false,
      },
    },
    {
      name: 'SubGatheringSystem',
      label: 'Sub Gathering System',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnNavigateToSegment(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'SegmentStatus',
      label: 'SegmentStatus',
      options: {
        filter: true,
      },
    },
    {
      name: 'Diameter',
      options: {
        download: true,
        filter: true,
      },
    },
    {
      name: 'Miles',
      options: {
        download: true,
        filter: true,
      },
    },
    {
      name: 'Commodity',
      options: {
        download: true,
        filter: true,
      },
    },
  ];

  /**
   * Fetches gathering system section data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [gssection, setGSSection] = React.useState([]);
  const fnFetchGSSection = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gssection',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSSection(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse GS Section View
  const [gssectionsexpanded, setGSSectionExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the gathering system sections and, if necessary,
   * triggers the fetch of gathering system sections.
   */
  const fnExpandGSSections = () => {
    // Toggle the expansion state.
    setGSSectionExpanded(!gssectionsexpanded);

    // If the sections are not expanded and there are no sections to display,
    // fetch the gathering system sections.
    if (!gssectionsexpanded && gssection.length === 0) {
      fnFetchGSSection();
    }
  };

  const gsconnscolumns = [
    {
      name: 'PipelineHeaderId',
      options: {
        filter: false,
      },
    },
    {
      name: 'PipelineName',
      label: 'Pipeline Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityId',
      label: 'FacilityId',
      options: {
        display: false,
      },
    },
    {
      name: 'Facility Type',
      options: {
        download: true,
        filter: true,
      },
    },
    {
      name: 'FacilityName',
      label: 'Facility Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperatorName',
      label: 'Operator Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'OwnerName',
      label: 'Owner Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'StateName',
      label: 'StateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'CountyName',
      label: 'CountyName',
      options: {
        filter: true,
      },
    },
    {
      name: 'BasinName',
      label: 'BasinName',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches gathering system connections data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [gsconns, setGSConns] = React.useState([]);
  const fnFetchGSConns = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsconnections',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSConns(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse GS Section View
  const [gsconnsexpanded, setGSConnsExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the gathering system connections and, if necessary,
   * triggers the fetch of gathering system connections.
   */
  const fnExpandGSConns = () => {
    // Toggle the expansion state.
    setGSConnsExpanded(!gsconnsexpanded);

    // If the connections are not expanded and there are no connections to display,
    // fetch the gathering system connections.
    if (!gsconnsexpanded && gsconns.length === 0) {
      fnFetchGSConns();
    }
  };

  const gsleasescolumns = [
    {
      name: 'DistrictCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LeaseNumber',
      label: 'LeaseNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'LeaseName',
      label: 'LeaseName',
      options: {
        filter: true,
      },
    },
    {
      name: 'FieldNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'FieldName',
      label: 'FieldName',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperatorName',
      options: {
        filter: true,
      },
    },
    {
      name: 'Type',
      label: 'Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'Oil_Gas',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches gathering system leases data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [gsleases, setGSLeases] = React.useState([]);
  const fnFetchGSLeases = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsleases',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSLeases(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse GS Section View
  const [gsleasesexpanded, setGSLeasesExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the gathering system leases and, if necessary,
   * triggers the fetch of gathering system leases.
   */
  const fnExpandGSLeases = () => {
    // Toggle the expansion state.
    setGSLeasesExpanded(!gsleasesexpanded);

    // If the sections are not expanded and there are no leases to display,
    // fetch the gathering system leases.
    if (!gsleasesexpanded && gsleases.length === 0) {
      fnFetchGSLeases();
    }
  };

  const gswellprodcolumns = [
    {
      name: 'LeaseNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'APINumber',
      label: 'APINumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'WellNumber',
      label: 'WellNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasMCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasInjectedMCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'CondensateBBL',
      options: {
        filter: true,
      },
    },
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },
  ];

  const gswelldispcolumns = [
    {
      name: 'LeaseNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'APINumber',
      label: 'APINumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'WellNumber',
      label: 'WellNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasWellDispositionCodeDesc',
      label:'DispositionCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasDispositionAmountMCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },
  ];

  const [gaswellprodyears, setGasWellProdYears] = React.useState([]);

  const [gsgaswellprodselectedyear, setGSGasWellProdSelectedYear] = React.useState('');
  const fnFetchGSWellProdYearMonths = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsgaswellprod',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else if (response.data[0].data.gaswellproduction) {
        setGasWellProdYears(response.data[0].data.gaswellproduction);
        setGSGasWellProdSelectedYear(response.data[0].data.gaswellproduction[0]);
        fnFetchGSWellProd(response.data[0].data.gaswellproduction[0]);
        fnFetchGSWellDisp(response.data[0].data.gaswellproduction[0]);
      } else {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches gathering system well production data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [gswellprod, setGSWellProd] = React.useState([]);
  const fnFetchGSWellProd = async (selectedyear) => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsgaswellprod',
        id: props.facilityid,
        year: selectedyear,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSWellProd(response.data[0].data);
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gswelldisp, setGSWellDisp] = React.useState([]);
  const fnFetchGSWellDisp = async (selectedyear) => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsgaswelldisp',
        id: props.facilityid,
        year: selectedyear,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSWellDisp(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse GS Well Production View
  const [gswellprodexpanded, setGSWellProdExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the gathering system well production and, if necessary,
   * triggers the fetch of gathering system well production.
   */
  const fnExpandGSWellProd = () => {
    // Toggle the expansion state.
    setGSWellProdExpanded(!gswellprodexpanded);

    // If the sections are not expanded and there are no leases to display,
    // fetch the gathering system leases.
    if (!gswellprodexpanded && gaswellprodyears.length === 0) {
      fnFetchGSWellProdYearMonths();
    }
  };

  const gswellscolumns = [
    {
      name: 'WellInfoId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'APINumber',
      label: 'APINumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'WellNumber',
      label: 'WellNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'Latitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'Longitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperatorName',
      label: 'OperatorName',
      options: {
        filter: true,
      },
    }
  ];

  /**
   * Fetches gathering system leases data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [gswells, setGSWells] = React.useState([]);
  const fnFetchGSWells = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gswells',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSWells(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse GS Section View
  const [gswellsexpanded, setGSWellsExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the gathering system wells and, if necessary,
   * triggers the fetch of gathering system wells.
   */
  const fnExpandGSWells = () => {
    // Toggle the expansion state.
    setGSWellsExpanded(!gswellsexpanded);

    // If the sections are not expanded and there are no wells to display,
    // fetch the gathering system wells.
    if (!gswellsexpanded && gswells.length === 0) {
      fnFetchGSWells();
    }
  };

  const [focusonmap, setFocusOnMap] = React.useState({
    Latitude: 0,
    Longitude: 0,
  });
  const fnNavigateToSegment = (rowData) => {
    // setFocusOnMap({
    //   Latitude: rowData[10],
    //   Longitude: rowData[9]
    // })
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp
            facilitydata={facilitydata}
            gsid={props.facilityid}
            focusonmap={focusonmap}
            type="Gathering System"
          />
        )}
      </Box>
      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={gssectionsexpanded}
        onChange={fnExpandGSSections}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Gathering System Sections</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={gssection} columns={gssectioncolumns} options={gssectionoptions} />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ m: 0, mt: '10px' }} expanded={gswellsexpanded} onChange={fnExpandGSWells}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Wells</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={gswells} columns={gswellscolumns} options={gssectionoptions} />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ m: 0, mt: '10px' }} expanded={gsconnsexpanded} onChange={fnExpandGSConns}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Connections</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={gsconns} columns={gsconnscolumns} options={gssectionoptions} />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ m: 0, mt: '10px' }} expanded={gsleasesexpanded} onChange={fnExpandGSLeases}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>P5 Number Leases</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={gsleases} columns={gsleasescolumns} options={gssectionoptions} />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={gswellprodexpanded}
        onChange={fnExpandGSWellProd}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Well Production</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
              <Autocomplete
                disablePortal
                value={gsgaswellprodselectedyear}
                id="combo-box-demo"
                options={gaswellprodyears}
                onChange={(event, newValue) => {
                  fnFetchGSWellProd(newValue);
                  fnFetchGSWellDisp(newValue);
                }}
                fullWidth
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select Year"
                    aria-label="Select Year"
                  />
                )}
              />
            </Grid>
          </Grid>

          {gswellprod.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={gswellprod}
                columns={gswellprodcolumns}
                options={gssectionoptions}
              />
            </ThemeProvider>
          )}
          {gswelldisp.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={gswelldisp}
                columns={gswelldispcolumns}
                options={gssectionoptions}
              />
            </ThemeProvider>
          )}
          <div ref={bottomRef} />
        </AccordionDetails>
      </Accordion>
      {/* <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper> */}

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
