export const DBConfig = {
    name: 'Aura',
    version: 1,
    objectStoresMeta: [
      {
        store: 'reduxdata',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'data', keypath: 'data', options: { unique: false } }
        ]
      }
    ]
  };

  // Function to generate a unique tab ID
const generateTabId = () => `tab-${Math.random().toString(36).substr(2, 9)}`;

export const createDbConfig = () => {

  const id = generateTabId();

  return {
    name: id,
    version: 1,
    objectStoresMeta: [
      {
        store: `reduxdata_${id}`, //`reduxdata`,
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'data', keypath: 'data', options: { unique: false } }
        ]
      }
    ]
  };
};