/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// Api
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MUIDataTable from 'mui-datatables';
import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';
import { TableOptions } from '../../../../constants/muitable';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function PowerPlantTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const options = TableOptions();

  const facilityheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text)) {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  /**
   * Fetches the Power Plant Header from the API and sets the facility header and facility data state.
   * Also calls the fnFetchGasDailyFlowYears function to fetch daily flow data.
   * @async
   */
  const fnFetchFacilityHeader = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'powerplantheader',
        id: props.facilityid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        fnFetchGasDailyFlowYears(props.facilityid, 'Power Plant');

        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        const metadata = Object.keys(response.data[0].data[0]);

        const data = {};

        metadata.forEach((element) => {
          if (element !== 'Power Plant Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
              // data.push({ name: element, value: response.data[0].data[0][element] });
            }
          }

          // if (element === 'Longitude') {
          // 	data.push({
          // 		name: 'Google Maps',
          // 		value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          // 	});

          // }
        });

        setFacilityHeader(data);

        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Power Plant Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches the facility header data and updates the facility data state.
   *
   * @returns {void}
   */
  const fnProcessData = () => {
    // Fetch the facility header data
    fnFetchFacilityHeader();
  };

  const powerplantunitcolumns = [
    {
      name: 'PowerPlantUnitName',
      label: 'PowerPlantUnitName',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'Technology',
      label: 'Technology',
      options: {
        filter: true,
      },
    },
    {
      name: 'PrimeMoverCode',
      label: 'PrimeMoverCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'PrimeMoverDesc',
      label: 'PrimeMoverDesc',
      options: {
        filter: true,
      },
    },
    {
      name: 'NameplateCapacityMW',
      label: 'NameplateCapacityMW',
      options: {
        filter: true,
      },
    },
    {
      name: 'SummerCapacityMW',
      label: 'SummerCapacityMW',
      options: {
        filter: true,
      },
    },
    {
      name: 'WinterCapacityMW',
      label: 'WinterCapacityMW',
      options: {
        filter: true,
      },
    },
    {
      name: 'MinimumLoadMW',
      label: 'MinimumLoadMW',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperatingMonth',
      label: 'OperatingMonth',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperatingYear',
      label: 'OperatingYear',
      options: {
        filter: true,
      },
    },
    {
      name: 'PlannedRetirementYear',
      label: 'PlannedRetirementYear',
      options: {
        filter: true,
      },
    },
    {
      name: 'AssocWithCHPSystem',
      label: 'AssocWithCHPSystem',
      options: {
        filter: true,
      },
    },
    {
      name: 'Fuel1',
      label: 'Fuel1',
      options: {
        filter: true,
      },
    },
    {
      name: 'Fuel2',
      label: 'Fuel2',
      options: {
        filter: true,
      },
    },
    {
      name: 'Fuel3',
      label: 'Fuel3',
      options: {
        filter: true,
      },
    },
    {
      name: 'Fuel4',
      label: 'Fuel4',
      options: {
        filter: true,
      },
    },
  ];

  const [powerplantunitexpanded, setPowerPlantUnitExpanded] = React.useState(false);
  /**
   * Expands or collapses the Power Plant Unit section.
   * If the section is expanded and the gas plant unit data is not fetched yet,
   * it calls the fnFetchPowerPlantUnit function to fetch the data.
   */
  const fnExpandPowerPlantUnit = () => {
    // Toggle the power plant unit expanded state
    setPowerPlantUnitExpanded(!powerplantunitexpanded);

    // If the section is expanded and the power plant unit data is not fetched yet,
    // fetch the data
    if (!powerplantunitexpanded && powerplantunit.length === 0) {
      fnFetchPowerPlantUnit();
    }
  };

  const [powerplantunit, setPowerPlantUnit] = React.useState([]);
  // Fetches the Power Plant Unit data from the API
  const fnFetchPowerPlantUnit = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    // Set the query details
    const queryDetails = {
      index: 'powerplantunit',
      id: props.facilityid,
    };

    // Set the request options
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Send the request to the API
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Do nothing, no records found
      } else {
        // Set the power plant unit state
        setPowerPlantUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        //   return (
        //     <Fragment>
        //       {value.data[0] === 0 ? 'False' : 'True'}
        //     </Fragment>
        //   );
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  const fnFetchGasDailyFlowYears = async (id, type) => {
    dispatch(displaySpinner(true));

    try {
      setAssetType(type);

      const queryDetails = {
        id,
        type,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state if an error occurred
      } else if (response.data[0].status === 'no records found') {
        // Set the error message state if no records found
      } else {
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Toggles the gas flow expanded state
   *
   * This function is used to toggle the state of the gas flow section.
   * It is called when the user wants to expand or collapse the gas flow section.
   *
   * @return {void}
   */
  const fnExpandGasFlow = () => {
    // Toggle the gas flow expanded state
    setGasFlowExpanded(!gasflowexpanded);
  };
  

   /**
   * Fetches gas daily flow data from the server based on the provided id, type, and year.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {Array} value - The array of years to fetch data for.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  const fnFetchGasDailyFlowData = async (value) => {
    if (value && value.length > 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.facilityid,
          type: assettype,
          year: value.join(),
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };
        
        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="Power Plant" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>
      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={powerplantunitexpanded}
        onChange={fnExpandPowerPlantUnit}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Power Plant Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {powerplantunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable data={powerplantunit} columns={powerplantunitcolumns} options={options} />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>

      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
