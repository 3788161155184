/* eslint-disable react/prop-types */
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from '@mui/material';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Api
import axios from 'axios';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { TableOptions } from '../../../../constants/muitable';
import MUIDataTable from 'mui-datatables';

export default function EmissionFacilityTemplateComp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  /**
   * Resets the error message state to hide the error message and clears the message and variant.
   *
   * @return {void} This function does not return anything.
   */
  const fnShowErrorMessage = () => {
    // Reset the error message state to hide the error message
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const eqoptions = TableOptions();

  const emissionqntycolumns = [
    {
      name: 'ReportingYear',
      options: {
        filter: true,
      },
    },
    {
      name: 'GhgQuantityMetricTonsCo2E',
      options: {
        filter: true,
      },
    },
    {
      name: 'Subparts',
      options: {
        filter: true,
      },
    },
  ];

  const emissionoperatorscolumns = [
    {
      name: 'ReportingYear',
      options: {
        filter: true,
      },
    },
    {
      name: 'Company1',
      options: {
        filter: true,
      },
    },
    {
      name: 'Company2',
      options: {
        filter: true,
      },
    },
    {
      name: 'Company3',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches the header data for a CCUS facility.
   *
   * @return {Promise<void>} - A Promise that resolves when the data is fetched successfully.
   */
  const fnFetchEmissionHeader = async () => {
    // Show the spinner while fetching data
    dispatch(displaySpinner(true));

    const queryDetails = {
      index: 'emissionheader',
      id: props.facilityid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Make a POST request to the API to fetch the header data
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner after data is fetched
      dispatch(displaySpinner(false));

      // Check for errors in the response data
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state if an error occurred
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the error message state if no records were found
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Define the facility types and metadata
        const facilitytypes = [
          'Industry Type',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Google Maps',
        ];
        const metadata = Object.keys(response.data[0].data[0]);

        const data = [];

        // Iterate over the metadata and populate the data array
        metadata.forEach((element) => {
          if (element !== 'Facility Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
            }
          }
        });

        // Set the facility header state
        setFacilityHeader(data);

        // Create the facility data array and set the facility data state
        const facility = [
          {
            'Industry Type': response.data[0].data[0]['Industry Type'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Facility Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Process the data by fetching the CCUS header.
   */
  const fnProcessData = () => {
    // Call function to fetch CCUS header data
    fnFetchEmissionHeader();
  };

  const [emissionoperatorexpanded, setEmissionOperatorExpanded] = React.useState(false);
  const [emissionoperatorsdata, setEmissionOperatorData] = React.useState([]);

  const fnExpandEmissionOperator = async () => {
    setEmissionOperatorExpanded(!emissionoperatorexpanded);

    if (emissionoperatorsdata.length === 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.facilityid,
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        const response = await axios.post(
          '/api/emissionfacilityoperatordata',
          queryDetails,
          options,
        );

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setEmissionOperatorData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  const [emissionqntyexpanded, setEmissionQntyExpanded] = React.useState(false);
  const [emissionqntydata, setEmissionQntyData] = React.useState([]);

  const fnExpandEmissionQnty = async () => {
    setEmissionQntyExpanded(!emissionqntyexpanded);

    if (emissionqntydata.length === 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.facilityid,
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        const response = await axios.post('/api/emissionfacilityqntydata', queryDetails, options);

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setEmissionQntyExpanded(true);

          setEmissionQntyData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="EmissionFacility" />
        )}
      </Box>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={emissionoperatorexpanded}
        onChange={fnExpandEmissionOperator}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Facility Operators</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {emissionoperatorsdata.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={emissionoperatorsdata}
                columns={emissionoperatorscolumns}
                options={eqoptions}
              />
            </ThemeProvider>
            // <>{gasdailyflowdata.length}</>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={emissionqntyexpanded}
        onChange={fnExpandEmissionQnty}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Emission Facility Quantity</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {emissionqntydata.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={emissionqntydata}
                columns={emissionqntycolumns}
                options={eqoptions}
              />
            </ThemeProvider>
            // <>{gasdailyflowdata.length}</>
          )}
        </AccordionDetails>
      </Accordion>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
