/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// Api
import axios from 'axios';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import MUIDataTable from 'mui-datatables';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';
import { TableOptions } from '../../../../constants/muitable';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function GasPlantTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);
  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [gasplantyears, setGasPlantYears] = React.useState({
    gasplantintake: [],
    gasplantintakedetails: [],
    gasplantdisposition: [],
    gasplantproduction: [],
    gasplantpurchaser: [],
  });
  const [gasplantintake, setGasPlantIntake] = React.useState([]);
  const [gpintakeselectedyear, setGPIntakeSelectedYear] = React.useState('');

  const [gasplantintakedetails, setGasPlantIntakeDetails] = React.useState([]);

  const [gasplantdisposition, setGasPlantDisposition] = React.useState([]);
  const [gpdispositionselectedyear, setGPDispositionSelectedYear] = React.useState('');

  const [gasplantproduction, setGasPlantProduction] = React.useState([]);
  const [gpprodselectedyear, setGPProdSelectedYear] = React.useState('');

  const [gasplantpurchaser, setGasPlantPurchaser] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const options = TableOptions();

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const gasplantheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (
          Date.parse(text) &&
          record.name !== 'Gas Prcg Capacity (Mmscfd)' &&
          record.name !== 'BTU Content Gas Sold' &&
          record.name !== 'NGL Storage Capacity (Bbls)'
        ) {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;

        // if (index === gasplantdata.length - 3) {
        // 	return (
        // 		<Fragment>
        // 			<Button size="small" onClick={() => this.fnOpenComponent(record, index)} color="primary">
        // 				{text}
        // 			</Button>
        // 		</Fragment>
        // 	);
        // } else {
        // 	return (
        // 		<Fragment>
        // 			<Button
        // 				size="small"
        // 				// onClick={() => fnFetchRecordData(record, index)}
        // 				onClick={() => this.fnOpenComponent(record, index)}
        // 				color="primary"
        // 			>
        // 				{text}
        // 			</Button>
        // 		</Fragment>
        // 	);
        // }
      },
      // width: 100,
    },
  ];

  const gasplantunitcolumns = [
    {
      name: 'GasPlantUnitName',
      label: 'GasPlantUnitName',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasProcgUnitCapacityMMSCFD',
      label: 'GasProcgUnitCapacityMMSCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'NGLFractionationCapacityBbld',
      label: 'NGLFractionationCapacityBbld',
      options: {
        filter: true,
      },
    },
    {
      name: 'EthaneCapacityBbld',
      label: 'EthaneCapacityBbld',
      options: {
        filter: true,
      },
    },
    {
      name: 'PropaneCapacityBbld',
      label: 'PropaneCapacityBbld',
      options: {
        filter: true,
      },
    },
    {
      name: 'UnitCommissionedDate',
      label: 'Commissioning Date',
      options: {
        filter: true,
      },
    },
    {
      name: 'UnitDecommisionedDate',
      label: 'Decommissioning Date',
      options: {
        filter: true,
      },
    },
  ];

  const gasplantintakecolumns = [
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasIntoGatheringSystemMCF',
      label: 'Gas Into Gathering System MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'WetGasDispositionMCF',
      label: 'Wet Gas Disposition MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasPlantIntakeMCF',
      label: 'Gas Plant Intake MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasFromOtherPlantMCF',
      label: 'Gas Frm Other Plant MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasFromTransmissionLineMCF',
      label: 'Gas Frm Transmission Line MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasFromStorageMCF',
      label: 'Gas Frm Storage MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalGasIntakeMCF',
      label: 'Total GP Intake MCF',
      options: {
        filter: true,
      },
    },
  ];

  const gasplantdispositioncolumns = [
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasInletMCF',
      label: 'Gas Inlet Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasOutletMCF',
      label: 'Gas Outlet Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'PlantFuelMCF',
      label: 'Plant Fuel Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'LeaseFuelMCF',
      label: 'Lease Fuel Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'ReInjectedMCF',
      label: 'Re-injected Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'ShrinkageMCF',
      label: 'Shrinkage Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'MeterDifferenceMCF',
      label: 'Meter Diff. Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasVentedMCF',
      label: 'Gas Vented Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasFlaredMCF',
      label: 'Gas Flared Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'OtherProcessingPlantMCF',
      label: 'Other Procg Plant Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'OtherDispositionMCF',
      label: 'Other Disposition Mcf',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalDispositionMCF',
      label: 'Total Disposition Mcf',
      options: {
        filter: true,
      },
    },
  ];

  const gasplantproductioncolumns = [
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },

    {
      name: 'GasPlantProductName',
      label: 'Product Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'Production',
      label: 'Production Gallons',
      options: {
        filter: true,
      },
    },
    {
      name: 'OpeningStock',
      label: 'Opening Stock Gallons',
      options: {
        filter: true,
      },
    },

    {
      name: 'Shrinkage',
      label: 'Shrinkage Gallons',
      options: {
        filter: true,
      },
    },
    {
      name: 'Receipts',
      label: 'Receipts Gallons',
      options: {
        filter: true,
      },
    },

    {
      name: 'Deliveries',
      label: 'Deliveries Gallons',
      options: {
        filter: true,
      },
    },
    {
      name: 'ClosingStock',
      label: 'Closing Stock Gallons',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches the Gas Plant Header from the API and sets the data and facility data state.
   * Also calls the fnFetchGasDailyFlowYears function to fetch daily flow data.
   * @async
   */
  const fnFetchGasPlantHeader = async () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      index: 'gasplantheader',
      id: props.GasPlantId,
    };

    

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Make API call to fetch gas plant header
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set error message state if API call fails
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set info message state if no records found
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Call fnFetchGasDailyFlowYears function to fetch daily flow data
        fnFetchGasDailyFlowYears(props.GasPlantId, 'Gas Plant');

        // Extract facility types and metadata from API response
        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        const metadata = Object.keys(response.data[0].data[0]);

        const data = {};

        // Loop through metadata and extract relevant data
        metadata.forEach((element) => {
          if (element !== 'Gas Plant Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
            }
          }
        });

        // Set facility header state
        setFacilityHeader(data);

        // Create facility data object and set facility data state
        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Gas Plant Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches the gas plant years from the API
   *
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchGasPlantYears = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    // Set the query details
    const queryDetails = {
      index: 'gasplant',
      id: props.GasPlantId,
    };

    // Set the request options
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Send the request to the API
      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set an info message
      } else {
        // Set the gas plant years state
        setGasPlantYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasplantunitexpanded, setGasPlantUnitExpanded] = React.useState(false);
  /**
   * Expands or collapses the Gas Plant Unit section.
   * If the section is expanded and the gas plant unit data is not fetched yet,
   * it calls the fnFetchGasPlantUnit function to fetch the data.
   */
  const fnExpandGasPlantUnit = () => {
    // Toggle the gas plant unit expanded state
    setGasPlantUnitExpanded(!gasplantunitexpanded);

    // If the section is expanded and the gas plant unit data is not fetched yet,
    // fetch the data
    if (!gasplantunitexpanded && gasplantunit.length === 0) {
      fnFetchGasPlantUnit();
    }
  };

  const [gasplantunit, setGasPlantUnit] = React.useState([]);
  // Fetches the Gas Plant Unit data from the API
  const fnFetchGasPlantUnit = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    // Set the query details
    const queryDetails = {
      index: 'gasplantunit',
      id: props.GasPlantId,
    };

    // Set the request options
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Send the request to the API
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Do nothing, no records found
      } else {
        // Set the gas plant unit state
        setGasPlantUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasplantintakeexpanded, setGasPlantIntakeExpanded] = React.useState(false);
  /**
   * Expand or collapse the gas plant intake section.
   * If the section is expanded and there are no gas plant intake records,
   * fetch additional data for the gas plant intake.
   */
  const fnExpandGasPlantIntake = () => {
    // Toggle the expanded state
    setGasPlantIntakeExpanded(!gasplantintakeexpanded);

    // If the section is expanded and there are no gas plant intake records,
    // fetch additional data for the gas plant intake
    // if (!gasplantintakeexpanded && gasplantintake.length === 0) {
    //   const lastYear = gasplantyears.gasplantintake[gasplantyears.gasplantintake.length - 1];
    //   fnFetchAdditionalData("gasplantintake", lastYear);
    // }
  };

  const [gasplantdispositionexpanded, setGasPlantDispositionExpanded] = React.useState(false);
  /**
   * Expand or collapse the gas plant disposition section.
   * If the section is expanded and there are no gas plant disposition records,
   * fetch additional data for the gas plant disposition.
   */
  const fnExpandGasPlantDisposition = () => {
    // Toggle the expanded state
    setGasPlantDispositionExpanded(!gasplantdispositionexpanded);

    // If the section is expanded and there are no gas plant disposition records,
    // fetch additional data for the gas plant disposition
    // if (!gasplantdispositionexpanded && gasplantdisposition.length === 0) {
    //   const lastYear = gasplantyears.gasplantdisposition[gasplantyears.gasplantdisposition.length - 1];
    //   fnFetchAdditionalData("gasplantdisposition", lastYear);
    // }
  };

  const [gasplantproductionexpanded, setGasPlantProductionExpanded] = React.useState(false);
  /**
   * Expand or collapse the gas plant production section.
   * If the section is expanded and there are no gas plant production records,
   * fetch additional data for the gas plant production.
   */
  const fnExpandGasPlantProduction = () => {
    setGasPlantProductionExpanded(!gasplantproductionexpanded);

    // If the section is expanded and there are no gas plant production records,
    // fetch additional data for the gas plant production
    // if (!gasplantproductionexpanded && gasplantproduction.length === 0) {
    //   const lastYear = gasplantyears.gasplantproduction[gasplantyears.gasplantproduction.length - 1];
    //   fnFetchAdditionalData("gasplantproduction", lastYear);
    // }
  };

  /**
   * Fetches gas plant yearly data based on the given index and value.
   * If the value is not empty, it calls the fnFetchAdditionalData function
   * with the index and the value joined as a string.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {Array} value - The value containing the data to fetch.
   */
  const fnFetchGasPlantYearlyData = (index, value) => {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Join the value as a string and call the fnFetchAdditionalData function
      fnFetchAdditionalData(index, value.join());
    }
  };

  /**
   * Fetches additional data for the given index and year.
   * Shows the spinner while fetching the data.
   * If the data fetch is successful, it updates the state of the
   * corresponding data array. If the data fetch is unsuccessful, it
   * sets the error message state.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {string} year - The year of the data to fetch.
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchAdditionalData = async (index, year) => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Set the query details
      const queryDetails = {
        index,
        id: props.GasPlantId,
        year,
      };

      // Set the request options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Send the request to the API
      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set an info message
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Update the state of the corresponding data array
        switch (index) {
          case 'gasplantintake':
            setGasPlantIntake(response.data[0].data);
            break;
          case 'gasplantdisposition':
            setGasPlantDisposition(response.data[0].data);
            break;
          case 'gasplantproduction':
            setGasPlantProduction(response.data[0].data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Processes the data for the Gas Plant Template Component.
   * This function fetches the gas plant header and years data.
   *
   * @return {void}
   */
  const fnProcessData = () => {
    // Fetch the gas plant header data
    fnFetchGasPlantHeader();

    // Fetch the gas plant years data
    fnFetchGasPlantYears();
  };

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        //   return (
        //     <Fragment>
        //       {value.data[0] === 0 ? 'False' : 'True'}
        //     </Fragment>
        //   );
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  // Fetch GasDailyFlowYears
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowYears = async (id, type) => {
    // Display spinner while fetching data
    dispatch(displaySpinner(true));

    try {
      setAssetType(type);

      const queryDetails = {
        id,
        type,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
      } else if (response.data[0].status === 'no records found') {
        // Set an info message
      } else {
        // Set the fetched data
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Toggle the gas flow expanded state
   *
   * @returns {void}
   */
  const fnExpandGasFlow = () => {
    // Toggle the gas flow expanded state
    setGasFlowExpanded(!gasflowexpanded);
  };

  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  /**
   * Fetches gas daily flow data from the server based on the provided id, type, and year.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {Array} value - The array of years to fetch data for.
   * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowData = async (value) => {
    // Check if value is defined and has a length greater than 0
    if (value && value.length > 0) {
      // Display spinner while fetching data
      dispatch(displaySpinner(true));

      try {
        // Prepare the query details
        const queryDetails = {
          id: props.GasPlantId,
          type: assettype,
          year: value.join(),
        };

        // Set the request headers
        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        // Send a POST request to the server
        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        // Hide the spinner after fetching data
        dispatch(displaySpinner(false));

        // Check for errors in the response data
        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          // Set the error message state if an error occurred
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          // Set the error message state if no records were found
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          // Set the gas daily flow data state
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="Gas Plant" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>
      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={gasplantunitexpanded}
        onChange={fnExpandGasPlantUnit}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Gas Plant Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {gasplantunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable data={gasplantunit} columns={gasplantunitcolumns} options={options} />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>

      {gasplantyears.gasplantintake.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={gasplantintakeexpanded}
          onChange={fnExpandGasPlantIntake}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Gas Plant Intake</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpintakeselectedyear}
                  id="combo-box-demo"
                  options={gasplantyears.gasplantintake}
                  onChange={(event, newValue) => {
                    fnFetchGasPlantYearlyData('gasplantintake', newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasplantintake.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasplantintake}
                  columns={gasplantintakecolumns}
                  options={options}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {gasplantyears.gasplantdisposition.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={gasplantdispositionexpanded}
          onChange={fnExpandGasPlantDisposition}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Gas Plant Disposition</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                {/* {JSON.stringify(gasplantyears.gasplantdisposition)} */}
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpdispositionselectedyear}
                  id="combo-box-demo"
                  options={gasplantyears.gasplantdisposition}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  onChange={(event, newValue) => {
                    fnFetchGasPlantYearlyData('gasplantdisposition', newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasplantdisposition.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasplantdisposition}
                  columns={gasplantdispositioncolumns}
                  options={options}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {gasplantyears.gasplantproduction.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={gasplantproductionexpanded}
          onChange={fnExpandGasPlantProduction}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Gas Plant Production</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasplantyears.gasplantproduction}
                  onChange={(event, newValue) => {
                    fnFetchGasPlantYearlyData('gasplantproduction', newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasplantproduction.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasplantproduction}
                  columns={gasplantproductioncolumns}
                  options={options}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
