import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import OverallFacilitiesPBComp from '../views/analysis/OverallFacilitiesPBComp';
import AnalysisComp from '../views/analysis/AnalysisComp';
import FacilityInfoComp from '../views/facility/FacilityInfoComp';
import PipelineFacilityInfoComp from '../views/facility/PipelineFacilityInfoComp';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
const LoginLayout = Loadable(lazy(() => import('../layouts/login-layout/LoginLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

// const QuickSearch = Loadable(lazy(() => import('../views/quicksearch/QuickSearch')));
const SearchStack = Loadable(lazy(() => import('../views/searchstack/SearchStack')));
const FacilitySearch = Loadable(lazy(() => import('../views/facilitysearch/organisms/FacilitySearch')));
const DataSift = Loadable(lazy(() => import('../views/datasift/pages/DataSift')));
const DataFetch = Loadable(lazy(() => import('../views/datafetch/Pages/DataFetch')));

/* ****Routes***** */

const Router = [
  {
    path: 'aura',
    element: <FullLayout />,
    children: [
      { path: '', element: <Navigate to="/aura/searchstack" /> },
      // { path: 'quicksearch', element: <QuickSearch /> },
      { path: 'searchstack', element: <SearchStack /> },
      { path: 'facilitysearch', element: <FacilitySearch /> },
      { path: 'datasift', element: <DataSift /> },
      { path: 'datafetch', element: <DataFetch /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: 'fi',
    element: <BlankLayout />,
    children: [
      { path: '', element: <Login /> },
      { path: 'facilityinfo', element: <FacilityInfoComp /> },
      { path: 'pipelinefacilityinfo', element: <PipelineFacilityInfoComp /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: 'pbi',
    element: <BlankLayout />,
    children: [
      { path: '', element: <Login /> },
      { path: 'dashboard/:type', element: <OverallFacilitiesPBComp /> },
      { path: 'analysisdashboard/:type', element: <AnalysisComp /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: 'register', element: <Login /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
  {
    path: 'error',
    children: [

      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/error/404" /> },
    ],
  },
];

export default Router;
