import React, { useEffect } from "react";

import { makeStyles } from '@mui/styles';


import linq from "linq";

//Powerbi Imports
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";

//Redux imports
import { useDispatch, useSelector } from "react-redux";


//Api
import axios from "axios";
import { fnFetchPowerbiIds, fnFetchPowerBiTarget } from "../../constants/powerbiids";

import { ADD_ACCESSTOKEN } from "../../redux/constants";
import { displaySpinner } from "../../redux/spinner/Action";

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: "30%",
  },
  templatecontainer: {
    padding: "10px",
  },
  yearformcontrol: {
    minWidth: "150px",
    marginRight: 10,
    marginLeft: 10,
    "& .MuiSelect-root": {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
  embedcontainer: {
    height: "900px",
    width: "100%",
  },
}));

//Fetch stored powerbi access tokens from redux by passing pipeline commodity type
//Fetch powerbi Id and Target from the constants 'powerbiids.jsx'
//Check if powerbi token is available in tokens
//If exists check for time
//If doesn't exists fetch token from api "/fetchpbaccesstoken" by passing report id
//Store token details into redux
//Load the power bi template

export default function PipelinePBComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);
  const alltokens = useSelector((state) => state.ReportReducer.reportdetails);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: "",
    variant: "info",
  });

  const [overalltokens, setOverallTokens] = React.useState([]);
  const [accesstoken, setAccessToken] = React.useState("");
  const [powerbiid, setPowerBiId] = React.useState("");
  const [powerbitarget, setPowerBiTarget] = React.useState({});

  const fnFetchPBAccessToken = (pbid) => {
    dispatch(displaySpinner(true));

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    };

    const data = {
      pbi: pbid,
    };

    axios.post("/api/fetchpbaccesstoken", data, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (
          response.data[0] !== undefined &&
          response.data[0].status === "error"
        ) {
          setStatusBase({ show: true, message: "An error occurred during execution.", variant: "error" });
        } else if (response.data[0].status === "no records found") {
          setStatusBase({
            show: true,
            message: "No records found",
            variant: "info",
          });
        } else {
          // //console.log("access token : " + response.data[0].data);
          var currentdate = new Date();

          let pbd = {
            powerbiid: pbid,
            accesstoken: response.data[0].data,
            recordedtime: currentdate,
          };

          setOverallTokens((overalltokens) => [...overalltokens, pbd]);

          setAccessToken(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          // navigate('/', { replace: true });
          setStatusBase({
            show: true,
            message: "Reports Failed",
            variant: "error",
          });
        } else {
          setStatusBase({ show: true, message: "An error occurred during execution.", variant: "error" });
        }
      }
    );
  };

  useEffect(() => {
    dispatch({
      type: ADD_ACCESSTOKEN,
      payload: {
        reportdetails: overalltokens,
      },
    });
  }, [overalltokens]);

  useEffect(() => {
    let pipelinetype = "liquidpipeline";

    if (props.commodity === "Natural Gas") {
      pipelinetype = "gaspipeline";
    } else if (props.commodity === "Refined Products" || props.commodity === "Crude Oil") {
      pipelinetype = "liquidpipeline";
    }

    const pbId = fnFetchPowerbiIds(pipelinetype);
    const pbtarget = fnFetchPowerBiTarget(pipelinetype);

    setPowerBiId(pbId);
    setPowerBiTarget(pbtarget);

    if (alltokens.length > 0) {
      var pbdetails = linq
        .from(alltokens)
        .where((a) => a.powerbiid === pbId)
        .toArray();

      if (pbdetails.length > 0) {
        var currentdate = new Date();

        var diff = Math.abs(currentdate - pbdetails[0].recordedtime) / 60000;

        if (diff > 10) {
          fnFetchPBAccessToken(pbId);
        } else {
          setAccessToken(pbdetails[0].accesstoken);
        }
      } else {
        fnFetchPBAccessToken(pbId);
      }
    } else {
      fnFetchPBAccessToken(pbId);
    }
  }, []);

  return (
    <>
      {accesstoken !== "" && powerbiid !== "" && (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            // id: "0d1d05b5-1118-4375-9d77-0342f0f16f52",
            id: powerbiid,
            embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${powerbiid}&groupId=d028ade5-43ab-417a-8fce-cf4d2ef55170&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d`,
            accessToken: `${accesstoken}`,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Default,
            },
          }}
          cssClassName={classes.embedcontainer}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  // //console.log("Report loaded");

                  const filter = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: powerbitarget,
                    operator: "In",
                    values: [props.pipeline],
                  };

                  window.report.setFilters([filter]);
                },
              ],
              [
                "rendered",
                function () {
                  // //console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  // //console.log(event.detail);
                },
              ],
            ])
          }
        />
      )}
    </>
  );
}
