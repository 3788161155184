/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
import React, { Fragment } from 'react';
import shortid from 'shortid';

import {
  Box,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

// Api
import axios from 'axios';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import AlertNotificationComp from '../../../components/notification/AlertNotificationComp';
import MapTemplateComp from './MapTemplateComp';
import PipelineMapTemplateComp from './PipelineMapTemplateComp';

import { displaySpinner } from '../../../redux/spinner/Action';
import GSMapTemplateComp from './GSMapTemplateComp';
import { GATHERING_SYSTEM } from '../../../constants/facilitytypes';

export default function FacilityHeaderComp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const fetchFacilityConnectedPipelines = async () => {
    dispatch(displaySpinner(true));

    try {
      const fName = props.facilitydata[0]['Facility Name'];
      const fType =
        props.type === 'PipelineAssemblies' ? props.facilitydata[0]['Asset Type'] : props.type;

      const queryDetails = {
        facilityname: fName,
        facilitytype: fType,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchconnectedpipelines', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No connections found',
          variant: 'info',
        });
      } else {
        const cPipes = response.data[0].data.map((pipeline) => pipeline.PipelineHeaderId);

        setConnectedPipelines(cPipes);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [connectedfacilities, setConnectedFacilities] = React.useState([]);
  const fetchGSConnectedPipelines = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        gsid: props.gsid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchgsconnectedpipelines', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No connections found',
          variant: 'info',
        });
      } else {
        const cPipes = [
          ...new Set(response.data[0].data.map((pipeline) => pipeline.PipelineHeaderId)),
        ];

        setConnectedPipelines(cPipes);
        setConnectedFacilities(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [connectedPipelines, setConnectedPipelines] = React.useState([]);
  // Fetch connected pipelines for the facility
  const fetchconnectedpipelines = async () => {
    // console.log(props.type);
    if (props.type === GATHERING_SYSTEM) {
      fetchGSConnectedPipelines();
    } else {
      fetchFacilityConnectedPipelines();
    }
  };

  const [loadgsconns, setLoadGSConns] = React.useState(false);
  const fetchloadgatheringsystems = () => {
    setLoadGSConns(true);
  };

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={6}>
          <Paper sx={{ p: '10px', minHeight: '500px' }}>
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Header Fields</Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: 'right', cursor: 'pointer' }}>
                    {props.type !== 'Pipeline' && (
                      <Fragment>
                        <Link onClick={fetchconnectedpipelines}>
                          <Typography variant="h6" fontWeight="600">
                            Fetch Connected Pipelines
                          </Typography>
                        </Link>
                      </Fragment>
                    )}
                    {props.type === 'Pipeline' && props.gscons.length > 0 && (
                      <Fragment>
                        {/* {props.gscons.length} */}
                        <Link onClick={fetchloadgatheringsystems}>
                          <Typography variant="h6" fontWeight="600">
                            Load Connected Gathering Systems
                          </Typography>
                        </Link>
                      </Fragment>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(props.facilitydata[0]).map(
                  (header) =>
                    props.facilitydata[0][header] !== null && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px', pr: '0' }}>
                          <Box
                            sx={
                              {
                                // mr: 3
                              }
                            }
                          >
                            {header === 'Google Maps' ? (
                              <Fragment>
                                <Link
                                  href={props.facilitydata[0][header]}
                                  to={props.facilitydata[0][header]}
                                  target="_blank"
                                >
                                  <Typography variant="h6" fontWeight="600">
                                    Click here to open
                                  </Typography>
                                </Link>
                              </Fragment>
                            ) : (
                              <Typography variant="h6" fontWeight="600">
                                {props.facilitydata[0][header]}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Grid style={{ width: '98.7%', margin: '10px', marginTop: '0' }}>
            {props.facilitydata.length > 0 &&
              (props.type === 'Pipeline' ? (
                <PipelineMapTemplateComp
                  facilitydata={props.facilitydata}
                  type={props.type}
                  pipelineid={props.pipelineid}
                  pipelinefacilities={props.pipelinefacilities}
                  focusonmap={props.focusonmap}
                  loadgsconns={loadgsconns}
                  gsconns={props.gscons}
                />
              ) : props.type === GATHERING_SYSTEM ? (
                <GSMapTemplateComp
                  type={props.type}
                  gsid={props.gsid}
                  confacilities={connectedfacilities}
                  conpipelines={connectedPipelines}
                  focusonmap={props.focusonmap}
                />
              ) : (
                <MapTemplateComp
                  facilitydata={props.facilitydata}
                  type={props.type}
                  conpipelines={connectedPipelines}
                />
              ))}
          </Grid>
        </Grid>
      </Grid>
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
