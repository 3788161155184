import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Fab, Grid, Paper, Snackbar, Tooltip } from '@mui/material';
import { loadModules } from 'esri-loader';
import MapIcon from '@mui/icons-material/Map';
import shortid from 'shortid';

//Api
import axios from 'axios';

import AlertNotificationComp from '../../../components/notification/AlertNotificationComp';
import * as facilitycolors from '../../../constants/facilitycolors.jsx';
import BaseMapsIconComp from '../../basemaps/BaseMapsIconComp';
import { displaySpinner } from '../../../redux/spinner/Action';
import { ADD_PIPELINETEMPLATEDETAILS, RESET_INITIAL_STATE } from '../../../redux/constants';
import {ARROWVIS_ZOOM} from '../../../constants/user';
// import BaseMapsComp from "components/search/subcomponents/BaseMapsComp";

//Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { GAS_STORAGE } from '../../../constants/facilitytypes.jsx';

const useStyles = makeStyles((theme) => ({
  facilitymap: {
    position: 'relative',
  },
}));

export default function MapTemplateComp(props) {
  let facilitymapRef = React.createRef();
  const dispatch = useDispatch();
  const classes = useStyles();

  const token = useSelector((state) => state.UserReducer.token);
  const ri = useSelector((state) => state.UserReducer.ri);

  const [view, setView] = React.useState({});

  const [facilitylayer, setFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const [facilitylabel, setFacilityLabel] = React.useState({
    symbol: {
      type: 'text',
      color: '#000000',
      // haloColor: '#d83435',
      // haloSize: '1px',
      font: {
        size: '12px',
        family: 'Arial',
        // style: 'italic',
        weight: 'normal',
      },
    },
    labelPlacement: 'above-center',
    labelExpressionInfo: {
      expression: '$feature.Facility',
    },
  });

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const fnLoadMapView = () => {
    loadModules(['esri/Map', 'esri/views/MapView'], {
      css: true,
    }).then(([ArcGISMap, MapView]) => {
      var mapview = new MapView({
        container: 'facilitymapid',
        map: new ArcGISMap({
          basemap: 'oceans',
        }),
        center: [-99, 38],
        zoom: 4,
      });

      setView(mapview);
    });
  };

  const fnMapSnapshot = (domElement) => {
    view.takeScreenshot().then(function (screenshot) {
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.download = 'map.png';
      link.href = screenshot.dataUrl;
      link.target = '_blank';
      link.click();
    });
  };

  const fnHandleBaseMapChange = (value) => {
    view.map.basemap = value;
  };

  const fnAddFacility = (data) => {
    loadModules(['esri/Graphic', 'esri/layers/FeatureLayer', 'esri/PopupTemplate'], {
      css: true,
    }).then(([Graphic, FeatureLayer, PopupTemplate]) => {
      var moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      var popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },

          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OwnerName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function FacilityChange(feature) {
        var div = document.createElement('div');

        

        div.innerHTML =
          'Facility Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Latitude : <b>' +
          feature.graphic.attributes.Latitude +
          '</b> </br> Longitude : <b>' +
          feature.graphic.attributes.Longitude +
          '</b> </br> Operator Name : <b>' +
          feature.graphic.attributes.OperatorName +
          '</b>';
        return div;
      }

      view.center = [data[0].Longitude, data[0].Latitude];
      view.zoom = ARROWVIS_ZOOM;

      var facilitygraphics = data.map(function (storage) {
        

        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage['Facility Name'],
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            OperatorName: storage['Operator Name'] === null ? '' : storage['Operator Name'],
            OwnerName: storage['Owner Name'] === null ? '' : storage['Owner Name'],
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      let fcolor = '#000000';

      switch (props.type) {
        case 'Gas Plant':
          fcolor = facilitycolors.GasPlantColor;
          break;
        case GAS_STORAGE:
          fcolor = facilitycolors.GasStorageColor;
          break;
        case 'LNG':
          fcolor = facilitycolors.LNGColor;
          break;
        case 'Refinery':
          fcolor = facilitycolors.RefineryColor;
          break;
        case 'Terminal':
          fcolor = facilitycolors.TerminalColor;
          break;
        case 'Permit':
          fcolor = facilitycolors.PermitColor;
          break;
        case 'Well':
          fcolor = facilitycolors.WellColor;
          break;
        case 'CompressorStation':
          fcolor = facilitycolors.CompressorStationColor;
          break;
        case 'PumpingStation':
          fcolor = facilitycolors.PumpingStationColor;
          break;
        case 'CCUS':
          fcolor = facilitycolors.CCUSColor;
          break;
        case 'CO2 Storage':
          fcolor = facilitycolors.CO2StorageColor;
          break;
        case 'Hydrogen Plant':
          fcolor = facilitycolors.HydrogenPlantColor;
          break;
        case 'Industrial Plant':
          fcolor = facilitycolors.IndustrialPlantColor;
          break;
        case 'Power Plant':
          fcolor = facilitycolors.PowerPlantColor;
          break;
        case 'PipelineAssemblies':
          fcolor = facilitycolors.PipelineAssembliesColor;
          break;
      }

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
          {
            name: 'OwnerName',
            type: 'string',
          },
        ],
        popupTemplate: popupTemplate,
        renderer: {
          type: 'simple', // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
            size: 7,
            color: fcolor,
            outline: {
              // autocasts as new SimpleLineSymbol()
              width: 1.5,
              color: fcolor,
            },
          },
        },
        labelingInfo: [facilitylabel],
      });

      setFacilityLayer({ properties: facilitylayer, binded: false });
    });
  };

  const fnAddPipelines = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      pipelineids: `(${props.conpipelines.toString()})`,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchpipelinesdatabyids', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          fnAddPipelineOnMap(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));

        setStatusBase({
          show: true,
          message: 'Error while loading connected pipelines',
          variant: 'error',
        });
      },
    );
  };

  const [zoomchanged, setZoomChanged] = React.useState(false);
  const onZoomChange = (newValue, oldValue, propertyName, target) => {
    // View just finished updating. Get the new zoom value from the view

    if (!view.updating && view.zoom >= ARROWVIS_ZOOM) {
      if (!zoomchanged) {
        
        setZoomChanged(true);
      }
    }

    if (!view.updating && view.zoom < ARROWVIS_ZOOM) {
      setZoomChanged(false);
    }
  };

  const [pipelinelayer, setPipelineLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnHandlePopupAction = (event) => {
    if (event.action.id === 'more-info') {

      // dispatch({
      //   type: RESET_INITIAL_STATE
      // });

      let planttype = 'Pipeline';

      var templatevalues = {
        index: planttype,
        id: view.popup.selectedFeature.attributes.PlantId,
        name: view.popup.selectedFeature.attributes.Facility,
      };

      dispatch({
        type: ADD_PIPELINETEMPLATEDETAILS,
        payload: {
          pipelinetemplatevalues: templatevalues,
        },
      });

      // Get the screen width and height
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      window.open(
        `${window.location.origin}/fi/pipelinefacilityinfo`, // URL for the Power BI dashboard
        '_blank', // open in a new window
        `width=${screenWidth},height=${screenHeight}`, // set the window size
      );
    }
  };

  const fnAddPipelineOnMap = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      var moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: PipelineChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Section',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Direction',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function PipelineChange(feature) {
        var div = document.createElement('div');

        div.innerHTML =
          'Pipeline Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Section Name : <b>' +
          feature.graphic.attributes.Section +
          '</b> </br> Direction : <b>' +
          feature.graphic.attributes.Direction +
          '</b> </br>Facility Type : <b>' +
          feature.graphic.attributes.PlantType +
          '</b> </br> Operator Name : <b>' +
          feature.graphic.attributes.OperatorName +
          '</b> </br> CommodityHandled : <b>' +
          feature.graphic.attributes.CommodityHandled +
          '</b>';
        return div;
      }

      const pipelinegraphics = data.map(function (storage) {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.PipelineName,
            Section: storage.PipelineSectionName,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.PipelineHeaderId,
            PlantType: 'Pipeline',
            OperatorName: storage.OperatorName,
            Direction: storage.Direction,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: facilitycolors.FullPipelineProps,
      };

      const pipelinelayer = new FeatureLayer({
        // source: features,
        source: pipelinegraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Section',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
          {
            name: 'Direction',
            type: 'string',
          },
        ],

        popupTemplate: popupTemplate,
        geometryType: 'polyline',
        renderer: renderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setPipelineLayer({ properties: pipelinelayer, binded: false });
    });
  };

  useEffect(() => {
    fnLoadMapView();
  }, []);

  useEffect(() => {
    if (facilitylayer.binded === false && facilitylayer.properties.visible !== undefined) {
      // setTimeout(() => {
      // 	view.map.add(facilitylayer.properties);
      // 	setFacilityLayer({ ...facilitylayer, binded: true });
      // }, 5000);

      view.map.add(facilitylayer.properties);
      setFacilityLayer({ ...facilitylayer, binded: true });
    }
  }, [facilitylayer]);

  useEffect(() => {
    if (pipelinelayer.binded === false && pipelinelayer.properties.visible !== undefined) {
      
      view.map.add(pipelinelayer.properties);

      setPipelineLayer({ ...pipelinelayer, binded: true });

      view.popup.on('trigger-action', fnHandlePopupAction);
    }
  }, [pipelinelayer]);

  useEffect(() => {
    if (pipelinelayer.properties.visible !== undefined) {
      if (zoomchanged) {
        var zoomrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'Direction',
          field2: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.PipeineProps,
        };

        pipelinelayer.properties.renderer = zoomrenderer;
      }

      if (!zoomchanged) {
        var normalrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.FullPipelineProps,
        };

        pipelinelayer.properties.renderer = normalrenderer;
      }
    }
  }, [zoomchanged]);

  useEffect(() => {
    if (props.facilitydata.length > 0 && view.map !== undefined) {
      fnAddFacility(props.facilitydata);
    }
  }, [props.facilitydata, view.map]);

  useEffect(() => {
    if (props.conpipelines.length > 0 && view.map !== undefined) {
      fnAddPipelines(props.conpipelines);
    }
  }, [props.conpipelines, view.map]);

  useEffect(() => {
    if (view.map !== undefined && pipelinelayer.properties !== undefined) {
      loadModules(['esri/core/watchUtils'], {
        css: true,
      }).then(([watchUtils]) => {
        watchUtils.watch(view, 'updating', onZoomChange);
      });
    }
  }, [view]);

  return (
    <>
      <Paper className={classes.facilitymap}>
        <div
          className="facilitywebmap"
          id="facilitymapid"
          style={{ height: '500px', width: '100%' }}
          ref={facilitymapRef}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            position: {
              xs: 'absolute',
              lg: 'absolute',
            },

            top: '87%',

            width: '20%',
          }}
        >
          {facilitylayer.binded === true && view.map !== undefined && (
            <>
              <Tooltip title="Map Snapshot">
                <Fab
                  size="medium"
                  color="mapsnapshot"
                  aria-label="mapsnapshot"
                  onClick={() => fnMapSnapshot(facilitymapRef.current)}
                >
                  <MapIcon sx={{ fontSize: '1.6rem' }} />
                </Fab>
              </Tooltip>
              <BaseMapsIconComp fnHandleBaseMapChange={fnHandleBaseMapChange}></BaseMapsIconComp>
            </>
          )}
        </Grid>
      </Paper>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      ></AlertNotificationComp>
    </>
  );
}
