export const dateFormat = (dateinput) => {
  const d = new Date(dateinput);
  return d.toLocaleDateString('en-US');
};

export const customizedDateFormat = (dateinput) => {
  const date = new Date(dateinput);

  const month = date.getMonth() + 1;
  const newMonth = month > 9 ? month : `0${month}`;
  const day = date.getDate();
  const newDay = day > 9 ? day : `0${day}`;
  const formattedDateTime = `${newMonth}/${newDay}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  return formattedDateTime;
};
