import React from "react";

import { makeStyles } from '@mui/styles';

import { Tooltip, Grid, Popper, Paper, Fab, ListSubheader, CardMedia, ClickAwayListener, ImageListItem, ImageList, ImageListItemBar } from '@mui/material';


import LayersIcon from '@mui/icons-material/Layers';

//MapTypeImages

import darkgray from "../../assets/images/maptypes/dark-gray.jpg";
import gray from "../../assets/images/maptypes/gray.jpg";
import hybrid from "../../assets/images/maptypes/hybrid.jpg";
import nationalgeographic from "../../assets/images/maptypes/national-geographic.jpg";
import oceans from "../../assets/images/maptypes/oceans.jpeg";
import osm from "../../assets/images/maptypes/osm.jpg";
import satellite from "../../assets/images/maptypes/satellite.jpg";
import streetsnight from "../../assets/images/maptypes/streets-night.jpg";
import streets from "../../assets/images/maptypes/streets.jpg";
import terrain from "../../assets/images/maptypes/terrain.jpg";
import topo from "../../assets/images/maptypes/topo.jpg";


const useStyles = makeStyles((theme) => ({
  // mapcontrol: {
  //   minWidth: "200px",
  //   marginLeft: 10,
  //   "& .MuiSelect-root": {
  //     paddingTop: 10,
  //     paddingBottom: 10,
  //   },
  // },
  mapgroup: {
    // left: '10% !important'
  },
  mapicon: { width: "100px", height: "100px" },
  // menulist: { maxHeight: "500px", overflow: "auto" },
  // root: {
  //   display: "flex",
  //   flexWrap: "wrap",
  //   justifyContent: "space-around",
  //   overflow: "hidden",
  //   backgroundColor: theme.palette.background.paper,
  // },
  // gridList: {
  //   width: "170px",
  //   height: 500,
  // },
  // infogridList: {
  //   width: 500,
  //   height: 300,
  // },
}));

function BaseMapsIconComp(props) {
  const classes = useStyles();

  const [selectedmap, setSelectedMap] = React.useState("");

  //Source for names : https://developers.arcgis.com/javascript/3/jsapi/esri.basemaps-amd.html
  const baseMaps = [
    {
      name: "Default",
      value: "oceans",
      image: oceans,
    },
    {
      name: "Dark Gray",
      value: "dark-gray",
      image: darkgray,
    },
    {
      name: "Light Gray",
      value: "gray",
      image: gray,
    },
    // {
    // 	name: 'Light Gray Canvas [v2]',
    // 	value: 'gray-vector',
    // 	image: grayvector,
    // },
    {
      name: "World Imagery",
      value: "hybrid",
      image: hybrid,
    },
    {
      name: "National Geographic",
      value: "national-geographic",
      image: nationalgeographic,
    },
    {
      name: "OpenStreetMap",
      value: "osm",
      image: osm,
    },
    {
      name: "World Imagery",
      value: "satellite",
      image: satellite,
    },
    {
      name: "Streets",
      value: "streets",
      image: streets,
    },
    // {
    // 	name: 'World Navigation',
    // 	value: 'streets-navigation-vector',
    // 	image: streetsnavigation,
    // },
    {
      name: "World Street Map (Night)",
      value: "streets-night-vector",
      image: streetsnight,
    },
    {
      name: "Terrain with Labels",
      value: "terrain",
      image: terrain,
    },
    {
      name: "Topographic Map",
      value: "topo",
      image: topo,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const [infoopen, setInfoOpen] = React.useState(false);
  const infoanchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const infohandleToggle = () => {
    setInfoOpen((prevOpen) => !prevOpen);
  };

  const infohandleClose = (event) => {
    if (infoanchorRef.current && infoanchorRef.current.contains(event.target)) {
      return;
    }

    setInfoOpen(false);
  };

  const fnHandleItemClick = (event) => {
    const { myValue } = event.currentTarget.dataset;
    props.fnHandleBaseMapChange(myValue);
    setOpen(false);
  };

  return (
    <>

      <Tooltip title="Map Layer" >
        <Fab size={props.size === undefined ? 'medium' : props.size} color="maplayers" aria-label="maplayers" onClick={handleToggle} ref={anchorRef}>
          <LayersIcon sx={{ fontSize: '1.6rem' }} />
        </Fab>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{ zIndex: 1300 }}
        placement="bottom"
        className={classes.mapgroup}
      // anchorPosition={{ top: "10%", left: "90%" }}
      // anchorOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left",
      // }}
      // transformOrigin={{
      //   vertical: "bottom",
      //   horizontal: "left",
      // }}
      >


        <Paper sx={{ p: 2 }}>
          <ClickAwayListener onClickAway={handleClose}>
            <ImageList sx={{ width: 300, height: 350 }}>
              {baseMaps.map((val, index) => (
                <ImageListItem key={val.image} style={{ cursor: "pointer" }}
                  onClick={fnHandleItemClick} data-my-value={val.value}>
                  <CardMedia
                    className={classes.mapicon}
                    image={val.image}
                    title={val.value}
                  />
                  <ImageListItemBar
                    title={val.name}
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
            {/* <ImageList cellHeight={150} className={classes.gridList} cols={1}>
              <ImageListItem key="Subheader" cols={1} style={{ height: "auto" }}>
                <ListSubheader component="div">Base Maps</ListSubheader>
              </ImageListItem>
              {baseMaps.map((val, index) => (
                <ImageListItem
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={fnHandleItemClick}
                  data-my-value={val.value}
                >
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <CardMedia
                        className={classes.mapicon}
                        image={val.image}
                        title={val.value}
                      />
                    </Grid>
                    <Grid item>
                      {val.name}
                    </Grid>
                  </Grid>
                </ImageListItem>
              ))}
            </ImageList> */}
          </ClickAwayListener>
        </Paper>
      </Popper>
      {/* <IconButton
				ref={infoanchorRef}
				aria-label="info"
				aria-haspopup="true"
				aria-controls={infoopen ? 'menu-list-grow' : undefined}
				className={classes.margin}
				onClick={infohandleToggle}
			>
				<InfoIcon fontSize="small" />
			</IconButton> */}
      {/* <Popper
				open={infoopen}
				anchorEl={infoanchorRef.current}
				role={undefined}
				style={{ zIndex: 1 }}
				anchorPosition={{ top: 200, left: 400 }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<Paper className={classes.root}>
					<ClickAwayListener onClickAway={infohandleClose}>
						<GridList cellHeight={65} className={classes.infogridList} cols={4}>
							<GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
								<ListSubheader component="div">Product(s) Colors</ListSubheader>
							</GridListTile>

							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Crude</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.CrudeColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>

							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Refined Product</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.RefinedProductColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Chemicals</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.ChemicalsColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>LNG</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.LNGProductColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
								<ListSubheader component="div">Status Colors</ListSubheader>
							</GridListTile>

							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Active</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.ActiveColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Expired</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.ExpiredColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Denied</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.DeniedColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
							<GridListTile>
								<Grid container direction="column" justify="center" alignItems="center">
									<Grid item>
										<span>Waiting</span>
									</Grid>
									<Grid item>
										<span
											style={{
												height: '15px',
												width: '15px',
												backgroundColor: facilitycolors.WaitingColor,
												borderRadius: '50%',
												display: 'inline-block',
											}}
										></span>
									</Grid>
								</Grid>
							</GridListTile>
						</GridList>
					</ClickAwayListener>
				</Paper>
			</Popper> */}
    </>
  );
}

export default BaseMapsIconComp;
