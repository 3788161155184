/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
// Redux imports
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
// Api
import axios from 'axios';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

export default function GasStorageTemplateComp(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const options = {
    print: false,
    selectableRows: 'none',
    resizableColumns: true,
  };

  const [facilitydata, setFacilityData] = React.useState([]);

  const [gasstorageheader, setGasStorageHeader] = React.useState({});
  const [gasstorageyears, setGasStorageYears] = React.useState({
    gasstoragedeliveriesyearly: [],
    gasstoragereservoircharacteristics: [],
    gasstoragespecifications: [],
  });

  const [gasstoragedeliveries, setGasStorageDeliveries] = React.useState([]);
  const [gsdeliveriesselectedyear, setGSDeliveriesSelectedYear] = React.useState('');

  const [gasstoragereservoir, setGasStorageReservoir] = React.useState([]);
  const [gsreservoirselectedyear, setGSReservoirSelectedYear] = React.useState('');

  const [gasstoragespecification, setGasStorageSpecification] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  /**
   * Function to show an error message
   */
  const fnShowErrorMessage = () => {
    // Set the status base to hide the message with an 'info' variant
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const gasstoragedeliveriescolumns = [
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalCapacity',
      label: 'Total Capacity BCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'WorkingCapacity',
      label: 'Working Capacity BCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'CushionCapacity',
      label: 'Cushion Capacity BCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'InjectionCapacity',
      label: 'Injection Capacity BCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'WithdrawalCapacity',
      label: 'Withdrawal Capacity BCF',
      options: {
        filter: true,
      },
    },
  ];

  const gasstoragereservoircolumns = [
    {
      name: 'Year',
      label: 'Commissioning Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageReservoirName',
      label: 'Gas Storage Reservoir Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'CommissioningDate',
      label: 'Commissioning Date',
      options: {
        filter: true,
      },
    },
    {
      name: 'DecomissioningDate',
      label: 'Decomissioning Date',
      options: {
        filter: true,
      },
    },
    {
      name: 'DateQualifier',
      label: 'Date Qualifier',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberofSaltCavens',
      label: 'Number Of Salt Cavens',
      options: {
        filter: true,
      },
    },
  ];

  const gasstoragespecificationscolumns = [
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageMaxPressure',
      label: 'Max Pressure',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageMaxPressureUnit',
      label: 'Max Pressure Unit',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageReservoirMaxDepth',
      label: 'Max Depth',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageReservoirMaxDepthUnit',
      label: 'Max Depth Unit',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageReservoirMinDepth',
      label: 'Min Depth',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasStorageReservoirMinDepthUnit',
      label: 'Min Depth Unit',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfInjectionWithdrawalWells',
      label: 'Injection Withdrawal Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsDrilled',
      label: 'Drilled Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsAbandoned',
      label: 'Abandoned Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsGasFlowTube',
      label: 'Wells Gas Flow Tube',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberofWellsGasFlowBoth',
      label: 'Wells Gas Flow Both',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberofWellsGasFlowOtherType',
      label: 'Wells Gas Flow Other Types',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberofWellsGasFlowCasing',
      label: 'Wells Gas Flow Casing',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsNewTubing',
      label: 'Wells New Tubing',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsNewCasing',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsRepaired',
      label: 'Repaired Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsLeaks',
      label: 'Leaks Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsPressureTest',
      label: 'Pressure Test Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsCorrosionWallLoss',
      label: 'Corrosion Wall Loss Wells',
      options: {
        filter: true,
      },
    },
    {
      name: 'NumberOfWellsOtherMaintaince',
      label: 'Other Maintaince Wells',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches the gas storage header data from the server.
   * @returns {Promise<void>} A promise that resolves when the data is fetched and set.
   */
  const fnFetchGasStorageHeader = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Define the query details and options
      const queryDetails = {
        index: 'gasstorageheader',
        id: props.GasStorageId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Make the API request and get the response
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set error message if there is an error
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set info message if no records found
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Fetch gas daily flow years
        fnFetchGasDailyFlowYears(props.GasStorageId, 'Gas Storage');

        // Define the facility types
        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        // Get the metadata from the response
        const metadata = Object.keys(response.data[0].data[0]);

        // Initialize the data object
        const data = {};

        // Initialize the map link
        const maplink = '';

        // Iterate over the metadata and populate the data object
        metadata.forEach((element) => {
          if (element !== 'Gas Storage Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
            }
          }
        });

        // Set the gas storage header data
        setGasStorageHeader(data);

        // Set the facility data
        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Gas Storage Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Fetch List of Gas Storage Years
  const fnFetchGasStorageYears = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gasstorage',
        id: props.GasStorageId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        setGasStorageYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches gas storage yearly data based on the given index and value.
   * If the value is not empty, it calls the fnFetchAdditionalData function
   * with the index and the value joined as a string.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {Array} value - The value containing the data to fetch.
   */
  const fnFetchGasStorageYearlyData = (index, value) => {
    if (value && value.length > 0) {
      // Join the value as a string and call the fnFetchAdditionalData function
      fnFetchAdditionalData(index, value.join());
    }
  };

  /**
   * Fetches additional data for gas storage based on the given index and year.
   * Displays a spinner while fetching data and handles errors that occur during the process.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {string} year - The year of the data to fetch.
   */
  const fnFetchAdditionalData = async (index, year) => {
    // Show spinner
    dispatch(displaySpinner(true));

    try {
      // Prepare query details
      const queryDetails = {
        index,
        id: props.GasStorageId,
        year,
      };

      // Prepare options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Make API call to fetch data
      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      

      // Hide spinner
      dispatch(displaySpinner(false));

      // Handle response data
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set error status
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set no records found status
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        
        // Set data based on index
        switch (index) {
          case 'gasstoragedeliveriesyearly':
            setGasStorageDeliveries(response.data[0].data);
            break;
          case 'gasstoragereservoircharacteristics':
            setGasStorageReservoir(response.data[0].data);
            break;
          case 'gasstoragespecifications':
            setGasStorageSpecification(response.data[0].data);
            break;

          default:
            break;
        }
      }
    } catch (error) {
      // Hide spinner and handle errors
      dispatch(displaySpinner(false));

      

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Function to process data on screen load.
   * It fetches gas storage header and years data.
   */
  const fnProcessData = () => {
    // Fetch gas storage header and years data
    // when the component is loaded

    // Fetch gas storage header data
    fnFetchGasStorageHeader();

    // Fetch gas storage years data
    fnFetchGasStorageYears();
  };

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        // 	return (
        // 		<Fragment>
        // 			{value.data[0] === 0 ? 'False' : 'True'}
        // 		</Fragment>
        // 	);
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  // Fetch GasDailyFlowYears
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowYears = async (id, type) => {
    // Display spinner while fetching data
    dispatch(displaySpinner(true));

    // Set the asset type state
    setAssetType(type);

    // Prepare the query details
    const queryDetails = {
      id,
      type,
    };

    // Set the request headers
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Send a POST request to the server
      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      // Hide the spinner after fetching data
      dispatch(displaySpinner(false));

      // Check for errors in the response data
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set error status
      } else if (response.data[0].status === 'no records found') {
        // Set no records found status
      } else {
        // Set the fetched data
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Function to expand or collapse the Gas Flow section.
   * Toggles the gasFlowExpanded state.
   *
   * @return {void}
   */
  const fnExpandGasFlow = () => {
    // Toggle the gasFlowExpanded state
    setGasFlowExpanded(!gasflowexpanded);
  };

  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  const fnFetchGasDailyFlowData = async (value) => {
    if (value && value.length > 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.GasStorageId,
          type: assettype,
          year: value.join(),
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  useEffect(() => {
    if (gasstorageyears.gasstoragedeliveriesyearly.length > 0) {
      // fnFetchAdditionalData(
      // 	'gasstoragedeliveriesyearly',
      // 	gasstorageyears.gasstoragedeliveriesyearly[gasstorageyears.gasstoragedeliveriesyearly.length - 1]
      // );
    }

    if (gasstorageyears.gasstoragereservoircharacteristics.length > 0) {
      // fnFetchAdditionalData(
      // 	'gasstoragereservoircharacteristics',
      // 	gasstorageyears.gasstoragereservoircharacteristics[
      // 	gasstorageyears.gasstoragereservoircharacteristics.length - 1
      // 	]
      // );
    }
  }, [gasstorageyears]);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="Gas Storage" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(gasstorageheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(gasstorageheader).map(
                  (header) =>
                    gasstorageheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography variant="h6" fontWeight="600">
                              {gasstorageheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>
      {gasstorageyears.gasstoragedeliveriesyearly.length > 0 && (
        <Paper
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2, pb: 0 }}
          >
            <Grid xs={3} item>
              <Typography variant="h5">Gas Storage Deliveries Yearly</Typography>
            </Grid>
            <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
              <Autocomplete
                multiple
                disablePortal
                // value={gsdeliveriesselectedyear}
                id="combo-box-demo"
                options={gasstorageyears.gasstoragedeliveriesyearly}
                onChange={(event, newValue) => {
                  fnFetchGasStorageYearlyData('gasstoragedeliveriesyearly', newValue);
                }}
                fullWidth
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select Year"
                    aria-label="Select Year"
                  />
                )}
              />
            </Grid>
          </Grid>

          {gasstoragedeliveries.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={gasstoragedeliveries}
                columns={gasstoragedeliveriescolumns}
                options={options}
              />
            </ThemeProvider>
          )}
        </Paper>
      )}
      {gasstorageyears.gasstoragereservoircharacteristics.length > 0 && (
        <Paper
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2, pb: 0 }}
          >
            <Grid xs={3} item>
              <Typography variant="h5">Gas Storage Reservoir Characteristics Yearly</Typography>
            </Grid>
            <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
              <Autocomplete
                multiple
                disablePortal
                // value={gpintakeselectedyear}
                id="combo-box-demo"
                options={gasstorageyears.gasstoragereservoircharacteristics}
                onChange={(event, newValue) => {
                  fnFetchGasStorageYearlyData('gasstoragereservoircharacteristics', newValue);
                }}
                fullWidth
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select Year"
                    aria-label="Select Year"
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* <Divider /> */}

          {gasstoragereservoir.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={gasstoragereservoir}
                columns={gasstoragereservoircolumns}
                options={options}
              />
            </ThemeProvider>
          )}
        </Paper>
      )}

      {gasstorageyears.gasstoragespecifications.length > 0 && (
        <Paper
          sx={{
            p: 0,
            mt: 2,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2, pb: 0 }}
          >
            <Grid xs={3} item>
              <Typography variant="h5">Gas Storage Specifications Yearly</Typography>
            </Grid>
            <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
              <Autocomplete
                multiple
                disablePortal
                // value={gpintakeselectedyear}
                id="combo-box-demo"
                options={gasstorageyears.gasstoragespecifications}
                onChange={(event, newValue) => {
                  fnFetchGasStorageYearlyData('gasstoragespecifications', newValue);
                }}
                fullWidth
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select Year"
                    aria-label="Select Year"
                  />
                )}
              />
            </Grid>
          </Grid>
          {/* <Divider /> */}

          {gasstoragespecification.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={gasstoragespecification}
                columns={gasstoragespecificationscolumns}
                options={options}
              />
            </ThemeProvider>
          )}
        </Paper>
      )}
      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
