import React from "react";
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import Router from './routes/Router';
// import { ClearCacheProvider } from "react-clear-cache";

import LoadingSpinnerComp from "./components/customspinner/LoadingSpinnerComp";

function App() {

  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        {/* <ClearCacheProvider duration={5000}> */}
          <LoadingSpinnerComp />
          {routing}
        {/* </ClearCacheProvider> */}

      </RTL>
    </ThemeProvider>
  );
}

export default App;
