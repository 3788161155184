/* eslint-disable react/self-closing-comp */
/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Api
import axios from 'axios';

import linq from 'linq';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import * as assemblies from '../../../../constants/pipelineassemblies';
import { dateFormat } from '../../../../constants/utils';

// import { Report } from "powerbi-report-component";

// Redux imports

import { PipelineTableOptions } from '../../../../constants/muitable';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import {
  ADD_PIPELINETEMPLATEDETAILS,
  ADD_TEMPLATEDETAILS,
  RESET_INITIAL_STATE,
  RESET_PIPELINETEMPLATEDETAILS,
} from '../../../../redux/constants';
import { displaySpinner } from '../../../../redux/spinner/Action';
import PipelinePBComp from '../../../analysis/PipelinePBComp';
import FacilityHeaderComp from '../FacilityHeaderComp';
import GasTariffTableComp from '../tariff/GasTariffTableComp';
import OilTariffTableComp from '../tariff/OilTariffTableComp';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  templatecontainer: {
    padding: '10px',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
  embedcontainer: {
    height: '900px',
    width: '100%',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function PipelineTemplateComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);

  // const rtoken = useSelector((state) => state.reportReducer.accesstoken);
  // const rtime = useSelector((state) => state.reportReducer.recordedtime);

  // const templatevalues = useSelector((state) => state.FacilityReducer.templatevalues);

  // const [pipelineheader, setPipelineHeader] = React.useState([]);

  // const [accesstoken, setAccessToken] = React.useState("");
  const [pipelinename, setPipelineName] = React.useState('');

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  // const [facilitylabel, setFacilityLabel] = React.useState({
  //   symbol: {
  //     type: 'text',
  //     color: '#000000',
  //     // haloColor: '#d83435',
  //     // haloSize: '1px',
  //     font: {
  //       size: '12px',
  //       family: 'Arial',
  //       // style: 'italic',
  //       weight: 'normal',
  //     },
  //   },
  //   labelPlacement: 'above-center',
  //   labelExpressionInfo: {
  //     expression: '$feature.Facility',
  //   },
  // });

  const [pipelinefacilities, setPipelineFacilities] = React.useState([]);

  // const [tabvalue, setTabValue] = React.useState(0);

  // const fnTabChange = (event, newValue) => {
  //   setTabValue(newValue);
  // };

  const [pointsdialogopen, setPointsDialogOpen] = React.useState(false);
  // Closes the Points dialog.
  const fnClosePointsDialog = () => {
    // Set the state to hide the Points dialog
    setPointsDialogOpen(false);
  };

  // const pipelineheadercolumns = [
  //   {
  //     title: 'Name',
  //     dataIndex: 'name',
  //     width: '35%',
  //   },
  //   {
  //     title: 'Value',
  //     dataIndex: 'value',
  //     render: (text, record, index) => {
  //       if (record.name === 'InserviceDate' || record.name === 'SectionInservicedate') {
  //         return <>{dateFormat(text)}</>;
  //       }
  //       return <>{text}</>;
  //     },
  //   },
  // ];

  // Handles the click event on a facility.
  const fnOpenFacility = (id, name, index) => {
    // dispatch({
    //   type: RESET_PIPELINETEMPLATEDETAILS,
    // });

    let planttype = index;

    if (assemblies.palist.indexOf(index) > -1) {
      planttype = 'Pipeline Assembly';
    }

    const templatevalues = {
      index: planttype,
      id,
      name,
    };

    // Get the screen width and height
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;

    const windowfeature = `width=${screenWidth},height=${screenHeight}`;

    if (planttype === 'Pipeline') {
      dispatch({
        type: ADD_PIPELINETEMPLATEDETAILS,
        payload: {
          pipelinetemplatevalues: templatevalues,
        },
      });

      window.open(
        `${window.location.origin}/fi/pipelinefacilityinfo`, // URL for the Power BI dashboard
        '_blank', // open in a new window
        windowfeature, // set the window size
      );
    } else {
      dispatch({
        type: ADD_TEMPLATEDETAILS,
        payload: {
          templatevalues,
        },
      });

      window.open(
        `${window.location.origin}/fi/facilityinfo`, // URL for the Power BI dashboard
        '_blank', // open in a new window
        windowfeature, // set the window size
      );
    }
  };

  const [focusonmap, setFocusOnMap] = React.useState({
    Latitude: 0,
    Longitude: 0,
  });
  const fnNavigateToSegment = (rowData) => {
    setFocusOnMap({
      Latitude: rowData[10],
      Longitude: rowData[9],
    });
  };

  // Handles the click event on a terminal.
  const fnHandleTerminalClick = (rowdata) => {
    if (window.location.href.indexOf('query') > 0) {
      // dispatch({
      //   type: actionType.ADD_LOCATION,
      //   payload: {
      //     lat: rowdata[7],
      //     long: rowdata[8],
      //     facility: facname,
      //   },
      // });
      // props.closepopupwindow();
    } else {
      fnOpenFacility(rowdata[3], rowdata[2], rowdata[9]);
    }
  };

  // Handles the click event on a meter.
  const fnHandleMeterClick = (rowdata) => {
    if (window.location.href.indexOf('query') > 0) {
      // dispatch({
      //   type: actionType.ADD_LOCATION,
      //   payload: {
      //     lat: rowdata[17],
      //     long: rowdata[18],
      //     facility: facname,
      //   },
      // });
      // props.closepopupwindow();
    } else {
      fnOpenFacility(rowdata[4], rowdata[2], rowdata[3]);
    }
  };

  // Handles the click event on flow data.
  const fnHandleFlowDataClick = (rowdata) => {
    if (window.location.href.indexOf('query') > 0) {
      // dispatch({
      //   type: actionType.ADD_LOCATION,
      //   payload: {
      //     lat: rowdata[17],
      //     long: rowdata[18],
      //     facility: facname,
      //   },
      // });
      // props.closepopupwindow();
    } else {
      fnOpenFacility(rowdata[0], rowdata[1], rowdata[2]);
    }
  };

  // Handles the click event on shipper point data.
  const fnHandleShipperPointDataClick = (rowdata) => {
    fnOpenFacility(rowdata[8], rowdata[7], rowdata[6]);
  };

  // Fetches the pipeline header data from the database.
  const [facilitydata, setFacilityData] = React.useState([]);
  const [commodityhandled, setCommodityHandled] = React.useState('');
  const fnFetchPipelineHeader = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'pipelineheader',
        id: props.PipelineId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        const metadata = Object.keys(response.data[0].data[0]);

        const data = [];

        metadata.forEach((element) => {
          if (element !== 'Id' && element !== 'FromId' && element !== 'ToId') {
            // //console.log(': ' + element);
            // //console.log('data : ' + response.data[0].data[0][element]);
            // //console.log(response.data[0].data[0][element] === '' ? 'true' : 'false');
            // //console.log(typeof response.data[0].data[0][element]);

            if (
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data.push({
                name: element,
                value: response.data[0].data[0][element],
              });
            }

            if (element === 'FromLongitude') {
              data.push({
                name: 'From Terminal Google Maps',
                value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0].FromLatitude},${response.data[0].data[0].FromLongitude}`,
              });
            }

            if (element === 'ToLongitude') {
              data.push({
                name: 'To Terminal Google Maps',
                value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0].ToLatitude},${response.data[0].data[0].ToLongitude}`,
              });
            }

            if (element === 'CommodityHandled') {
              setCommodityHandled(response.data[0].data[0][element]);
            }

            if (element === 'Pipeline Name') {
              setPipelineName(response.data[0].data[0][element]);
            }
          }
        });

        // setPipelineHeader(data);

        const facility = [
          {
            'Pipeline Name': response.data[0].data[0]['Pipeline Name'],
            'Commodity Handled': response.data[0].data[0].CommodityHandled,
            'Operator Name': response.data[0].data[0].OperatorName,
            'Owner Name': response.data[0].data[0].OwnerName,
            'Regulatory Type': response.data[0].data[0].RegulatoryType,
            LifeCycleStatus: response.data[0].data[0].LifeCycleStatus,
            'Inservice Date': response.data[0].data[0]['Inservice Date'],
            ReportedLengthMiles: response.data[0].data[0].ReportedLengthMiles,
            'Pipeline Capacity BCFD': response.data[0].data[0].PipelineCapacityBCFD,
            'Pipeline Capacity BPD': response.data[0].data[0].PipelineCapacityBPD,
            'Nominal Diameter Inch': response.data[0].data[0].NominalDiameterInch,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Pipeline Section Information Start

  const interconnectoptions = {
    print: false,
    selectableRows: 'none',
    resizableColumns: true,
    filterType: 'multiselect',
    // downloadOptions: {
    //   filterOptions: {
    //     useDisplayedColumnsOnly: true,
    //     useDisplayedRowsOnly: true,
    //   },
    // },
  };

  const pipelinesectionoptions = PipelineTableOptions();

  const pipelinesectioncolumns = [
    {
      name: 'Id',
      options: {
        filter: false,
        display: false,
        download: false,
      },
    },
    {
      name: 'PipelineName',
      label: 'Pipeline Name',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'PipelineSectionName',
      label: 'Section Name',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnNavigateToSegment(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'SegmentId',
      label: 'Segment Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'SegmentStatus',
      label: 'Segment Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'FromId',
      options: {
        display: 'excluded',
        download: false,
        filter: false,
      },
    },
    {
      name: 'SectionFromDeviceTerminalType',
      label: 'From Facility Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'SectionFromDeviceTerminal',
      label: 'From Facility',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  fnOpenFacility(tableMeta.rowData[5], tableMeta.rowData[7], tableMeta.rowData[6])
                }
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'FromLatitude',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'FromLongitude',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'FromLatitude',
      label: 'From Facility Lat-Long',
      options: {
        filter: false,
        download: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[8] !== null && tableMeta.rowData[9] !== null) {
            const glink = `https://www.google.com/maps/search/?api=1&query=${tableMeta.rowData[8]},${tableMeta.rowData[9]}`;
            return (
              <>
                <a target="_blank" href={glink} rel="noreferrer">
                  {tableMeta.rowData[8].toFixed(2)},{tableMeta.rowData[9].toFixed(2)}
                </a>
              </>
            );
          }
          return <></>;
        },
      },
    },
    {
      name: 'FromStateName',
      label: 'From StateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'FromCountyName',
      label: 'From CountyName',
      options: {
        filter: true,
      },
    },
    {
      name: 'ToId',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'SectionToDeviceTerminalType',
      label: 'To Facility Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'SectionToDeviceTerminal',
      label: 'To Facility',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  fnOpenFacility(
                    tableMeta.rowData[13],
                    tableMeta.rowData[15],
                    tableMeta.rowData[14],
                  )
                }
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'ToLatitude',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'ToLongitude',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'ToLatitude',
      label: 'To Facility Lat-Long',
      options: {
        filter: false,
        download: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[16] !== null && tableMeta.rowData[17] !== null) {
            const glink = `https://www.google.com/maps/search/?api=1&query=${tableMeta.rowData[16]},${tableMeta.rowData[17]}`;
            return (
              <>
                {/* <Link
                href={
                  glink
                }
                to={
                  glink
                }
                target="_blank"
              >
                {tableMeta.rowData[15]},{tableMeta.rowData[16]}
              </Link> */}
                <a target="_blank" href={glink} rel="noreferrer">
                  {tableMeta.rowData[16].toFixed(2)},{tableMeta.rowData[17].toFixed(2)}
                </a>
              </>
            );
          }
          return <></>;
        },
      },
    },
    {
      name: 'ToStateName',
      label: 'To StateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'ToCountyName',
      label: 'To CountyName',
      options: {
        filter: true,
      },
    },
    {
      name: 'FromToSectionCapacityMMBTU',
      label: 'From To Section Capacity MMBTU',
      options: {
        filter: true,
      },
    },
    {
      name: 'ToFromSectionCapacityMMBTU',
      label: 'To From Section Capacity MMBTU',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineSectionGid',
      label: 'GID',
      options: {
        filter: false,
        display: false,
      },
    },
  ];

  /**
   * Fetches pipeline section data for the selected pipeline.
   * Sets the fetched data in the state.
   */
  const [pipelinesection, setPipelineSection] = React.useState([]);
  const fnFetchPipelineSection = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'pipelinesection',
        id: props.PipelineId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setPipelineSection(response.data[0].data);

        const data = [];

        response.data[0].data.forEach((element) => {
          const fromfacilityexists = linq
            .from(data)
            .where(
              (a) =>
                a.PlantType === element.SectionFromDeviceTerminalType &&
                a.Facility === element.SectionFromDeviceTerminal,
            )
            .toArray();

          const tofacilityexists = linq
            .from(data)
            .where(
              (a) =>
                a.PlantType === element.SectionToDeviceTerminalType &&
                a.Facility === element.SectionToDeviceTerminal,
            )
            .toArray();

          if (!fromfacilityexists.length > 0 && element.FromId != null) {
            const fromFacility = {
              PlantType: element.SectionFromDeviceTerminalType,
              Facility: element.SectionFromDeviceTerminal,
              PlantId: element.FromId,
              PipelineHeaderId: element.PipelineHeaderId,
              PipelineName: element.PipelineName,
              PipelineProduct: element.CommodityHandled,
              SectionId: element.PipelineSectionId,
              SectionName: element.PipelineSectionName,
              Latitude: element.FromLatitude,
              Longitude: element.FromLongitude,
            };

            data.push(fromFacility);
          }

          if (!tofacilityexists.length > 0 && element.ToId != null) {
            const toFacility = {
              PlantType: element.SectionToDeviceTerminalType,
              Facility: element.SectionToDeviceTerminal,
              PlantId: element.ToId,
              PipelineHeaderId: element.PipelineHeaderId,
              PipelineName: element.PipelineName,
              PipelineProduct: element.CommodityHandled,
              SectionId: element.PipelineSectionId,
              SectionName: element.PipelineSectionName,
              Latitude: element.ToLatitude,
              Longitude: element.ToLongitude,
            };

            data.push(toFacility);
          }
        });

        // var interconnects = linq
        //   .from(data)
        //   .where(
        //     (a) =>
        //       a.PlantType === "Interconnect" ||
        //       a.PlantType === "Compressor Station"
        //   )
        //   .select((a) => a.Facility)
        //   .toArray();

        setPipelineFacilities(data);

        // if (interconnects.length > 0) {
        //   fnFetchInterconnectsData(interconnects, props.PipelineId);
        // }
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gatheringsystemids, setGatheringSystemIds] = React.useState([]);
  const fnFetchGatheringSystemIds = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gspipelineconns',
        id: props.PipelineId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({
        //   show: true,
        //   message: 'No records found',
        //   variant: 'info',
        // });
      } else {
        const gsids = response.data[0].data.map((a) => a.GatheringSystemId);

        setGatheringSystemIds(gsids);
      }
    } catch (error) {
      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Expand/Collapse Pipeline Section View
  const [pipelinesectionsexpanded, setPipelineSectionExpanded] = React.useState(false);
  /**
   * Toggles the expansion state of the pipeline sections and, if necessary,
   * triggers the fetch of pipeline sections.
   */
  const fnExpandPipelineSections = () => {
    // Toggle the expansion state.
    setPipelineSectionExpanded(!pipelinesectionsexpanded);

    // If the sections are not expanded and there are no sections to display,
    // fetch the pipeline sections.
    if (!pipelinesectionsexpanded && pipelinesection.length === 0) {
      fnFetchPipelineSection();
    }
  };

  // Pipeline Section Information End

  // Pipeline Interconnects Information Start

  const interconnectcolumns = [
    {
      name: 'InterconnectedPipelineHeaderId',
      label: 'PipelineHeaderId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'InterconnectedPipelineName',
      label: 'Interconnected Pipeline',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityName',
      label: 'FacilityName',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnHandleTerminalClick(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'FacilityId',
      label: 'FacilityId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'Latitude',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'Longitude',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'Longitude',
      label: 'From Facility Lat-Long',
      options: {
        filter: true,
        download: false,
        customBodyRender: (value, tableMeta) => {
          const glink = `https://www.google.com/maps/search/?api=1&query=${tableMeta.rowData[4]},${tableMeta.rowData[5]}`;
          return (
            <>
              {/* <Link
                href={
                  glink
                }
                to={
                  glink
                }
                target="_blank"
              >
                {tableMeta.rowData[4]},{tableMeta.rowData[5]}
              </Link> */}
              <a target="_blank" href={glink} rel="noreferrer">
                {tableMeta.rowData[4]},{tableMeta.rowData[5]}
              </a>
            </>
          );
        },
      },
    },
    {
      name: 'CommodityHandled',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineDirection',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityType',
      label: 'FacilityType',
      options: {
        filter: true,
      },
    },
  ];

  const [pipelineinterconnects, setPipelineInterconnects] = React.useState([]);
  /**
   * Fetches the pipeline interconnects data from the database.
   *
   * This function is called when the component is mounted and when the user
   * clicks on the "Expand/Collapse Interconnects" button.
   *
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const fnFetchInterconnectsData = async () => {
    if (pipelineinterconnects.length === 0) {
      // Display the spinner while the operation is in progress
      dispatch(displaySpinner(true));

      try {
        // Create the query details object for fetching the pipeline interconnects
        const queryDetails = {
          pipelines: [props.PipelineId],
        };

        // Create options for the axios request
        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        // Send the axios request to fetch the pipeline interconnects
        const response = await axios.post('/api/pipelineinterconnects', queryDetails, options);

        // Hide the spinner
        dispatch(displaySpinner(false));

        // Check the response status and display the appropriate message
        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          // Set the pipeline interconnects data if available
          setPipelineInterconnects(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  // Expand/Collapse Pipeline Interconnects View
  const [interconnectsexpanded, setInterconnects] = React.useState(false);
  /**
   * Toggles the view of the pipeline interconnects data.
   *
   * If the view is collapsed and the data has not been fetched yet, this
   * function fetches the data and expands the view.
   */
  const fnExpandInterconnects = () => {
    setInterconnects(!interconnectsexpanded);

    if (!interconnectsexpanded && pipelineinterconnects.length === 0) {
      // If the view is collapsed and the data has not been fetched yet, fetch the data.
      fnFetchInterconnectsData();
    }
  };

  // Pipeline Interconnects Information End

  // Pipeline Facilities Information End

  const pipelinemeterscolumns = [
    {
      name: 'Id',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'PipelineName',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'FacilityName',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnHandleMeterClick(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'FacilityType',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'TSP',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'LocationActualName',
      options: {
        filter: true,
      },
    },
    {
      name: 'FlowDirection',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'NominalIndicator',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'LocationStatus',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
        customBodyRender: (value) => {
          if (value === null) {
            return <></>;
          }
          // return <>{dateFormat(value)}</>;
          return <>{value}</>;
        },
      },
    },
    {
      name: 'InactiveDate',
      options: {
        filter: true,
        display: false,
        customBodyRender: (value) => {
          if (value === null) {
            return <></>;
          }
          // return <>{dateFormat(value)}</>;
          return <>{value}</>;
        },
      },
    },
    {
      name: 'UpDownIndicator',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'UpDownName',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'UpDownId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'Latitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'Longitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'StateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'CountyName',
      options: {
        filter: true,
      },
    },
  ];

  const pipelinemetersoilcolumns = [
    {
      name: 'Id',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'PipelineName',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'FacilityName',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnHandleMeterClick(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'FacilityType',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'Latitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'Longitude',
      options: {
        filter: true,
      },
    },
    {
      name: 'StateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'CountyName',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches pipeline facilities data from the server and updates the state.
   *
   * @param {string} pipelineid - The ID of the pipeline.
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const [pipelinemeters, setPipelineMeters] = React.useState([]);
  const fnFetchFacilitiesData = async (pipelineid) => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        pipelineid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/pipelinefacilities', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setPipelineMeters(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [facilitiesexpanded, setFacilities] = React.useState(false);
  /**
   * Toggles the state of the facilitiesExpanded variable and fetches data if necessary.
   * @returns {void}
   */
  const fnExpandFacilities = () => {
    // Toggle the state of the facilitiesExpanded variable
    setFacilities(!facilitiesexpanded);

    // If the facilities are not expanded and there are no pipeline facilities yet
    if (!facilitiesexpanded && pipelinemeters.length === 0) {
      // Fetch the pipeline facilities data
      fnFetchFacilitiesData(props.PipelineId);
    }
  };

  // Pipeline Facilities Information End

  // Pipeline Shippers Information Start
  const [shipperpoints, setShipperPoints] = React.useState([]);
  /**
   * Fetches the shipper points data from the database.
   *
   * This function is called when the user clicks on the "View Shipper Points" button.
   *
   * @param {string} detailId - The shipper detail ID.
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const fnFetchShipperPointData = async (detailId) => {
    // Show the spinner while the operation is in progress
    dispatch(displaySpinner(true));

    // Create the query details object for fetching the shipper points
    const queryDetails = {
      id: detailId,
    };

    // Create options for the axios request
    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    try {
      // Send the axios request to fetch the shipper points
      const response = await axios.post('/api/fetchshipperpoints', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Check the response status and display the appropriate message
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Set the shipper points data if available
        setShipperPoints(response.data[0].data);

        // Open the points dialog
        setPointsDialogOpen(true);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const shipperdetailcolumns = [
    {
      name: 'ShipperDetailId',
      label: 'Id',
      options: {
        filter: false,
      },
    },
    {
      name: 'ShipperName',
      label: 'Shipper Name',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnFetchShipperPointData(tableMeta.rowData[0])}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'ShipperAffiliationIndicator',
      label: 'Affiliation Indicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'RateSchedule',
      label: 'Rate Schedule',
      options: {
        filter: true,
      },
    },
    {
      name: 'ContractNumber',
      label: 'Contract Number',
      options: {
        filter: true,
      },
    },
    {
      name: 'ContractEffectiveYear',
      label: 'Effective Year',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta) => {
        //   return <>{dateFormat(value)}</>;
        // },
      },
    },
    {
      name: 'ContractExpirationYear',
      label: 'Expiration Year',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta) => {
        //   return <>{dateFormat(value)}</>;
        // },
      },
    },
    {
      name: 'NegotiatedRatesIndicator',
      label: 'NegotiatedRatesIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'TransportMDQ',
      label: 'Transport MDQ',
      options: {
        filter: true,
      },
    },
    {
      name: 'StorageMSQ',
      label: 'Storage MSQ',
      options: {
        filter: true,
      },
    },
  ];

  const shipperpointcolumns = [
    {
      name: 'ShipperPointId',
      label: 'Id',
      options: {
        filter: true,
      },
    },
    {
      name: 'ShipperPointCode',
      label: 'Point Code',
      options: {
        filter: true,
      },
    },
    {
      name: 'ShipperPointValue',
      label: 'Point Value',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointName',
      label: 'Point Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointQualifierCode',
      label: 'Qualifier Code',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      label: 'Location Code',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityType',
      label: 'Facility Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'FacilityName',
      label: 'Facility Name',
      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnHandleShipperPointDataClick(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'FacilityId',
      options: {
        filter: true,
        display: false,
      },
    },
    {
      name: 'ZoneName',
      label: 'Zone Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointTransportMDQ',
      label: 'Transport MDQ',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointStorageMSQ',
      label: 'Storage MSQ',
      options: {
        filter: true,
      },
    },
  ];

  const [shipperdetails, setShipperDetails] = React.useState([]);
  /**
   * Fetches the shipper details for a given report ID from the API.
   *
   * @param {string} reportid - The ID of the report.
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const fnFetchPipelineShipperDetails = async (reportid) => {
    // Show the spinner while the operation is in progress
    dispatch(displaySpinner(true));

    try {
      // Create the query details object for fetching the shipper details
      const queryDetails = {
        id: reportid,
      };

      // Create options for the axios request
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Send the axios request to fetch the shipper details
      const response = await axios.post('/api/fetchshipperdetails', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Check the response status and display the appropriate message
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Set the shipper details data if available
        setShipperDetails(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches the report dates for shippers for a given pipeline ID from the API.
   *
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const [shipperreportdates, setShipperReportDates] = React.useState([]);
  const [shipperselectedreportdate, setShipperSelectedReportDate] = React.useState('');
  const fnFetchReportDates = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        id: props.PipelineId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchshipperreportdates', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        const dtes = response.data[0].data.map((value) => ({
          Id: value.Id,
          ReportDate: dateFormat(value.ReportDate),
        }));

        const idate = {
          Id: response.data[0].data[0].Id,
          ReportDate: dateFormat(response.data[0].data[0].ReportDate),
        };

        setShipperReportDates(dtes);

        setShipperSelectedReportDate(idate);

        fnFetchPipelineShipperDetails(response.data[0].data[0].Id);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Toggles the view of the pipeline shippers data.
   *
   * If the view is collapsed and the data has not been fetched yet, this
   * function fetches the data and expands the view.
   */
  const [shippersexpanded, setShippers] = React.useState(false);
  const fnExpandShippers = () => {
    setShippers(!shippersexpanded);

    if (!shippersexpanded && shipperreportdates.length === 0) {
      fnFetchReportDates();
    }
  };

  /**
   * Fetches the shipper details data for a given option and sets it as the
   * selected report date. Then, fetches the pipeline shipper details using
   * the option's Id.
   *
   * @param {Object} option - The selected report date option.
   */
  const fnFetchShipperDetailData = (option) => {
    setShipperSelectedReportDate(option);
    fnFetchPipelineShipperDetails(option.Id);
  };

  // Pipeline Shippers Information End

  // Pipeline Gas Flow Information Start

  const gasflowdetailcolumns = [
    {
      name: 'FacilityId',
      options: {
        filter: false,
        display: false,
      },
    },
    {
      name: 'FacilityName',

      options: {
        filter: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnHandleFlowDataClick(tableMeta.rowData)}
              >
                {value}
              </Link>
            </>
          );
        },
      },
    },
    {
      name: 'Facilitytype',

      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      label: 'Location Code',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      label: 'Zone',
      options: {
        filter: false,
        display: false,
        download: false,
      },
    },
    {
      name: 'DesignCapacity',

      options: {
        filter: true,
      },
    },
    {
      name: 'OperatingCapacity',

      options: {
        filter: true,
      },
    },
    {
      name: 'AvgScheduledQuantity',

      options: {
        filter: true,
      },
    },
    {
      name: 'AvgOperationallyAvailableCapacity',

      options: {
        filter: true,
      },
    },
    {
      name: 'FlowIndicator',

      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveMonth',

      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveYear',

      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches the years of gas plant data from the API.
   *
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const [gasflowreportyears, setGasFlowReportYears] = React.useState([]);
  const fnFetchGasPlantYears = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'pipelinegasflow',
        id: props.PipelineId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGasFlowReportYears(response.data[0].data.pipelinegasflow);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlow] = React.useState(false);
  /**
   * Expand or collapse the gas flow report section.
   * If the section is expanded and there are no gas flow report years,
   * fetch the gas flow report years.
   */
  const fnExpandGasFlow = () => {
    setGasFlow(!gasflowexpanded);

    // If the section is expanded and there are no gas flow report years,
    // fetch the gas flow report years
    if (!gasflowexpanded && gasflowreportyears.length === 0) {
      // fnFetchGasFlowMonths();
      fnFetchGasPlantYears();
    }
  };

  // const [gasflowreportmonths, setGasFlowReportMonths] = React.useState([]);
  // const [gasflowselectedmonth, setGasFlowSelectedMonth] = React.useState('');

  // const fnFetchGasFlowMonths = () => {
  //   dispatch(displaySpinner(true));

  //   const queryDetails = {
  //     id: props.PipelineId,
  //   };

  //   const options = {
  //     headers: {
  //       authorization: token ? `Bearer ${token}` : '',
  //     },
  //   };

  //   axios.post('/api/fetchpipelinegasflowmonths', queryDetails, options).then(
  //     (response) => {
  //       dispatch(displaySpinner(false));

  //       if (response.data[0] !== undefined && response.data[0].status === 'error') {
  //         setStatusBase({
  //           show: true,
  //           message: 'An error occurred during execution.',
  //           variant: 'error',
  //         });
  //       } else if (response.data[0].status === 'no records found') {
  //         setStatusBase({ show: true, message: 'Gas flow data not available.', variant: 'info' });
  //       } else {
  //         // setGasFlowReportMonths(response.data[0].data);

  //         // setGasFlowSelectedMonth(response.data[0].data[0]);

  //         // fnFetchGasFlowData(
  //         //   response.data[0].data[0].EffectiveMonth,
  //         //   response.data[0].data[0].EffectiveYear
  //         // );
  //       }
  //     },

  //     (error) => {
  //       dispatch(displaySpinner(false));
  //       if (error.request.status === 500) {
  //         navigate('/', { replace: true });
  //       } else {
  //         setStatusBase({
  //           show: true,
  //           message: 'An error occurred during execution.',
  //           variant: 'error',
  //         });
  //       }
  //     },
  //   );
  // };

  /**
   * Fetches additional data for a given index and year from the API.
   *
   * @param {string} index - The index of the data.
   * @param {string} year - The year of the data.
   * @return {Promise<void>} Promise that resolves when the operation is complete.
   */
  const [gasflowdetails, setGasFlowDetails] = React.useState([]);
  const fnFetchAdditionalData = async (index, year) => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index,
        id: props.PipelineId,
        year,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGasFlowDetails(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // const fnFetchGasFlowData = (year) => {
  //   dispatch(displaySpinner(true));

  //   const queryDetails = {
  //     id: props.PipelineId,
  //     year,
  //   };

  //   const options = {
  //     headers: {
  //       authorization: token ? `Bearer ${token}` : '',
  //     },
  //   };

  //   axios.post('/api/fetchpipelinegasflowmonthlydata', queryDetails, options).then(
  //     (response) => {
  //       dispatch(displaySpinner(false));

  //       if (response.data[0] !== undefined && response.data[0].status === 'error') {
  //         setStatusBase({
  //           show: true,
  //           message: 'An error occurred during execution.',
  //           variant: 'error',
  //         });
  //       } else if (response.data[0].status === 'no records found') {
  //         setStatusBase({
  //           show: true,
  //           message: 'No records found',
  //           variant: 'info',
  //         });
  //       } else {
  //         setGasFlowDetails(response.data[0].data);
  //       }
  //     },

  //     (error) => {
  //       dispatch(displaySpinner(false));
  //       if (error.request.status === 500) {
  //         navigate('/', { replace: true });
  //       } else {
  //         setStatusBase({
  //           show: true,
  //           message: 'An error occurred during execution.',
  //           variant: 'error',
  //         });
  //       }
  //     },
  //   );
  // };

  /**
   * Fetches gas flow detail data based on the selected options.
   * If options are provided, it calls fnFetchAdditionalData with the appropriate arguments.
   * Otherwise, it sets gas flow details to an empty array.
   *
   * @param {Array} option - The selected options for gas flow detail data.
   */
  const fnFetchGasFlowDetailData = (option) => {
    // setGasFlowSelectedMonth(option);

    if (option.length > 0) {
      fnFetchAdditionalData('pipeliengasflow', option.join());
    } else {
      setGasFlowDetails([]);
    }
  };

  // Pipeline Gas Flow  Information End

  const gsgasprodcolumns = [
    {
      name: 'GatheringSystem',
      options: {
        filter: true,
      },
    },
    {
      name: 'P5Number',
      options: {
        filter: true,
      },
    },
    {
      name: 'LeaseNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'APINumber',
      label: 'APINumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'WellNumber',
      label: 'WellNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasMCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasInjectedMCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'CondensateBBL',
      options: {
        filter: true,
      },
    },
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },
  ];

  // Fetches the years of gas plant data from the API.
  const [gsgasproductionyears, setGSGasProductionYears] = React.useState([]);
  const fnFetchGSGasProductionYears = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsgaswellprod',
        id: gatheringsystemids.join(','),
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSGasProductionYears(response.data[0].data.gaswellproduction);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gsgasproductionexpanded, setGSGasProductionExpanded] = React.useState(false);

  const fnExpandGSGasProduction = () => {
    setGSGasProductionExpanded(!gsgasproductionexpanded);

    // If the section is expanded and there are no gas flow report years,
    // fetch the gas flow report years
    if (!gsgasproductionexpanded && gsgasproductionyears.length === 0) {
      // fnFetchGasFlowMonths();
      fnFetchGSGasProductionYears();
    }
  };

  const [gsgasproductiondata, setGSGasProductionData] = React.useState([]);
  const fnFetchGSGasProductionData = async (selectedyear) => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'gsgaswellprod',
        id: gatheringsystemids.join(','),
        year: selectedyear,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        setGSGasProductionData(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  // Pipeline Tariff Information Start

  /**
   * Toggles the tariff expanded state and updates the gas/oil tariff pipeline name
   * if necessary.
   */
  const [gastariffpipelinename, setGasTariffPipelineName] = React.useState('');
  const [oiltariffpipelinename, setOilTariffPipelineName] = React.useState('');
  const [tariffexpanded, setTariff] = React.useState(false);
  const fnExpandTariff = () => {
    setTariff(!tariffexpanded);

    if (!tariffexpanded && commodityhandled === 'Natural Gas' && gastariffpipelinename === '') {
      setGasTariffPipelineName(pipelinename);
    }

    if (
      !tariffexpanded &&
      (commodityhandled === 'Refined Products' ||
        commodityhandled === 'Crude Oil' ||
        commodityhandled === 'LPG' ||
        commodityhandled === 'NGL') &&
      oiltariffpipelinename === ''
    ) {
      setOilTariffPipelineName(pipelinename);
    }
  };

  // Pipeline Tariff Information End

  // Pipeline Analysis Information Start

  /**
   * Toggles the analysis expanded state.
   */
  const [analysisexpanded, setAnalysis] = React.useState(false);
  const fnExpandAnalysis = () => {
    setAnalysis(!analysisexpanded);
  };

  // Pipeline Analysis Information End

  // Processes the data by fetching the pipeline header and pipeline section.
  const fnProcessData = () => {
    fnFetchPipelineHeader();
    fnFetchPipelineSection();
    fnFetchGatheringSystemIds();
  };

  const [dailyflowdialog, setDisplayDailyFlowDialog] = React.useState(false);

  /**
   * Closes the daily flow dialog.
   *
   * Sets the `dailyflowdialog` state to `false` to close the dialog.
   */
  const fnCloseDailyFlowDialog = () => {
    setDisplayDailyFlowDialog(false);
  };

  // const fnOpenDailyFlowDialog = () => {
  //   setDisplayDailyFlowDialog(true);
  // };

  const [selecteddailyflowyear, setSelectedDailyFlowYear] = React.useState({});
  // Pipeline Daily Flow Data Download
  const fnDownloadDailyFlow = () => {};

  useEffect(() => {
    dispatch(displaySpinner(false));

    fnProcessData();

    // if (rtoken === undefined || rtoken === null) {
    //   fnFetchPBAccessToken();
    // } else {
    //   var currentdate = new Date();

    //   var diff = Math.abs(currentdate - rtime) / 60000;

    //   if (diff > 10) {
    //     fnFetchPBAccessToken();
    //   } else {
    //     setAccessToken(rtoken);
    //   }
    // }
  }, []);

  return (
    <>
      <Box>
        {/* {JSON.stringify(gatheringsystemids)} */}
        {facilitydata.length > 0 && (
          <FacilityHeaderComp
            facilitydata={facilitydata}
            type="Pipeline"
            pipelineid={props.PipelineId}
            pipelinefacilities={pipelinefacilities}
            gscons={gatheringsystemids}
            focusonmap={focusonmap}
          ></FacilityHeaderComp>
        )}
      </Box>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={pipelinesectionsexpanded}
        onChange={fnExpandPipelineSections}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Pipeline Sections</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable
              data={pipelinesection}
              columns={pipelinesectioncolumns}
              options={pipelinesectionoptions}
            />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={interconnectsexpanded}
        onChange={fnExpandInterconnects}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Interconnected Pipelines</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable
              data={pipelineinterconnects}
              columns={interconnectcolumns}
              options={interconnectoptions}
            />
          </ThemeProvider>
        </AccordionDetails>
      </Accordion>
      {facilitydata.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={facilitiesexpanded}
          onChange={fnExpandFacilities}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Pipeline Facilities</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={pipelinemeters}
                columns={
                  facilitydata[0]['Commodity Handled'] === 'Natural Gas'
                    ? pipelinemeterscolumns
                    : pipelinemetersoilcolumns
                }
                options={pipelinesectionoptions}
              />
            </ThemeProvider>
          </AccordionDetails>
        </Accordion>
      )}
      {commodityhandled === 'Natural Gas' && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={shippersexpanded}
          onChange={fnExpandShippers}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography className={classes.heading}>Pipeline Shippers</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            {shipperreportdates.length > 0 && (
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid xs={3} sx={{ pr: '2px' }} item>
                  <Autocomplete
                    disablePortal
                    value={shipperselectedreportdate}
                    id="combo-box-demo"
                    options={shipperreportdates}
                    onChange={(event, newValue) => {
                      fnFetchShipperDetailData(newValue);
                    }}
                    getOptionLabel={(option) => option.ReportDate}
                    fullWidth
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Select Report Date"
                        aria-label="Select Report Date"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            {shipperdetails.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={shipperdetails}
                  columns={shipperdetailcolumns}
                  options={pipelinesectionoptions}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {commodityhandled === 'Natural Gas' && (
        <>
          <Accordion
            sx={{ m: 0, mt: '10px' }}
            expanded={gasflowexpanded}
            onChange={fnExpandGasFlow}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography className={classes.heading}>Gas Monthly Flow Data</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              {gasflowreportyears.length > 0 && (
                <>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    {/* <Grid xs={3} item>
                      <Button variant="outlined" onClick={fnOpenDailyFlowDialog}>Download Daily Flow</Button>
                    </Grid> */}
                    <Grid xs={3} sx={{ pr: '2px' }} item>
                      <Autocomplete
                        disablePortal
                        multiple
                        // value={gasflowselectedmonth}
                        id="combo-box-demo"
                        options={gasflowreportyears}
                        onChange={(event, newValue) => {
                          fnFetchGasFlowDetailData(newValue);
                        }}
                        // getOptionLabel={(value) => {

                        //   return (
                        //     value.EffectiveMonth +
                        //     "-" +
                        //     value.EffectiveYear

                        //   )
                        // }}
                        fullWidth
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Select Report Year"
                            aria-label="Select Report Year"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {gasflowdetails.length > 0 && (
                    <ThemeProvider theme={getMuiTableTheme}>
                      <MUIDataTable
                        data={gasflowdetails}
                        columns={gasflowdetailcolumns}
                        options={pipelinesectionoptions}
                      />
                    </ThemeProvider>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
      {commodityhandled === 'Natural Gas' && gatheringsystemids.length > 0 && (
        <>
          <Accordion
            sx={{ m: 0, mt: '10px' }}
            expanded={gsgasproductionexpanded}
            onChange={fnExpandGSGasProduction}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <Typography className={classes.heading}>
                Gathering System Gas Production Data
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              {gsgasproductionyears.length > 0 && (
                <>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid xs={3} sx={{ pr: '2px' }} item>
                      <Autocomplete
                        disablePortal
                        multiple
                        // value={gasflowselectedmonth}
                        id="combo-box-demo"
                        options={gsgasproductionyears}
                        onChange={(event, newValue) => {
                          fnFetchGSGasProductionData(newValue);
                        }}
                        fullWidth
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Select Report Year"
                            aria-label="Select Report Year"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {gsgasproductiondata.length > 0 && (
                    <ThemeProvider theme={getMuiTableTheme}>
                      <MUIDataTable
                        data={gsgasproductiondata}
                        columns={gsgasprodcolumns}
                        options={pipelinesectionoptions}
                      />
                    </ThemeProvider>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
      <Accordion sx={{ m: 0, mt: '10px' }} expanded={tariffexpanded} onChange={fnExpandTariff}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography className={classes.heading}>Tariff Data</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {gastariffpipelinename !== '' && (
            <>
              <Paper style={{ width: '100%' }}>
                <GasTariffTableComp
                  title={gastariffpipelinename}
                  facilitydata={facilitydata}
                  pipelineid={props.PipelineId}
                  pipelinefacilities={pipelinefacilities}
                />
              </Paper>
            </>
          )}

          {oiltariffpipelinename !== '' && (
            <>
              <Paper style={{ width: '100%' }}>
                <OilTariffTableComp
                  title={oiltariffpipelinename}
                  facilitydata={facilitydata}
                  pipelineid={props.PipelineId}
                  pipelinefacilities={pipelinefacilities}
                />
              </Paper>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {(commodityhandled === 'Natural Gas' ||
        commodityhandled === 'Crude Oil' ||
        commodityhandled === 'Refined Products') && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={analysisexpanded}
          onChange={fnExpandAnalysis}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography className={classes.heading}>Pipeline Analysis</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <PipelinePBComp commodity={commodityhandled} pipeline={pipelinename} />
          </AccordionDetails>
        </Accordion>
      )}
      <Dialog
        open={pointsdialogopen}
        fullWidth
        onClose={fnClosePointsDialog}
        maxWidth="lg"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Shipper Points
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable
                title="Pipeline Shipper Points"
                data={shipperpoints}
                columns={shipperpointcolumns}
                options={pipelinesectionoptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={fnClosePointsDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dailyflowdialog}
        fullWidth
        onClose={fnCloseDailyFlowDialog}
        maxWidth="lg"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Gas Pipeline Daily Flow Download
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Autocomplete
              disablePortal
              // value={gasflowselectedmonth}
              id="combo-box-demo"
              options={gasflowreportyears}
              onChange={(event, newValue) => {
                if (newValue.length > 0) {
                  setSelectedDailyFlowYear(newValue);
                } else {
                  setSelectedDailyFlowYear({});
                }
              }}
              value={selecteddailyflowyear}
              fullWidth
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select Report Year"
                  aria-label="Select Report Year"
                />
              )}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={fnCloseDailyFlowDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={fnDownloadDailyFlow} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
