import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Fab, Grid, Paper, Tooltip } from '@mui/material';
import { loadModules } from 'esri-loader';
import MapIcon from '@mui/icons-material/Map';
import shortid from 'shortid';
import linq from 'linq';
import { useNavigate } from 'react-router-dom';

// Api
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import AlertNotificationComp from '../../../components/notification/AlertNotificationComp';
import * as facilitycolors from '../../../constants/facilitycolors';
import BaseMapsIconComp from '../../basemaps/BaseMapsIconComp';
import { displaySpinner } from '../../../redux/spinner/Action';

import { ARROWVIS_ZOOM } from '../../../constants/user';
// import BaseMapsComp from "components/search/subcomponents/BaseMapsComp";

// Redux imports
import { ADD_TEMPLATEDETAILS } from '../../../redux/constants/index';
import { GATHERING_SYSTEM } from '../../../constants/facilitytypes';

const useStyles = makeStyles((theme) => ({
  facilitymap: {
    position: 'relative',
  },
}));

export default function GSMapTemplateComp(props) {
  const gsfacilitymapRef = React.createRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);
  const ri = useSelector((state) => state.UserReducer.ri);

  const [gsview, setGSView] = React.useState({});

  const [facilitylayer, setFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const [facilitylabel, setFacilityLabel] = React.useState({
    symbol: {
      type: 'text',
      color: '#000000',
      // haloColor: '#d83435',
      // haloSize: '1px',
      font: {
        size: '12px',
        family: 'Arial',
        // style: 'italic',
        weight: 'normal',
      },
    },
    labelPlacement: 'above-center',
    labelExpressionInfo: {
      expression: '$feature.Facility',
    },
  });

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const fnLoadMapView = () => {
    loadModules(['esri/Map', 'esri/views/MapView'], {
      css: true,
    }).then(([ArcGISMap, MapView]) => {
      const mapview = new MapView({
        container: 'gsfacilitymapid',
        map: new ArcGISMap({
          basemap: 'oceans',
        }),
        center: [-99, 38],
        zoom: 4,
      });

      setGSView(mapview);
    });
  };

  const fnFetchGSMapData = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      gsid: props.gsid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchgsdatabyid', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else if (response.data[0].data.length > 0) {
          fnAddGS(response.data[0].data);

          const focus_point = JSON.parse(response.data[0].data[0].Coords).coordinates;

          // Center the map on the GS location
          gsview.center = focus_point[0][0];
          gsview.zoom = 10;
        } else {
          setStatusBase({
            show: true,
            message: 'Gathering System Coords not found',
            variant: 'info',
          });
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          navigate('/', { replace: true });
        } else {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        }
      },
    );
  };

  const fnFetchGSWellsData = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      gsid: props.gsid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchgswells', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'Active Wells not found for selected Gathering System',
            variant: 'info',
          });
        } else {
          fnAddGSWells(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          navigate('/', { replace: true });
        } else {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        }
      },
    );
  };

  const fnMapSnapshot = (domElement) => {
    gsview.takeScreenshot().then((screenshot) => {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.download = 'map.png';
      link.href = screenshot.dataUrl;
      link.target = '_blank';
      link.click();
    });
  };

  const fnHandleBaseMapChange = (value) => {
    gsview.map.basemap = value;
  };

  const [zoomchanged, setZoomChanged] = React.useState(false);
  const onZoomChange = (newValue, oldValue, propertyName, target) => {
    // View just finished updating. Get the new zoom value from the view
    if (!gsview.updating && gsview.zoom >= ARROWVIS_ZOOM) {
      if (!zoomchanged) {
        setZoomChanged(true);
      }
    }

    if (!gsview.updating && gsview.zoom < ARROWVIS_ZOOM) {
      setZoomChanged(false);
    }
  };

  const [gslayer, setGSLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnAddGS = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      const popupTemplate = new PopupTemplate({
        title: '{GatheringSystem}',
        outFields: ['*'],
        content: GSChange,
        fieldInfos: [
          {
            fieldName: 'GatheringSystem',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SubGatheringSystem',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function GSChange(feature) {
        const div = document.createElement('div');

        div.innerHTML = `Gathering System : <b>${feature.graphic.attributes.GatheringSystem}</b> </br> Sub Gathering System : <b>${feature.graphic.attributes.SubGatheringSystem}</b> </br> CommodityHandled : <b>${feature.graphic.attributes.CommodityHandled}</b>`;
        return div;
      }

      const gsgraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            GatheringSystem: storage.GatheringSystem,
            SubGatheringSystem: storage.SubGatheringSystem,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.GatheringSystemId,
            PlantType: GATHERING_SYSTEM,
            OperatorName: storage.OperatorName,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: facilitycolors.FullGSProps,
      };

      const gslayer = new FeatureLayer({
        // source: features,
        source: gsgraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'GatheringSystem',
            type: 'string',
          },
          {
            name: 'SubGatheringSystem',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'GatheringSystemId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
        ],
        popupTemplate,
        geometryType: 'polyline',
        renderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setGSLayer({ properties: gslayer, binded: false });
    });
  };

  const [wellslayer, setWellsLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnAddGSWells = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer]) => {
      const moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{APINumber}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'APINumber',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'WellNumber',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'WellInfoId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function FacilityChange(feature) {
        const div = document.createElement('div');

        div.innerHTML = `API Number : <b>${feature.graphic.attributes.APINumber}</b> </br> WellNumber : <b>${feature.graphic.attributes.WellNumber}</b> </br> Latitude : <b>${feature.graphic.attributes.Latitude}</b> </br> Longitude : <b>${feature.graphic.attributes.Longitude}</b>`;
        return div;
      }

      const facilitygraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            APINumber: storage.APINumber,
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            WellInfoId: storage.WellInfoId,
            WellNumber: storage.WellNumber,
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      const renderer = {
        type: 'simple', // autocasts as new SimpleRenderer()
        symbol: {
          type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
          size: 7,
          color: facilitycolors.WellColor,
          outline: {
            // autocasts as new SimpleLineSymbol()
            width: 1.5,
            color: facilitycolors.WellColor,
          },
        },
      };

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'APINumber',
            type: 'string',
          },
          {
            name: 'WellNumber',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'WellInfoId',
            type: 'integer',
          },
        ],
        popupTemplate,
        renderer,
        labelingInfo: [facilitylabel],
        minScale: 300000011555811.09,
      });

      setWellsLayer({ properties: facilitylayer, binded: false });
    });
  };

  const [gsfacilitylayer, setGSFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });
  const fnHandlePopupAction = (event) => {
    if (event.action.id === 'more-info') {
      // //console.log(view.popup.selectedFeature.attributes.Facility);
      const planttype = gsview.popup.selectedFeature.attributes.PlantType;

      const templatevalues = {
        index: planttype,
        id: gsview.popup.selectedFeature.attributes.GatheringSystemId,
        name: gsview.popup.selectedFeature.attributes.GatheringSystem,
      };

      dispatch({
        type: ADD_TEMPLATEDETAILS,
        payload: {
          templatevalues,
        },
      });
    }
  };

  const fnAddPipelines = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      pipelineids: `(${props.conpipelines.toString()})`,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchpipelinesdatabyids', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          fnAddPipelineOnMap(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));

        setStatusBase({
          show: true,
          message: 'Error while loading connected pipelines',
          variant: 'error',
        });
      },
    );
  };

  const [pipelinelayer, setPipelineLayer] = React.useState({
    properties: {},
    binded: false,
  });
  const fnAddPipelineOnMap = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      var moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: PipelineChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Section',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Direction',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function PipelineChange(feature) {
        var div = document.createElement('div');

        div.innerHTML =
          'Pipeline Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Section Name : <b>' +
          feature.graphic.attributes.Section +
          '</b> </br> Direction : <b>' +
          feature.graphic.attributes.Direction +
          '</b> </br>Facility Type : <b>' +
          feature.graphic.attributes.PlantType +
          '</b> </br> Operator Name : <b>' +
          feature.graphic.attributes.OperatorName +
          '</b> </br> CommodityHandled : <b>' +
          feature.graphic.attributes.CommodityHandled +
          '</b>';
        return div;
      }

      const pipelinegraphics = data.map(function (storage) {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.PipelineName,
            Section: storage.PipelineSectionName,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.PipelineHeaderId,
            PlantType: 'Pipeline',
            OperatorName: storage.OperatorName,
            Direction: storage.Direction,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: facilitycolors.FullPipelineProps,
      };

      const pipelinelayer = new FeatureLayer({
        // source: features,
        source: pipelinegraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Section',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
          {
            name: 'Direction',
            type: 'string',
          },
        ],

        popupTemplate: popupTemplate,
        geometryType: 'polyline',
        renderer: renderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setPipelineLayer({ properties: pipelinelayer, binded: false });
    });
  };

  const [pipelinefacilitylayer, setPipelineFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnAddConnectedFacilities = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer]) => {
      const moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineHeaderId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function FacilityChange(feature) {
        const div = document.createElement('div');

        div.innerHTML = `Facility Name : <b>${feature.graphic.attributes.Facility}</b> </br> Facility Type : <b>${feature.graphic.attributes.PlantType}</b> </br> Latitude : <b>${feature.graphic.attributes.Latitude}</b> </br> Longitude : <b>${feature.graphic.attributes.Longitude}</b>`;
        return div;
      }

      const facilitygraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.FacilityName,
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            PlantId: storage.FacilityId,
            PlantType: storage.FacilityType,
            PipelineHeaderId: storage.PipelineHeaderId,
            PipelineName: storage.PipelineName,
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        field: 'PlantType',
        defaultSymbol: {
          type: 'simple', // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
            size: 10,
            color: facilitycolors.PipelineFacilitiesProps,
            outline: {
              // autocasts as new SimpleLineSymbol()
              width: 1,
              color: '#000000',
            },
          },
        },
        uniqueValueInfos: facilitycolors.PipelineFacilitiesProps,
      };

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PipelineHeaderId',
            type: 'integer',
          },
          {
            name: 'PipelineName',
            type: 'string',
          },
        ],
        popupTemplate,
        renderer,
        labelingInfo: [facilitylabel],
        minScale: 300000011555811.09,
      });

      setPipelineFacilityLayer({ properties: facilitylayer, binded: false });
    });
  };

  useEffect(() => {
    fnLoadMapView();
  }, []);

  // useEffect(() => {
  //   if (gslayer.properties.visible !== undefined) {
  //     // //console.log(zoomchanged);

  //   }
  // }, [zoomchanged]);

  useEffect(() => {
    if (gslayer.binded === false && gslayer.properties.visible !== undefined) {
      gsview.map.add(gslayer.properties);

      setGSLayer({ ...gslayer, binded: true });
    }
  }, [gslayer]);

  useEffect(() => {
    if (gsfacilitylayer.binded === false && gsfacilitylayer.properties.visible !== undefined) {
      gsview.map.add(gsfacilitylayer.properties);
      // setToggleState({ ...togglestate, gs: true });
      setGSFacilityLayer({ ...gsfacilitylayer, binded: true });
    }
  }, [gsfacilitylayer]);

  useEffect(() => {
    if (gsview.map !== undefined) {
      loadModules(['esri/core/watchUtils'], {
        css: true,
      }).then(([watchUtils]) => {
        watchUtils.watch(gsview, 'updating', onZoomChange);
      });
    }
  }, [gsview]);

  useEffect(() => {
    if (gsview.map !== undefined) {
      fnFetchGSMapData();
      fnFetchGSWellsData();

      gsview.popup.on('trigger-action', fnHandlePopupAction);
    }
  }, [gsview]);

  useEffect(() => {
    if (gsview.map !== undefined && props.focusonmap.Latitude !== 0) {
      gsview.center = [props.focusonmap.Longitude, props.focusonmap.Latitude];
      gsview.zoom = 15;

      gsfacilitymapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Center the map on the refinery location
      // rcview.center = [refinery.Longitude, refinery.Latitude];
      // rcview.zoom = 7;
    }
  }, [props.focusonmap]);

  useEffect(() => {
    if (wellslayer.binded === false && wellslayer.properties.visible !== undefined) {
      gsview.map.add(wellslayer.properties);
      // setToggleState({ ...togglestate, pipeline: true });
      setWellsLayer({ ...wellslayer, binded: true });
    }
  }, [wellslayer]);

  useEffect(() => {
    if (pipelinelayer.binded === false && pipelinelayer.properties.visible !== undefined) {
      gsview.map.add(pipelinelayer.properties);

      setPipelineLayer({ ...pipelinelayer, binded: true });

      gsview.popup.on('trigger-action', fnHandlePopupAction);
    }
  }, [pipelinelayer]);

  useEffect(() => {
    if (props.conpipelines.length > 0 && gsview.map !== undefined) {
      fnAddPipelines(props.conpipelines);
    }
  }, [props.conpipelines, gsview.map]);

  useEffect(() => {
    if (props.confacilities.length > 0 && gsview.map !== undefined) {
      fnAddConnectedFacilities(props.confacilities);
    }
  }, [props.confacilities, gsview.map]);

  useEffect(() => {
    if (
      pipelinefacilitylayer.binded === false &&
      pipelinefacilitylayer.properties.visible !== undefined
    ) {
      gsview.map.add(pipelinefacilitylayer.properties);
      // setToggleState({ ...togglestate, pipeline: true });
      setPipelineFacilityLayer({ ...pipelinefacilitylayer, binded: true });
    }
  }, [pipelinefacilitylayer]);

  return (
    <>
      <Paper className={classes.facilitymap}>
        <div
          className="gsfacilitywebmap"
          id="gsfacilitymapid"
          style={{ height: '500px', width: '100%' }}
          ref={gsfacilitymapRef}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            position: {
              xs: 'absolute',
              lg: 'absolute',
            },

            top: '87%',

            width: '20%',
          }}
        >
          {gsfacilitylayer.binded === true && gsview.map !== undefined && (
            <>
              <Tooltip title="Map Snapshot">
                <Fab
                  size="medium"
                  color="mapsnapshot"
                  aria-label="mapsnapshot"
                  onClick={() => fnMapSnapshot(gsfacilitymapRef.current)}
                >
                  <MapIcon sx={{ fontSize: '1.6rem' }} />
                </Fab>
              </Tooltip>
              <BaseMapsIconComp fnHandleBaseMapChange={fnHandleBaseMapChange} />
            </>
          )}
        </Grid>
      </Paper>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
