/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// Api
import axios from 'axios';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MUIDataTable from 'mui-datatables';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { TableOptions } from '../../../../constants/muitable';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function LiquefactionTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const options = TableOptions();

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);
  const [liquefactionyears, setLiquefactionYears] = React.useState({
    liquefactionunit: [],
    liquefactionexportdetails: [],
  });
  const [liquefactionexport, setLiquefactionExport] = React.useState([]);
  const [lpgexportselectedyear, setLPGExportSelectedYear] = React.useState('');

  const [liquefactionunit, setLiquefactionUnit] = React.useState([]);
  const [lpgunitselectedyear, setLPGUnitSelectedYear] = React.useState('');

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const liquefactionheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text) && record.name !== 'Liquefaction ID') {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  const liquefactionexportcolumns = [
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'DateOfDeparture',
      label: 'Date Of Departure',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta) => {
        //   return <>{dateFormat(value)}</>;
        // },
      },
    },
    {
      name: 'NameOfExporter',
      label: 'Name Of Exporter',
      options: {
        filter: true,
      },
    },
    {
      name: 'Supplier',
      label: 'Supplier',
      options: {
        filter: true,
      },
    },
    {
      name: 'DocketTerm',
      label: 'Docket Term',
      options: {
        filter: true,
      },
    },
    {
      name: 'DestinationCountry',
      label: 'Destination Country',
      options: {
        filter: true,
      },
    },
    {
      name: 'TankerName',
      label: 'Tanker Name',
      options: {
        filter: true,
      },
    },
    {
      name: 'VolumeMCF',
      label: 'Volume MCF',
      options: {
        filter: true,
      },
    },
    {
      name: 'PriceUSDperMMBTU',
      label: 'Price USD per MMBTU',
      options: {
        filter: true,
      },
    },
  ];

  const liquefactionunitcolumns = [
    {
      name: 'CommissioningYear',
      label: 'Commissioning Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'CommissioningMonth',
      label: 'Commissioning Month',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquefactionPlantUnit',
      label: 'Liquefaction Plant Unit',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'BaseloadCapacityBCF',
      label: 'Baseload Capacity BCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'PeakloadCapacityBCF',
      label: 'Peakload Capacity BCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'CapacityMMTPA',
      label: 'Capacity MMTPA',
      options: {
        filter: true,
      },
    },
    {
      name: 'ProjectType',
      label: 'Project Type',
      options: {
        filter: true,
      },
    },
    {
      name: 'DOEExportQtyFTACountriesBCF',
      label: 'DOE Export Qty FTA Countries BCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'DOEExportQtyNonFTACountriesBCF',
      label: 'DOE Export Qty Non FTA Countries BCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'FERCAuthorizedExportQtyBCF',
      label: 'FERC Authorized Export Qty BCFD',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetches the Liquefaction Header data from the API and updates the state with the fetched data.
   *
   * @return {Promise<void>} - A promise that resolves once the data is fetched and the state is updated.
   */
  const fnFetchLiquefactionHeader = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Define the query details
      const queryDetails = {
        index: 'liquefactionheader',
        id: props.LiquefactionId,
      };

      // Define the request options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Make the API request
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Check if an error occurred during execution
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error status
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the no records found status
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Fetch the Gas Daily Flow Years
        fnFetchGasDailyFlowYears(props.LiquefactionId, 'Liquefaction');

        // Define the facility types to exclude
        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        // Get the metadata keys
        const metadata = Object.keys(response.data[0].data[0]);

        // Initialize the data object
        const data = {};

        // Iterate over the metadata keys
        metadata.forEach((element) => {
          if (element !== 'Liquefaction Plant Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
              // data.push({ name: element, value: response.data[0].data[0][element] });
            }
          }
        });

        // Set the facility header state
        setFacilityHeader(data);

        // Create the facility data object
        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Liquefaction Plant Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        // Set the facility data state
        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [liquefactionunitexpanded, setLiquefactionUnitExpanded] = React.useState(false);
  /**
   * Toggles the expanded state of the liquefaction unit section and fetches
   * the liquefaction plant unit data if the section is expanded and there are no
   * liquefaction unit records.
   */
  const fnExpandLiquefactionUnit = () => {
    // Toggle the expanded state
    setLiquefactionUnitExpanded(!liquefactionunitexpanded);

    // If the section is expanded and there are no liquefaction unit records,
    // fetch the liquefaction plant unit data
    if (!liquefactionunitexpanded && liquefactionunit.length === 0) {
      // Call the function to fetch the liquefaction plant unit data
      fnFetchLiquefactionPlantUnit();
    }
  };

  /**
   * Fetches the liquefaction plant unit data from the API and updates the state with the fetched data.
   *
   * @return {Promise<void>} - A promise that resolves once the data is fetched and the state is updated.
   */
  const fnFetchLiquefactionPlantUnit = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Define the query details
      const queryDetails = {
        index: 'liquefactionunit',
        id: props.LiquefactionId,
      };

      // Define the request options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Make the API request
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error status
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the no records found status
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Update the liquefaction unit state with the fetched data
        setLiquefactionUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [liquefactionexportexpanded, setLiquefactionExportExpanded] = React.useState(false);
  /**
   * Toggles the state of liquefaction export expansion.
   * If the export is expanded and there are no export details, it fetches additional data.
   */
  const fnExpandLiquefactionExport = () => {
    // Toggle the expansion state
    setLiquefactionExportExpanded(!liquefactionexportexpanded);

    // If the export is expanded and there are no export details,
    // fetch additional data.
    // Commented out for now as it doesn't seem to be used.
    // if (!liquefactionexportexpanded && liquefactionexport.length === 0) {
    //   fnFetchAdditionalData(
    //     "liquefactionexportdetails",
    //     liquefactionyears.liquefactionexportdetails[liquefactionyears.liquefactionexportdetails.length - 1]
    //   )
    // }
  };

  /**
   * Fetches the years of liquefaction data for a given LiquefactionId.
   * Shows the spinner while fetching the data.
   * If the data fetch is successful, it updates the state of the
   * liquefaction years. If the data fetch is unsuccessful, it
   * sets the error message state.
   *
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchLiquefactionYears = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Set the query details
      const queryDetails = {
        index: 'liquefaction',
        id: props.LiquefactionId,
      };

      // Set the request options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Send the request to the API
      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set an info message
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Update the liquefaction years state
        setLiquefactionYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches liquefaction yearly data based on the given index and value.
   * If the value is not empty, it calls the fnFetchAdditionalData function
   * with the index and the value joined as a string.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {Array} value - The value containing the data to fetch.
   */
  const fnFetchLPGYearlyData = (index, value) => {
    // Check if the value is not empty
    if (value && value.length > 0) {
      // Join the value as a string and call the fnFetchAdditionalData function
      fnFetchAdditionalData(index, value.join());
    }
  };

  /**
   * Fetches additional data based on the given index and year.
   * Displays a spinner while fetching data and handles errors.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {string} year - The year of the data to fetch.
   */
  const fnFetchAdditionalData = async (index, year) => {
    // Display spinner
    dispatch(displaySpinner(true));

    try {
      // Update the selected year state based on the index
      switch (index) {
        case 'liquefactionunit':
          setLPGUnitSelectedYear(year);
          break;
        case 'liquefactionexportdetails':
          setLPGExportSelectedYear(year);
          break;
        default:
          break;
      }

      // Prepare the query details
      const queryDetails = {
        index,
        id: props.LiquefactionId,
        year,
      };

      // Set the authorization header
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Send the request to fetch the data
      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Display an error message if the response status is 'error'
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Display an info message if the response status is 'no records found'
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Update the data state based on the index
        switch (index) {
          case 'liquefactionunit':
            setLiquefactionUnit(response.data[0].data);
            break;
          case 'liquefactionexportdetails':
            setLiquefactionExport(response.data[0].data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Process the data for the Liquefaction Template Component.
   * It fetches the Liquefaction Header data and Liquefaction Years.
   */
  const fnProcessData = () => {
    // Fetch the Liquefaction Header data
    fnFetchLiquefactionHeader();
    // Fetch the Liquefaction Years
    fnFetchLiquefactionYears();
  };

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        //   return (
        //     <Fragment>
        //       {value.data[0] === 0 ? 'False' : 'True'}
        //     </Fragment>
        //   );
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  // Fetch GasDailyFlowYears
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowYears = async (id, type) => {
    dispatch(displaySpinner(true));

    try {
      // Set the asset type state
      setAssetType(type);

      const queryDetails = {
        id,
        type,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state
      } else if (response.data[0].status === 'no records found') {
        // Set the error message state
      } else {
        // Set the fetched data
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Function to expand or collapse the Gas Flow section.
   * Toggles the gasFlowExpanded state.
   *
   * @return {void}
   */
  const fnExpandGasFlow = () => {
    // Toggle the gasFlowExpanded state
    setGasFlowExpanded(!gasflowexpanded);
  };

  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  /**
   * Fetches gas daily flow data from the server based on the provided id, type, and year.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {Array} value - The array of years to fetch data for.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowData = async (value) => {
    // Check if value is defined and has a length greater than 0
    if (value && value.length > 0) {
      // Display spinner while fetching data
      dispatch(displaySpinner(true));

      try {
        // Prepare the query details
        const queryDetails = {
          id: props.LiquefactionId,
          type: assettype,
          year: value.join(),
        };

        // Set the request headers
        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        // Send a POST request to the server
        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        // Hide the spinner after fetching data
        dispatch(displaySpinner(false));

        // Check for errors in the response data
        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          // Set the error message state if an error occurred
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          // Set the error message state if no records were found
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          // Set the fetched data
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && <FacilityHeaderComp facilitydata={facilitydata} type="LNG" />}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={liquefactionunitexpanded}
        onChange={fnExpandLiquefactionUnit}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Liquefaction Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {/* <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >

              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>


                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpintakeselectedyear}
                  id="combo-box-demo"
                  options={liquefactionyears.liquefactionunit}
                  onChange={(event, newValue) => {
                    fnFetchLPGYearlyData(
                      "liquefactionunit", newValue
                    )
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField {...params} size="small" placeholder="Select Year" aria-label="Select Year" />
                  )}
                />

              </Grid>
            </Grid> */}
          {liquefactionunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={liquefactionunit}
                columns={liquefactionunitcolumns}
                options={options}
              />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>

      {liquefactionyears.liquefactionexportdetails.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={liquefactionexportexpanded}
          onChange={fnExpandLiquefactionExport}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>Liquefaction Export Details</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpintakeselectedyear}
                  id="combo-box-demo"
                  options={liquefactionyears.liquefactionexportdetails}
                  onChange={(event, newValue) => {
                    fnFetchLPGYearlyData('liquefactionexportdetails', newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {liquefactionexport.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={liquefactionexport}
                  columns={liquefactionexportcolumns}
                  options={options}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
