import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CCUSTemplateComp from './templates/facilitytypes/CCUSTemplateComp';
import CO2StorageTemplateComp from './templates/facilitytypes/CO2StorageTemplateComp';
import CompressorStationTemplateComp from './templates/facilitytypes/CompressorStationTemplateComp';
import GasPlantTemplateComp from './templates/facilitytypes/GasPlantTemplateComp';
import GasStorageTemplateComp from './templates/facilitytypes/GasStorageTemplateComp';
import HydrogenPlantTemplateComp from './templates/facilitytypes/HydrogenPlantTemplateComp';
import IndustrialPlantTemplateComp from './templates/facilitytypes/IndustrialPlantTemplateComp';
import LiquefactionTemplateComp from './templates/facilitytypes/LiquefactionTemplateComp';
import PipelineAssemblyTemplateComp from './templates/facilitytypes/PipelineAssemblyTemplateComp';
import PowerPlantTemplateComp from './templates/facilitytypes/PowerPlantTemplateComp';
import PumpingStationTemplateComp from './templates/facilitytypes/PumpingStationTemplateComp';
import RefineryTemplateComp from './templates/facilitytypes/RefineryTemplateComp';
import TerminalTemplateComp from './templates/facilitytypes/TerminalTemplateComp';
import EmissionFacilityTemplateComp from './templates/facilitytypes/EmissionFacilityComp';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import { RESET_PIPELINETEMPLATEDETAILS, RESET_TEMPLATEDETAILS } from '../../redux/constants';
import { GAS_STORAGE } from '../../constants/facilitytypes';
import PipelineFacilityComp from './PipelineFacilityComp';
import PipelineTemplateComp from './templates/facilitytypes/PipelineTemplateComp';

const useStyles = makeStyles((theme) => ({
  fullheight: {
    height: '100%',
  },
  componentappBar: {
    position: 'relative',
  },
  componenttitle: {
    marginLeft: '2px',
    color: '#ffffff',
    flex: 1,
  },
  closeicon: {
    color: theme.palette.text.primary,
  },
  main: {
    background: `${theme.palette.background.default} !important`,
    padding: '10px !important',
  },
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function PipelineFacilityInfoComp(props) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const pipelinetemplatevalues = useSelector(
    (state) => state.FacilityReducer.pipelinetemplatevalues,
  );

  // console.log('Template Values ' + JSON.stringify(templatevalues));

  const [loading, setLoading] = React.useState(true);
  

  useEffect(() => {
    // console.log('FacilityComponent Loaded')
    setLoading(false);
  }, []);

  

  return (
    <>
      
      {pipelinetemplatevalues !== undefined && pipelinetemplatevalues !== null && (
        <>
          {pipelinetemplatevalues !== undefined &&
          pipelinetemplatevalues !== null &&
          pipelinetemplatevalues.index === 'Pipeline' ? (
            <PipelineTemplateComp PipelineId={pipelinetemplatevalues.id} />
          ) : null}
        </>
      )}
    </>
  );
}
