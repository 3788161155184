import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';
import { Fab, Grid, Paper, Snackbar, Tooltip } from '@mui/material';
import { loadModules } from 'esri-loader';
import MapIcon from '@mui/icons-material/Map';
import shortid from 'shortid';
import linq from 'linq';
import { useNavigate } from 'react-router-dom';

// import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel.js";

//Api
import axios from 'axios';

import AlertNotificationComp from '../../../components/notification/AlertNotificationComp';
import * as facilitycolors from '../../../constants/facilitycolors.jsx';
import BaseMapsIconComp from '../../basemaps/BaseMapsIconComp';
import { displaySpinner } from '../../../redux/spinner/Action';
import * as assemblies from '../../../constants/pipelineassemblies.jsx';
import { ARROWVIS_ZOOM } from '../../../constants/user';
// import BaseMapsComp from "components/search/subcomponents/BaseMapsComp";

//Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { ADD_TEMPLATEDETAILS } from '../../../redux/constants';
import { use } from 'react';
import { GATHERING_SYSTEM } from '../../../constants/facilitytypes.jsx';

const useStyles = makeStyles((theme) => ({
  facilitymap: {
    position: 'relative',
  },
}));

export default function PipelineMapTemplateComp(props) {
  let pipelinefacilitymapRef = React.createRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);
  const ri = useSelector((state) => state.UserReducer.ri);

  const [pipelineview, setPipelineView] = React.useState({});

  const [facilitylayer, setFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const [facilitylabel, setFacilityLabel] = React.useState({
    symbol: {
      type: 'text',
      color: '#000000',
      // haloColor: '#d83435',
      // haloSize: '1px',
      font: {
        size: '12px',
        family: 'Arial',
        // style: 'italic',
        weight: 'normal',
      },
    },
    labelPlacement: 'above-center',
    labelExpressionInfo: {
      expression: '$feature.Facility',
    },
  });

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const fnLoadMapView = () => {
    loadModules(['esri/Map', 'esri/views/MapView'], {
      css: true,
    }).then(([ArcGISMap, MapView]) => {
      const mapview = new MapView({
        container: 'pipelinefacilitymapid',
        map: new ArcGISMap({
          basemap: 'oceans',
        }),
        center: [-99, 38],
        zoom: 4,
      });

      setPipelineView(mapview);
    });
  };

  const fnFetchPipelineMapData = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      pipelineid: props.pipelineid,
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchpipelinedatabyid', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          fnAddPipeline(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          navigate('/', { replace: true });
        } else {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        }
      },
    );
  };

  const fnFetchGSMapData = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      gsid: props.gsconns.join(','),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchgsdatabyid', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else if (response.data[0].data.length > 0) {
          fnAddGS(response.data[0].data);

          // const focus_point = JSON.parse(response.data[0].data[0].Coords).coordinates;

          // // Center the map on the GS location
          // gsview.center = focus_point[0][0];
          // gsview.zoom = 10;
        } else {
          setStatusBase({
            show: true,
            message: 'Gathering System Coords not found',
            variant: 'info',
          });
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          navigate('/', { replace: true });
        } else {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        }
      },
    );
  };

  const fnFetchGSWellsData = () => {
    dispatch(displaySpinner(true));

    const queryDetails = {
      gsid: props.gsconns.join(','),
    };

    const options = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post('/api/fetchgswells', queryDetails, options).then(
      (response) => {
        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'Active Wells not found for selected Gathering System',
            variant: 'info',
          });
        } else {
          fnAddGSWells(response.data[0].data);
        }
      },

      (error) => {
        dispatch(displaySpinner(false));
        if (error.request.status === 500) {
          navigate('/', { replace: true });
        } else {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        }
      },
    );
  };

  const [gslayer, setGSLayer] = React.useState({
    properties: {},
    binded: false,
  });
  const fnAddGS = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      const popupTemplate = new PopupTemplate({
        title: '{GatheringSystem}',
        outFields: ['*'],
        content: GSChange,
        fieldInfos: [
          {
            fieldName: 'GatheringSystem',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SubGatheringSystem',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function GSChange(feature) {
        const div = document.createElement('div');

        div.innerHTML = `Gathering System : <b>${feature.graphic.attributes.GatheringSystem}</b> </br> Sub Gathering System : <b>${feature.graphic.attributes.SubGatheringSystem}</b> </br> CommodityHandled : <b>${feature.graphic.attributes.CommodityHandled}</b>`;
        return div;
      }

      const gsgraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            GatheringSystem: storage.GatheringSystem,
            SubGatheringSystem: storage.SubGatheringSystem,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.GatheringSystemId,
            PlantType: GATHERING_SYSTEM,
            OperatorName: storage.OperatorName,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
          // style: "short-dot",
          // color: "lightblue",
          // width: "4px",
        },
        uniqueValueInfos: facilitycolors.FullGSProps,
      };

      const gslayer = new FeatureLayer({
        // source: features,
        source: gsgraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'GatheringSystem',
            type: 'string',
          },
          {
            name: 'SubGatheringSystem',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'GatheringSystemId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
        ],
        popupTemplate,
        geometryType: 'polyline',
        renderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setGSLayer({ properties: gslayer, binded: false });
    });
  };

  const [wellslayer, setWellsLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnAddGSWells = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer]) => {
      const moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{APINumber}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'APINumber',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'WellNumber',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'WellInfoId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function FacilityChange(feature) {
        const div = document.createElement('div');

        div.innerHTML = `API Number : <b>${feature.graphic.attributes.APINumber}</b> </br> WellNumber : <b>${feature.graphic.attributes.WellNumber}</b> </br> Latitude : <b>${feature.graphic.attributes.Latitude}</b> </br> Longitude : <b>${feature.graphic.attributes.Longitude}</b>`;
        return div;
      }

      const facilitygraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            APINumber: storage.APINumber,
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            WellInfoId: storage.WellInfoId,
            WellNumber: storage.WellNumber,
            OperatorName:storage.OperatorName,
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      const renderer = {
        type: 'simple', // autocasts as new SimpleRenderer()
        symbol: {
          type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
          size: 7,
          color: facilitycolors.WellColor,
          outline: {
            // autocasts as new SimpleLineSymbol()
            width: 1.5,
            color: facilitycolors.WellColor,
          },
        },
      };

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'APINumber',
            type: 'string',
          },
          {
            name: 'WellNumber',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'WellInfoId',
            type: 'integer',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
        ],
        popupTemplate,
        renderer,
        labelingInfo: [facilitylabel],
        minScale: 300000011555811.09,
      });

      setWellsLayer({ properties: facilitylayer, binded: false });
    });
  };

  const fnMapSnapshot = (domElement) => {
    pipelineview.takeScreenshot().then(function (screenshot) {
      var link = document.createElement('a');
      document.body.appendChild(link);
      link.download = 'map.png';
      link.href = screenshot.dataUrl;
      link.target = '_blank';
      link.click();
    });
  };

  const fnHandleBaseMapChange = (value) => {
    pipelineview.map.basemap = value;
  };

  const [zoomchanged, setZoomChanged] = React.useState(false);
  const onZoomChange = (newValue, oldValue, propertyName, target) => {
    // View just finished updating. Get the new zoom value from the view
    if (!pipelineview.updating && pipelineview.zoom >= ARROWVIS_ZOOM) {
      if (!zoomchanged) {
        setZoomChanged(true);
      }
    }

    if (!pipelineview.updating && pipelineview.zoom < ARROWVIS_ZOOM) {
      setZoomChanged(false);
    }
  };

  const [pipelinelayer, setPipelineLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const fnAddPipeline = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      var popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: PipelineChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Section',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Direction',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function PipelineChange(feature) {
        var div = document.createElement('div');

        div.innerHTML =
          'Pipeline Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Section Name : <b>' +
          feature.graphic.attributes.Section +
          '</b> </br> Direction : <b>' +
          feature.graphic.attributes.Direction +
          '</b> </br>Facility Type : <b>' +
          feature.graphic.attributes.PlantType +
          '</b> </br> Operator Name : <b>' +
          feature.graphic.attributes.OperatorName +
          '</b> </br> CommodityHandled : <b>' +
          feature.graphic.attributes.CommodityHandled +
          '</b>';
        return div;
      }

      var pipelinegraphics = data.map(function (storage) {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.PipelineName,
            Section: storage.PipelineSectionName,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.PipelineHeaderId,
            PlantType: 'Pipeline',
            OperatorName: storage.OperatorName,
            Direction: storage.Direction,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      var renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: facilitycolors.FullPipelineProps,
      };

      var pipelinelayer = new FeatureLayer({
        // source: features,
        source: pipelinegraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Section',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
          {
            name: 'Direction',
            type: 'string',
          },
        ],

        popupTemplate: popupTemplate,
        geometryType: 'polyline',
        renderer: renderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setPipelineLayer({ properties: pipelinelayer, binded: false });
    });
  };

  const [sketchvm, setSketchVM] = React.useState({});

  const [pipelinefacilitylayer, setPipelineFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });
  const fnAddPipelineFacilities = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer]) => {
      var moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      var popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'AssemblyType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineHeaderId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineProduct',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SectionId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SectionName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function FacilityChange(feature) {
        var div = document.createElement('div');

        div.innerHTML =
          'Facility Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Facility Type : <b>' +
          feature.graphic.attributes.AssemblyType +
          '</b> </br> Latitude : <b>' +
          feature.graphic.attributes.Latitude +
          '</b> </br> Longitude : <b>' +
          feature.graphic.attributes.Longitude +
          '</b>';
        return div;
      }

      var latlongdata = linq
        .from(data)
        .where((a) => a.Longitude !== null && a.Latitude !== null)
        .toArray();

      pipelineview.center = [latlongdata[0].Longitude, latlongdata[0].Latitude];
      pipelineview.zoom = 5;

      var facilitygraphics = data.map(function (storage) {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.Facility,
            AssemblyType: storage.PlantType,
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            PlantId: storage.PlantId,
            PlantType: 'Pipeline Assembly',
            PipelineHeaderId: storage.PipelineHeaderId,
            PipelineName: storage.PipelineName,
            PipelineProduct: storage.PipelineProduct === undefined ? '' : storage.PipelineProduct,
            SectionId: storage.SectionId,
            SectionName: storage.SectionName,
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      var renderer = {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        field: 'AssemblyType',
        defaultSymbol: {
          type: 'simple', // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
            size: 7,
            color: facilitycolors.PipelineAssembliesColor,
            outline: {
              // autocasts as new SimpleLineSymbol()
              width: 1,
              color: '#000000',
            },
          },
        },
        uniqueValueInfos: facilitycolors.PipelineFacilitiesProps,
      };

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'AssemblyType',
            type: 'string',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PipelineHeaderId',
            type: 'integer',
          },
          {
            name: 'PipelineName',
            type: 'string',
          },
          {
            name: 'PipelineProduct',
            type: 'string',
          },
          {
            name: 'SectionId',
            type: 'integer',
          },
          {
            name: 'SectionName',
            type: 'string',
          },
        ],
        popupTemplate: popupTemplate,
        renderer: renderer,
        labelingInfo: [facilitylabel],
        minScale: 300000011555811.09,
      });

      setPipelineFacilityLayer({ properties: facilitylayer, binded: false });
    });
  };

  

  const fnHandlePopupAction = (event) => {
    if (event.action.id === 'more-info') {
      // //console.log(view.popup.selectedFeature.attributes.Facility);
      var planttype = pipelineview.popup.selectedFeature.attributes.PlantType;
      if (pipelineview.popup.selectedFeature.attributes.PlantType === 'Pipeline Assembly') {
        if (
          assemblies.palist.indexOf(pipelineview.popup.selectedFeature.attributes.AssemblyType) ===
          -1
        ) {
          var planttype = pipelineview.popup.selectedFeature.attributes.AssemblyType;
        }
      }

      var templatevalues = {
        index: planttype,
        id: pipelineview.popup.selectedFeature.attributes.PlantId,
        name: pipelineview.popup.selectedFeature.attributes.Facility,
      };

      dispatch({
        type: ADD_TEMPLATEDETAILS,
        payload: {
          templatevalues: templatevalues,
        },
      });

      // Get the screen width and height
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      window.open(
        `${window.location.origin}/fi/facilityinfo`, // URL for the Power BI dashboard
        '_blank', // open in a new window
        `width=${screenWidth},height=${screenHeight}`, // set the window size
      );
    }
  };

  useEffect(() => {
    fnLoadMapView();
  }, []);

  useEffect(() => {
    if (pipelinelayer.properties.visible !== undefined) {
      // //console.log(zoomchanged);

      if (zoomchanged) {
        var zoomrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'Direction',
          field2: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.PipeineProps,
        };

        pipelinelayer.properties.renderer = zoomrenderer;
      }

      if (!zoomchanged) {
        var normalrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.FullPipelineProps,
        };

        pipelinelayer.properties.renderer = normalrenderer;
      }
    }
  }, [zoomchanged]);

  useEffect(() => {
    if (pipelinelayer.binded === false && pipelinelayer.properties.visible !== undefined) {
      pipelineview.map.add(pipelinelayer.properties);

      setPipelineLayer({ ...pipelinelayer, binded: true });
    }
  }, [pipelinelayer]);

  useEffect(() => {
    if (
      pipelinefacilitylayer.binded === false &&
      pipelinefacilitylayer.properties.visible !== undefined
    ) {
      pipelineview.map.add(pipelinefacilitylayer.properties);

      // fnSetSketchViewModel();

      // setToggleState({ ...togglestate, pipeline: true });
      setPipelineFacilityLayer({ ...pipelinefacilitylayer, binded: true });
    }
  }, [pipelinefacilitylayer]);

  useEffect(() => {
    if (gslayer.binded === false && gslayer.properties.visible !== undefined) {
      pipelineview.map.add(gslayer.properties);

      setGSLayer({ ...gslayer, binded: true });
    }
  }, [gslayer]);

  useEffect(() => {
    if (wellslayer.binded === false && wellslayer.properties.visible !== undefined) {
      pipelineview.map.add(wellslayer.properties);
      // setToggleState({ ...togglestate, pipeline: true });
      setGSLayer({ ...wellslayer, binded: true });
    }
  }, [wellslayer]);

  useEffect(() => {
    if (pipelineview.map !== undefined) {
      loadModules(['esri/core/watchUtils'], {
        css: true,
      }).then(([watchUtils]) => {
        watchUtils.watch(pipelineview, 'updating', onZoomChange);
      });
    }
  }, [pipelineview]);

  useEffect(() => {
    if (props.pipelinefacilities.length > 0 && pipelineview.map !== undefined) {
      fnAddPipelineFacilities(props.pipelinefacilities);
      fnFetchPipelineMapData();

      pipelineview.popup.on('trigger-action', fnHandlePopupAction);
    }
  }, [props.pipelinefacilities, pipelineview]);

  useEffect(() => {
    if (pipelineview.map !== undefined && props.focusonmap.Latitude !== 0) {
      pipelineview.center = [props.focusonmap.Longitude, props.focusonmap.Latitude];
      pipelineview.zoom = 15;

      pipelinefacilitymapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      // Center the map on the refinery location
      // rcview.center = [refinery.Longitude, refinery.Latitude];
      // rcview.zoom = 7;
    }
  }, [props.focusonmap]);

  useEffect(() => {
    if (props.loadgsconns && props.gsconns.length > 0) {
      fnFetchGSMapData();
      fnFetchGSWellsData();
    }
  }, [props.loadgsconns]);

  // useEffect(() => {
  //   console.log(sketchvm);
  // }, [sketchvm]);

  

  useEffect(() => {
    if (pipelinefacilitylayer.binded && pipelineview.map !== undefined) {
      loadModules(
        [
          'esri/widgets/Sketch/SketchViewModel',
          'esri/widgets/Expand',
          'esri/widgets/support/SnappingControls',
          'esri/layers/GraphicsLayer',
          'esri/geometry/geometryEngineAsync',
          'esri/layers/FeatureLayer',
        ],
        { css: true },
      ).then(
        ([
          SketchViewModel,
          Expand,
          SnappingControls,
          GraphicsLayer,
          geometryEngineAsync,
          featureLayer,
        ]) => {
          // polygonGraphicsLayer will be used by the sketchviewmodel
          // show the polygon being drawn on the view
          const polygonGraphicsLayer = new GraphicsLayer();
          pipelineview.map.add(polygonGraphicsLayer);

          // add the select by rectangle button the view
          pipelineview.ui.add('select-by-rectangle', 'top-left');
          const selectButton = document.getElementById('select-by-rectangle');

          // click event for the select by rectangle button
          selectButton.addEventListener('click', () => {
            // pipelineview.closePopup();
            sketchViewModel.create('rectangle');
          });

          // add the clear selection button the view
          pipelineview.ui.add('clear-selection', 'top-left');
          document.getElementById('clear-selection').addEventListener('click', () => {
            // featureTable.highlightIds.removeAll();
            // featureTable.filterGeometry = null;
            polygonGraphicsLayer.removeAll();
          });

          // create a new sketch view model set its layer
          const sketchViewModel = new SketchViewModel({
            view: pipelineview,
            layer: polygonGraphicsLayer,
          });

          // Store the highlight handle
          let highlight;
          // Once user is done drawing a rectangle on the map
          // use the rectangle to select features on the map and table
          sketchViewModel.on('create', async (event) => {
            if (event.state === 'complete') {
              // Get the geometry of the drawn shape
              const geometry = event.graphic.geometry;

              

              pipelinefacilitylayer.properties
                .queryFeatures({
                  geometry: geometry,
                  spatialRelationship: 'intersects',
                  returnGeometry: true,
                  outFields: ['*'],
                })
                .then((results) => {
                  
                  // Clear existing highlight
                  if (highlight) {
                    highlight.remove();
                  }
                });

              // Get the FeatureLayerView to use highlight
              pipelineview.whenLayerView(pipelinefacilitylayer.properties).then((layerView) => {
                
                // Query for intersecting features
                pipelinefacilitylayer.properties
                  .queryFeatures({
                    geometry: geometry,
                    spatialRelationship: 'intersects',
                    returnGeometry: true,
                    outFields: ['*'],
                  })
                  .then((results) => {
                    
                    // Clear existing highlight
                    if (highlight) {
                      highlight.remove();
                    }

                    // Highlight the intersecting features
                    if (results.features.length > 0) {
                      highlight = layerView.highlight(results.features);

                      
                    }
                  });
              });
            }
          });

          // This function is called when user completes drawing a rectangle
          // on the map. Use the rectangle to select features in the layer and table
          function selectFeatures(geometry) {
            // if (csvLayerView) {
            //   // create a query and set its geometry parameter to the
            //   // rectangle that was drawn on the view
            //   const query = {
            //     geometry: geometry,
            //     outFields: ["*"]
            //   };
            //   // query graphics from the csv layer view. Geometry set for the query
            //   // can be polygon for point features and only intersecting geometries are returned
            //   csvLayerView
            //     .queryFeatures(query)
            //     .then((results) => {
            //       if (results.features.length === 0) {
            //         clearSelection();
            //       } else {
            //         featureTable.highlightIds.removeAll();
            //         let highlightIds = [];
            //         // filter the table based on the selection and only show those rows
            //         featureTable.filterGeometry = geometry;
            //         // Iterate through the features and push each individual result's OBJECTID to the highlightIds array
            //         results.features.forEach((feature) => {
            //           highlightIds.push(feature.attributes.__OBJECTID);
            //         });
            //         // Set the highlightIds array to the highlightIds property of the featureTable
            //         featureTable.highlightIds.addMany(highlightIds);
            //       }
            //     })
            //     .catch(errorCallback);
            // }
          }
        },
      );
    }
  }, [pipelinefacilitylayer, pipelineview]);

  return (
    <>
      <Paper className={classes.facilitymap}>
        {/* <calcite-panel heading="SketchViewModel Styler" id="propPanel">
          <calcite-block id="headingBlock">
            <calcite-action
              icon="cursor"
              title="Select graphic"
              scale="s"
              slot="control"
              id="selectBtn"
            ></calcite-action>
            <calcite-action
              icon="pin"
              title="Draw a point"
              scale="s"
              slot="control"
              id="pointBtn"
            ></calcite-action>
            <calcite-action
              icon="line"
              title="Draw a polyline"
              scale="s"
              slot="control"
              id="polylineBtn"
            ></calcite-action>
            <calcite-action
              icon="polygon"
              title="Draw a polygon"
              scale="s"
              slot="control"
              id="polygonBtn"
            ></calcite-action>
            <calcite-action
              icon="rectangle"
              title="Draw a rectangle"
              scale="s"
              slot="control"
              id="rectangleBtn"
            ></calcite-action>
            <calcite-action
              icon="circle"
              title="Draw a circle"
              scale="s"
              slot="control"
              id="circleBtn"
            ></calcite-action>
            <calcite-action
              icon="trash"
              title="Clear graphics"
              scale="s"
              slot="control"
              id="clearBtn"
            ></calcite-action>
          </calcite-block>
        </calcite-panel> */}
        <div
          id="select-by-rectangle"
          class="esri-widget esri-widget--button esri-widget esri-interactive"
          title="Select features by rectangle"
        >
          <span class="esri-icon-checkbox-unchecked"></span>
        </div>
        <div
          id="clear-selection"
          class="esri-widget esri-widget--button esri-widget esri-interactive"
          title="Clear selection"
        >
          <span class="esri-icon-erase"></span>
        </div>

        <div
          className="pipelinefacilitywebmap"
          id="pipelinefacilitymapid"
          style={{ height: '500px', width: '100%' }}
          ref={pipelinefacilitymapRef}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            position: {
              xs: 'absolute',
              lg: 'absolute',
            },

            top: '87%',

            width: '20%',
          }}
        >
          {pipelinefacilitylayer.binded === true && pipelineview.map !== undefined && (
            <>
              <Tooltip title="Map Snapshot">
                <Fab
                  size="medium"
                  color="mapsnapshot"
                  aria-label="mapsnapshot"
                  onClick={() => fnMapSnapshot(pipelinefacilitymapRef.current)}
                >
                  <MapIcon sx={{ fontSize: '1.6rem' }} />
                </Fab>
              </Tooltip>
              <BaseMapsIconComp fnHandleBaseMapChange={fnHandleBaseMapChange} />
            </>
          )}
        </Grid>
      </Paper>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
