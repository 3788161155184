/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';
// Api
import axios from 'axios';

import MUIDataTable from 'mui-datatables';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';
import { TableOptions } from '../../../../constants/muitable';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function HydrogenPlantTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilityheader, setFacilityHeader] = React.useState([]);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => setStatusBase({ show: false, message: '', variant: 'info' });

  const facilityheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text)) {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  /**
   * Fetches the facility header data from the API and sets the state with the response.
   * The response is expected to be an array of objects with keys as column names and values as the column values.
   * The function also handles errors and sets the error message state if there is an error.
   *
   * @returns {Promise<void>} Nothing is returned from this function.
   */
  const fnFetchFacilityHeader = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Define the query details
      const queryDetails = {
        index: 'hydrogenplantheader',
        id: props.facilityid,
      };

      // Define the options for the axios request
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Make the axios request to fetch the data
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set the error message state
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set the no records found message state
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        // Define the facility types array
        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        // Get the metadata from the response data
        const metadata = Object.keys(response.data[0].data[0]);

        // Initialize the data array
        const data = [];

        // Iterate over the metadata and populate the data array
        metadata.forEach((element) => {
          if (element !== 'Hydrogen Plant Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
              // data.push({ name: element, value: response.data[0].data[0][element] });
            }
          }

          // if (element === 'Longitude') {
          // 	data.push({
          // 		name: 'Google Maps',
          // 		value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          // 	});

          // }
        });

        // Set the facility header state
        setFacilityHeader(data);

        // Prepare the facility data array
        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Hydrogen Plant Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        // Set the facility data state
        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches the facility header data and updates the facility data state.
   *
   * @returns {void}
   */
  const fnProcessData = () => {
    // Fetch the facility header data
    fnFetchFacilityHeader();
  };

  const [hydrogenplantunitexpanded, setHydrogenPlantUnitExpanded] = React.useState(false);
  /**
   * Expands or collapses the Hydrogen Plant Unit section.
   * If the section is expanded and the Hydrogen Plant Unit data is not fetched yet,
   * it calls the fnFetchHydrogenPlantUnit function to fetch the data.
   *
   * @returns {void}
   */
  const fnExpandHydrogenPlantUnit = () => {
    // Toggle the Hydrogen Plant Unit expanded state
    setHydrogenPlantUnitExpanded(!hydrogenplantunitexpanded);

    // If the section is expanded and the Hydrogen Plant Unit data is not fetched yet,
    // fetch the data
    if (!hydrogenplantunitexpanded && hydrogenplantunit.length === 0) {
      fnFetchHydrogenPlantUnit();
    }
  };

  const options = TableOptions();

  const hydrogenplantunitcolumns = [
    {
      name: 'HydrogenUnitName',
      label: 'HydrogenUnitName',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'HydrogenCapacityMMSCFD',
      label: 'HydrogenCapacityMMSCFD',
      options: {
        filter: true,
      },
    },
    {
      name: 'HydrogenTechnologyType',
      label: 'HydrogenTechnologyType',
      options: {
        filter: true,
      },
    },
    {
      name: 'HydrogenProductionType',
      label: 'HydrogenProductionType',
      options: {
        filter: true,
      },
    },
    {
      name: 'InceptionDate',
      label: 'Inception Date',
      options: {
        filter: true,
      },
    },
  ];

  const [hydrogenplantunit, setHydrogenPlantUnit] = React.useState([]);
  /**
   * Fetches the Hydrogen Plant Unit data from the API
   *
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchHydrogenPlantUnit = async () => {
    // Show the spinner
    dispatch(displaySpinner(true));

    try {
      // Set the query details
      const queryDetails = {
        index: 'hydrogenplantunit',
        id: props.facilityid,
      };

      // Set the request options
      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      // Send the request to the API
      const response = await axios.post('/api/headertemplate', queryDetails, options);

      // Hide the spinner
      dispatch(displaySpinner(false));

      // Handle the response
      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        // Set an error message
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // Set an info message
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // Set the hydrogen plant unit state
        setHydrogenPlantUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="HydrogenPlant" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>
      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={hydrogenplantunitexpanded}
        onChange={fnExpandHydrogenPlantUnit}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Hydrogen Plant Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {hydrogenplantunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable
                data={hydrogenplantunit}
                columns={hydrogenplantunitcolumns}
                options={options}
              />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
