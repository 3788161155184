import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { setDefaultOptions } from 'esri-loader';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/Store';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Spinner from './views/spinner/Spinner';
import { initDB } from 'react-indexed-db';

import store from './redux/Store';
import { createDbConfig } from './indexdb/dbconfig';



// import { setAssetPath as setCalciteComponentsAssetPath } from '@esri/calcite-components/dist/components';

const indexdb = createDbConfig();

sessionStorage.setItem('idb_name', indexdb.name);

const currentDatabases = JSON.parse(localStorage.getItem('idb_databases')) || [];

if (!currentDatabases.includes(indexdb.name)) {
  currentDatabases.push(indexdb.name);
  localStorage.setItem('idb_databases', JSON.stringify(currentDatabases));
}

initDB(indexdb);

// configure esri-loader to lazy load the CSS
// the fisrt time any react-arcgis components are rendered
setDefaultOptions({ css: true });
// setCalciteComponentsAssetPath("https://js.arcgis.com/calcite-components/2.13.2/assets");

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
