import React, { useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';


//Redux imports
import { useDispatch, useSelector } from 'react-redux';

import { RESET_PIPELINETEMPLATEDETAILS, RESET_TEMPLATEDETAILS } from '../../redux/constants';

import PipelineTemplateComp from './templates/facilitytypes/PipelineTemplateComp';

const useStyles = makeStyles((theme) => ({
	fullheight: {
		height: '100%',
	},
	componentappBar: {
		position: 'relative',
	},
	componenttitle: {
		marginLeft: '2px',
		color: '#ffffff',
		flex: 1,
	},
	closeicon: {
		color: theme.palette.text.primary,
	},
	main: {
		background: `${theme.palette.background.default} !important`,
		padding: '10px !important'
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function PipelineFacilityComp(props) {
	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector((state) => state.UserReducer.token);

	const templatevalues = useSelector((state) => state.FacilityReducer.pipelinetemplatevalues);

	const [loading, setLoading] = React.useState(true);
	const [componentwindow, setComponentWindow] = React.useState(false);

	const fnOpenComponent = () => {
		// this.setState({ componentwindow: true, selectedgasplantid: record.GasPlantId });
		setComponentWindow(true);
	};

	const fnCloseComponent = () => {
		// dispatch({ type: actionType.RESET_TEMPLATEDETAILS });
		dispatch({ type: RESET_PIPELINETEMPLATEDETAILS });
		// if (pipelinetemplatevalues === null) {
		// 	dispatch({ type: RESET_TEMPLATEDETAILS });
		// } 
		setComponentWindow(false);
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	useEffect(() => {

		

		if (templatevalues !== undefined && templatevalues !== null && templatevalues.index === 'Pipeline') {

			

			fnOpenComponent();
		}else{
			fnCloseComponent();
		}
	}, [templatevalues]);

	return (
		<>
			<Dialog
				fullScreen
				open={componentwindow}
				onClose={fnCloseComponent}
				TransitionComponent={Transition}
			>
				<DialogTitle id="customized-dialog-title" onClose={fnCloseComponent}>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item>

							{templatevalues !== undefined && templatevalues !== null ? (
								<Typography variant="h5" color="textprimary">
									{templatevalues.index} Details
								</Typography>
							) : null}
						</Grid>
						<Grid item>
							<IconButton onClick={fnCloseComponent}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className={classes.main}>

					{templatevalues !== undefined &&
						templatevalues !== null &&
						templatevalues.index === 'Pipeline' ? (
						<PipelineTemplateComp PipelineId={templatevalues.id}
							closepopupwindow={fnCloseComponent}></PipelineTemplateComp>
					) : null}
				</DialogContent>
			</Dialog>
		</>
	);
}
