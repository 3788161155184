/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import shortid from 'shortid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Api
import axios from 'axios';

import MUIDataTable from 'mui-datatables';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';

import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { dateFormat } from '../../../../constants/utils';
import { displaySpinner } from '../../../../redux/spinner/Action';
import FacilityHeaderComp from '../FacilityHeaderComp';

const useStyles = makeStyles((theme) => ({
  columnname: {
    width: '30%',
  },
  yearformcontrol: {
    minWidth: '150px',
    marginRight: 10,
    marginLeft: 10,
    '& .MuiSelect-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  gridcontainer: {
    background: `${theme.palette.background.default} !important`,
  },
}));

export default function RefineryTemplateComp(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.UserReducer.token);

  const [facilitydata, setFacilityData] = React.useState([]);

  const [facilityheader, setFacilityHeader] = React.useState([]);
  const [refineryyears, setRefineryYears] = React.useState({
    refineryproduction: [],
  });
  const [refineryproduction, setRefineryProduction] = React.useState([]);
  const [rfproductionselectedyear, setRFProductionSelectedYear] = React.useState('');

  const [refineryunit, setRefineryUnit] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const options = {
    print: false,
    selectableRows: 'none',
    resizableColumns: true,
  };

  const refineryheadercolumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '35%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      render: (text, record, index) => {
        if (record.name === 'Google Maps') {
          return (
            <>
              <Link href={text} to={text} target="_blank">
                {text}
              </Link>
            </>
          );
        }
        if (Date.parse(text) && record.name !== 'PADD' && record.name !== 'Refinery ID') {
          return <>{dateFormat(text)}</>;
        }
        return <>{text}</>;
      },
    },
  ];

  const refineryproductioncolumns = [
    {
      name: 'Year',
      options: {
        filter: true,
      },
    },
    {
      name: 'Month',
      options: {
        filter: true,
      },
    },

    {
      name: 'RefineryProduct',
      label: 'Refinery Product',
      options: {
        filter: true,
      },
    },
    {
      name: 'StorageAtBeginningOfMonth',
      label: 'Storage At Beginning Of Month',
      options: {
        filter: true,
      },
    },
    {
      name: 'Received',
      label: 'Received',
      options: {
        filter: true,
      },
    },
    {
      name: 'Processed',
      label: 'Processed',
      options: {
        filter: true,
      },
    },
    {
      name: 'Deliveries',
      label: 'Deliveries',
      options: {
        filter: true,
      },
    },
    {
      name: 'StorageAtEndOfMonth',
      label: 'Storage At End Of Month',
      options: {
        filter: true,
      },
    },
    {
      name: 'ProductsManufactured',
      label: 'Products Manufactured',
      options: {
        filter: true,
      },
    },
    {
      name: 'PlantUse',
      label: 'Plant Use',
      options: {
        filter: true,
      },
    },
  ];

  const refineryunitcolumns = [
    {
      name: 'RefineryUnit',
      label: 'Refinery Unit',
      options: {
        filter: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
      },
    },
    {
      name: 'Capacity',
      label: 'Capacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'CommissioningDate',
      label: 'Commissioning Date',
      options: {
        filter: true,
      },
    },
  ];

  /**
   * Fetch Refinery Header
   * This function fetches the Refinery Header from the API and sets the facility header and facility data state.
   * Also calls the fnFetchGasDailyFlowYears function to fetch daily flow data.
   * @async
   */
  const fnFetchRefineryHeader = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'refineryheader',
        id: props.RefineryId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        fnFetchGasDailyFlowYears(props.RefineryId, 'Refinery');

        const facilitytypes = [
          'Status',
          'State Name',
          'Facility Name',
          'Basin Name',
          'City Name',
          'County Name',
          'Latitude',
          'Longitude',
          'Operator Name',
          'Owner Name',
          'Google Maps',
        ];

        const metadata = Object.keys(response.data[0].data[0]);

        const data = {};

        metadata.forEach((element) => {
          if (element !== 'Refinery Name' && element !== 'Country Name') {
            if (
              !facilitytypes.includes(element) &&
              response.data[0].data[0][element] !== null &&
              response.data[0].data[0][element] !== ''
            ) {
              data[element] = response.data[0].data[0][element];
              // data.push({ name: element, value: response.data[0].data[0][element] });
            }
          }

          // if (element === 'Longitude') {
          // 	data.push({
          // 		name: 'Google Maps',
          // 		value: `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          // 	});

          // }
        });

        setFacilityHeader(data);

        const facility = [
          {
            Status: response.data[0].data[0]['Status'],
            'State Name': response.data[0].data[0]['State Name'],
            'Facility Name': response.data[0].data[0]['Refinery Name'],
            'Basin Name': response.data[0].data[0]['Basin Name'],
            'City Name': response.data[0].data[0]['City Name'],
            'County Name': response.data[0].data[0]['County Name'],
            Latitude: response.data[0].data[0]['Latitude'],
            Longitude: response.data[0].data[0]['Longitude'],
            'Operator Name': response.data[0].data[0]['Operator Name'],
            'Owner Name': response.data[0].data[0]['Owner Name'],
            'Google Maps': `https://www.google.com/maps/search/?api=1&query=${response.data[0].data[0]['Latitude']},${response.data[0].data[0]['Longitude']}`,
          },
        ];

        setFacilityData(facility);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches the refinery unit capacity data
   *
   * @async
   * @function fnFetchRefineryUnitCapcity
   * @returns {void}
   */
  const fnFetchRefineryUnitCapcity = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'refineryunitcapacity',
        id: props.RefineryId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/headertemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        // setGasPlantHeader(response.data[0].data[0]);

        setRefineryUnit(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [refineryproductionexpanded, setRefineryProductionExpanded] = React.useState(false);
  /**
   * Expand or collapse the refinery production section.
   * If the section is expanded and there are no refinery production records,
   * fetch additional data for the refinery production.
   */
  const fnExpandRefineryProduction = () => {
    setRefineryProductionExpanded(!refineryproductionexpanded);

    // If the section is expanded and there are no refinery production records,
    // fetch additional data for the refinery production
    // if (!refineryproductionexpanded && refineryproduction.length === 0) {
    // 	fnFetchAdditionalData(
    // 		'refineryproduction',
    // 		refineryyears.refineryproduction[refineryyears.refineryproduction.length - 1]
    // 	);
    // }
  };

  /**
   * Fetches the years of refinery data for a given RefineryId.
   * Shows the spinner while fetching the data.
   * If the data fetch is successful, it updates the state of the
   * refinery years. If the data fetch is unsuccessful, it
   * sets the error message state.
   *
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchRefineryYears = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index: 'refinery',
        id: props.RefineryId,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearstemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        setRefineryYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [refineryunitexpanded, setRefineryUnitExpanded] = React.useState(false);
  /**
   * Toggles the expanded state of the refinery unit section and fetches the
   * refinery unit capacity data if the section is expanded and there are no
   * refinery unit records.
   */
  const fnExpandRefineryUnit = () => {
    setRefineryUnitExpanded(!refineryunitexpanded);

    if (!refineryunitexpanded && refineryunit.length === 0) {
      fnFetchRefineryUnitCapcity();
    }
  };

  /**
   * Fetches refinery yearly data based on the given index and value.
   * If the value is not empty, it calls the fnFetchAdditionalData function
   * with the index and the value joined as a string.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {Array} value - The value containing the data to fetch.
   */
  const fnFetchRefineryYearlyData = (index, value) => {
    if (value && value.length > 0) {
      fnFetchAdditionalData(index, value.join());
    }
  };

  /**
   * Fetches additional data based on the given index and year.
   * Displays a spinner while fetching data and handles errors that occur during the process.
   *
   * @param {string} index - The index of the data to fetch.
   * @param {string} year - The year of the data to fetch.
   * @return {Promise<void>} Promise that resolves when the fetch is complete
   */
  const fnFetchAdditionalData = async (index, year) => {
    // switch (index) {
    // 	case 'refineryproduction':
    // 		setRFProductionSelectedYear(year);
    // 		break;
    // }

    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        index,
        id: props.RefineryId,
        year,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/yearlydatatemplate', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        // setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        switch (index) {
          case 'refineryproduction':
            setRefineryProduction(response.data[0].data);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Executes the process data function which fetches the refinery header
   * and years data.
   *
   * @returns {void}
   */
  const fnProcessData = () => {
    // //console.log(props.GasPlantId);

    fnFetchRefineryHeader();
    fnFetchRefineryYears();
  };

  const gasdailyflowcolumns = [
    {
      name: 'PipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PipelineLocationCode',
      options: {
        filter: true,
      },
    },
    {
      name: 'LocationZone',
      options: {
        filter: true,
      },
    },
    {
      name: 'TotalScheduledQuantity',
      options: {
        filter: true,
      },
    },
    {
      name: 'OperationallyAvailableCapacity',
      options: {
        filter: true,
      },
    },
    {
      name: 'IT',
      options: {
        filter: true,
        // customBodyRender: (value, tableMeta, updateValue) => {

        // 	return (
        // 		<Fragment>
        // 			{value.data[0] === 0 ? 'False' : 'True'}
        // 		</Fragment>
        // 	);
        // },
      },
    },
    {
      name: 'FlowIndicator',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
  ];

  // Fetch GasDailyFlowYears
  const [gasdailyflowyears, setGasDailyFlowYears] = React.useState([]);
  const [assettype, setAssetType] = React.useState('');
  /**
   * Fetches gas daily flow years data from the server based on the provided id and type.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {string} id - The id of the facility.
   * @param {string} type - The type of the facility.
   * @return {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowYears = async (id, type) => {
    dispatch(displaySpinner(true));

    try {
      setAssetType(type);

      const queryDetails = {
        id,
        type,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/gasdailyflowyearlydatatemplate',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        //	setStatusBase({ show: true, message: 'An error occurred during execution.', variant: 'error' });
      } else if (response.data[0].status === 'no records found') {
        //	setStatusBase({ show: true, message: 'No records found', variant: 'info' });
      } else {
        setGasDailyFlowYears(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [gasflowexpanded, setGasFlowExpanded] = React.useState(false);
  /**
   * Function to expand or collapse the gas flow section.
   *
   * @return {void}
   */
  const fnExpandGasFlow = () => {
    setGasFlowExpanded(!gasflowexpanded);
  };

  const [gasdailyflowdata, setGasDailyFlowData] = React.useState([]);
  /**
   * Fetches gas daily flow data from the server based on the provided id, type, and year.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @param {Array} value - The array of years to fetch data for.
   * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasDailyFlowData = async (value) => {
    if (value && value.length > 0) {
      dispatch(displaySpinner(true));

      try {
        const queryDetails = {
          id: props.RefineryId,
          type: assettype,
          year: value.join(),
        };

        const options = {
          headers: {
            authorization: token ? `Bearer ${token}` : '',
          },
        };

        const response = await axios.post('/api/gasdailyflowyearlydata', queryDetails, options);

        dispatch(displaySpinner(false));

        if (response.data[0] !== undefined && response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'An error occurred during execution.',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'No records found',
            variant: 'info',
          });
        } else {
          setGasDailyFlowData(response.data[0].data);
        }
      } catch (error) {
        // Hide the spinner and handle errors
        dispatch(displaySpinner(false));

        let errorMessage = 'Error occurred while fetching data';

        if (error.response && error.response.status === 500) {
          errorMessage = 'Server error occurred. Please try again later.';
          navigate('/', { replace: true });
        }

        // Set the error message state
        setStatusBase({
          show: true,
          message: errorMessage,
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {
    fnProcessData();
  }, []);

  // useEffect(() => {
  // 	if (refineryyears.refineryproduction.length > 0) {

  // 	}
  // }, [refineryyears]);

  return (
    <>
      <Box>
        {facilitydata.length > 0 && (
          <FacilityHeaderComp facilitydata={facilitydata} type="Refinery" />
        )}
      </Box>
      <Paper>
        <Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
          {Object.keys(facilityheader).length > 0 && (
            <Table
              aria-label="header data"
              sx={{
                whiteSpace: 'nowrap',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h5">Additional Fields</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(facilityheader).map(
                  (header) =>
                    facilityheader[header] !== '' && (
                      <TableRow key={shortid.generate()}>
                        <TableCell sx={{ p: '10px' }}>
                          <Box
                            sx={{
                              mr: 3,
                            }}
                          >
                            <Typography color="textSecondary" variant="h6" fontWeight="400">
                              {header}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ p: '10.6px' }}>
                          <Box>
                            <Typography
                              variant="h6"
                              fontWeight="600"
                              style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {facilityheader[header]}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ),
                )}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Paper>

      <Accordion
        sx={{ m: 0, mt: '10px' }}
        expanded={refineryunitexpanded}
        onChange={fnExpandRefineryUnit}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Refinery Unit</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          {refineryunit.length > 0 && (
            <ThemeProvider theme={getMuiTableTheme}>
              <MUIDataTable data={refineryunit} columns={refineryunitcolumns} options={options} />
            </ThemeProvider>
          )}
        </AccordionDetails>
      </Accordion>
      {refineryyears.refineryproduction.length > 0 && (
        <Accordion
          sx={{ m: 0, mt: '10px' }}
          expanded={refineryproductionexpanded}
          onChange={fnExpandRefineryProduction}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography className={classes.heading}>
              Refinery Production - All values are in Barrels
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpintakeselectedyear}
                  id="combo-box-demo"
                  options={refineryyears.refineryproduction}
                  onChange={(event, newValue) => {
                    fnFetchRefineryYearlyData('refineryproduction', newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>
            {refineryproduction.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={refineryproduction}
                  columns={refineryproductioncolumns}
                  options={options}
                />
              </ThemeProvider>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {gasdailyflowyears.length > 0 && (
        <Accordion sx={{ m: 0, mt: '10px' }} expanded={gasflowexpanded} onChange={fnExpandGasFlow}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Gas Daily Flow Data</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid xs={5} sx={{ mr: '21px', mb: '5px' }} item>
                <Autocomplete
                  multiple
                  disablePortal
                  // value={gpprodselectedyear}
                  id="combo-box-demo"
                  options={gasdailyflowyears}
                  onChange={(event, newValue) => {
                    fnFetchGasDailyFlowData(newValue);
                  }}
                  fullWidth
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select Year"
                      aria-label="Select Year"
                    />
                  )}
                />
              </Grid>
            </Grid>

            {gasdailyflowdata.length > 0 && (
              <ThemeProvider theme={getMuiTableTheme}>
                <MUIDataTable
                  data={gasdailyflowdata}
                  columns={gasdailyflowcolumns}
                  options={options}
                />
              </ThemeProvider>
              // <>{gasdailyflowdata.length}</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {/* <Grid container>
				<Grid item xs={12}>
					<Paper>
						<Grid
							container
							direction="column"
							justify="flex-start"
							alignItems="flex-start"
							className={classes.gridcontainer}
						>
							
							
							<Grid item xs={12} style={{ width: '98.7%', margin: '10px' }} spacing={2}>
								{refineryyears.refineryproduction.length > 0 ? (
									<>
										<Grid container direction="row" justify="space-between" alignItems="center">
											<Grid item>
												<Typography variant="h6" gutterBottom>
													
												</Typography>
											</Grid>
											<Grid item>
												<Grid
													container
													direction="row"
													justify="flex-start"
													alignItems="center"
												>
													<Grid item>
														<Typography color="textPrimary">Select Year :</Typography>
													</Grid>
													<Grid item>
														<FormControl
															variant="outlined"
															className={classes.yearformcontrol}
														>
															<Select
																value={}
																// className={classes.searchtype}
																onChange=
															>
																{.map((value) => (
																	<MenuItem key={value} value={value}>
																		{value}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid>
											
										</Grid>
									</>
								) : null}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid> */}
      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
