/* eslint-disable react/prop-types */
import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Slide,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

// Api
import axios from 'axios';

import linq from 'linq';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { displaySpinner } from '../../../../redux/spinner/Action';
import PipelineTariffMapComp from './PipelineTariffMapComp';

import withRouter from '../../../../components/router/WithRouter';
import { TableOptions } from '../../../../constants/muitable';
import { DEMO_USER } from '../../../../constants/user';
import fileDownload from 'js-file-download';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GasTariffTableComp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);
  const role = useSelector((state) => state.UserReducer.userrole);

  const mapref = React.useRef(null);

  const [tariffdata, setTariffData] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const options = TableOptions();

  const [selectednode, setSelectedNode] = React.useState(0);
  const [points, setPoints] = React.useState({
    origin: '',
    destination: '',
  });

  const tariffoptions = {
    print: false,
    resizableColumns: true,
    elevation: 0,
    download: role !== DEMO_USER,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    // onRowClick: (rowData, rowMeta) => fnTariffRecordClick(rowData, rowMeta),
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    onRowSelectionChange: (rowData) => {
      // console.log(rowData);
      // console.log(tariffdata[rowData[0].index].LiquidTariffNodeId);
      // this.setState({ rowsSelected: rowsSelected });
      if (
        points.origin !== tariffdata[rowData[0].dataIndex].PointOfOrigin ||
        points.destination !== tariffdata[rowData[0].dataIndex].PointOfDestination
      ) {
        setPoints({
          origin: tariffdata[rowData[0].dataIndex].PointOfOrigin,
          destination: tariffdata[rowData[0].dataIndex].PointOfDestination,
        });

        setSelectedNode(tariffdata[rowData[0].dataIndex].GasTariffNodeId);

        mapref.current.scrollIntoView();
      }
    },
  };

  const [componentwindow, setComponentWindow] = React.useState(false);

  const fnOpenComponent = () => {
    // this.setState({ componentwindow: true, selectedgasplantid: record.GasPlantId });
    setComponentWindow(true);
  };

  const fnCloseComponent = () => {
    setComponentWindow(false);
  };

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const tariffcolumns = [
    // {
    //   name: "PipelineName",
    //   options: {
    //     filter: true,
    //   },
    // },
    // {
    //   name: "OperatorName",
    //   options: {
    //     filter: true,
    //   },
    // },
    // {
    //   name: "OwnerName",
    //   options: {
    //     filter: true,
    //   },
    // },
    {
      name: 'GasTariffMethodType',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfOrigin',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfOriginName',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'PointOfDestination',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfDestinationName',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'RateSchedule',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasTariffTypeofService',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasTariffRateCategory',
      options: {
        filter: true,
      },
    },
    {
      name: 'Season',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasTariffFlowDirection',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
    {
      name: 'EndDate',
      options: {
        filter: true,
      },
    },
    {
      name: 'MonthlyReservChargeUSDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'DailyReservChargeUSDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'MaxCommodityChargeUSDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'MinCommodityChargeUSDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'ACA_USDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'EPCCommodityUSDPerDth',
      options: {
        filter: true,
      },
    },
    {
      name: 'FuelChargePercentage',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasTariffNodeId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
  ];

  const origindestioptions = {
    print: false,
    resizableColumns: true,
    elevation: 0,
    download: false,

    // onRowClick: (rowData, rowMeta) => fnTariffRecordClick(rowData, rowMeta),
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    onRowSelectionChange: (rowData) => {
      // console.log(rowData);
      // console.log(tariffdata[rowData[0].index].LiquidTariffNodeId);
      // this.setState({ rowsSelected: rowsSelected });
      // if (
      //   points.origin !== tariffdata[rowData[0].dataIndex].PointOfOrigin ||
      //   points.destination !== tariffdata[rowData[0].dataIndex].PointOfDestination
      // ) {

      // console.log(origindestidata);

      // 
      if(
        origindestidata[rowData[0].dataIndex].GasTariffNodeId !== selectednode
      ){
        setPoints({
          origin: origindestidata[rowData[0].dataIndex].PointOfOrigin,
          destination: origindestidata[rowData[0].dataIndex].PointOfDestination,
        });

        setSelectedNode(origindestidata[rowData[0].dataIndex].GasTariffNodeId);
      }
    },
    // onCellClick: (colData, colIndex, rowIndex, dataIndex) => {},
    tableBodyMaxHeight: '500px',
  };

  const origindesticolumns = [
    {
      name: 'PointOfOrigin',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfOriginName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfDestination',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfDestinationName',
      options: {
        filter: true,
      },
    },
    {
      name: 'GasTariffNodeId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'Full Tariff',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnDownloadSelectedTariff(value, tableMeta, updateValue)}
              >
                <DownloadIcon />
              </Link>
            </>
          );
        },
      },
    },
  ];

  const [jointpipelines, setJointPipelines] = React.useState([]);
  const [origindestidata, setOriginDestiData] = React.useState([]);
  /**
   * Fetches gas tariff data from the server based on the provided title.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchGasTariffData = async () => {
    dispatch(displaySpinner(true));

    try {
      const data = {
        pipelinename: props.title,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchgastarifftabledata', data, options);

      dispatch(displaySpinner(false));

      if (response.data[0].status.code === undefined) {
        if (response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'Error while fetching records',
            variant: 'error',
          });
        } else if (response.data[0].status === 'no records found') {
          setStatusBase({
            show: true,
            message: 'Gas Tariff data not found',
            variant: 'info',
          });
        } else if (response.data[0].data.length > 0) {
          setTariffData(response.data[0].data);

          const groupeddata = [];
          const od_combis = [];

          response.data[0].data.forEach((tarf) => {
            const combi = `${tarf.PointOfOrigin} - ${tarf.PointOfDestination} - ${tarf.GasTariffNodeId}`;

            if (!od_combis.includes(combi) && tarf.EndDate === null) {
              od_combis.push(combi);
              const tdata = {
                PointOfOriginName: tarf.PointOfOriginName,
                PointOfDestinationName: tarf.PointOfDestinationName,
                PointOfOrigin: tarf.PointOfOrigin,
                PointOfDestination: tarf.PointOfDestination,
                GasTariffNodeId: tarf.GasTariffNodeId,
              };

              groupeddata.push(tdata);
            }
          });

          setOriginDestiData(groupeddata);

          const multiplepipelines = linq
            .from(response.data[0].data)
            .where((a) => a.PipelineHeaderId !== props.pipelineid)
            .toArray();

          if (multiplepipelines.length > 0) {
            const jpipes = [];

            multiplepipelines.forEach((e) => {
              if (e.PipelineHeaderId !== props.pipelineid && !jpipes.includes(e.PipelineHeaderId)) {
                jpipes.push(e.OriginPipelineId);
              }
            });

            if (jpipes.length > 0) {
              setJointPipelines(jpipes);
            }
          }
        }
      } else {
        setStatusBase({
          show: true,
          message: 'Error while fetching tariff data. Please try again.',
          variant: 'error',
        });
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };


  // Downloads the selected tariff data.
  const fnDownloadSelectedTariff = (value, tableMeta, updateValue) => {
    const nodeid = tableMeta.tableData[tableMeta.rowIndex].GasTariffNodeId;

    
    // console.log(nodeid);

    const tariffdatabynodeid = linq
      .from(tariffdata)
      .where((a) => a.GasTariffNodeId === nodeid)
      .toArray();

      

    const fields = Object.keys(tariffdatabynodeid[0]);

    // const pos = fields.findIndex((str) => str.includes('Id'));

    // fields.splice(pos, 1);

    const replacer = (key, rval) => {
      // Replace null values with an empty string
      return rval === null ? '' : rval;
    };

    let csv = tariffdatabynodeid.map((row) => {
      return fields
        // .filter((fieldName) => fieldName !== 'GasTariffNodeId' && fieldName !== 'Coords')
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',');
    });

    csv.unshift(fields.join(',')); // add header column
    csv = csv.join('\r\n');

    

    fileDownload(csv, 'tariff.csv');
  };

  useEffect(() => {
    if (props.title !== '') {
      fnFetchGasTariffData();
    }
  }, []);

  return (
    <>
      {tariffdata.length > 0 && (
        <>
          <Button sx={{ ml: '10px' }} onClick={fnOpenComponent} size="small">
            Map Tariff Explorer: Click and Uncover Section Details
          </Button>
          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={tariffdata} columns={tariffcolumns} options={options} />
          </ThemeProvider>
        </>
      )}

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
      <Dialog
        fullScreen
        // maxWidth="lg"
        open={componentwindow}
        onClose={fnCloseComponent}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={fnCloseComponent}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" color="textprimary">
                Gas Tariff Explorer
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={fnCloseComponent}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'unset !important' }}>
          <>
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item xs={6}>
                  <Paper>
                    <MUIDataTable
                      data={origindestidata}
                      columns={origindesticolumns}
                      options={origindestioptions}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <div ref={mapref} style={{ width: '98.7%', margin: '10px', marginTop: '0' }}>
                    <PipelineTariffMapComp
                      facilitydata={props.facilitydata}
                      pipelinetype="gas"
                      nodeid={selectednode}
                      pipelineid={props.pipelineid}
                      jointpipelines={jointpipelines}
                      pipelinefacilities={props.pipelinefacilities}
                      maxheight="600px"
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <MUIDataTable data={tariffdata} columns={tariffcolumns} options={tariffoptions} />
               */}
            </>
            {/* <MUIDataTable data={tariffdata} columns={tariffcolumns} options={tariffoptions} />
            <div ref={mapref} style={{ width: '98.7%', margin: '10px', marginTop: '0' }}>
              <PipelineTariffMapComp
                facilitydata={props.facilitydata}
                pipelinetype="gas"
                nodeid={selectednode}
                pipelineid={props.pipelineid}
                jointpipelines={jointpipelines}
                pipelinefacilities={props.pipelinefacilities}
                maxheight=""
              />
            </div> */}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRouter(GasTariffTableComp);
