/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import MapIcon from '@mui/icons-material/Map';
import { Fab, Grid, Paper, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { loadModules } from 'esri-loader';
import linq from 'linq';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';
// Api
import axios from 'axios';

import * as facilitycolors from '../../../../constants/facilitycolors.jsx';
import * as assemblies from '../../../../constants/pipelineassemblies.jsx';
import { displaySpinner } from '../../../../redux/spinner/Action';
import BaseMapsIconComp from '../../../basemaps/BaseMapsIconComp';
// import BaseMapsComp from "components/search/subcomponents/BaseMapsComp";

// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import { ADD_TEMPLATEDETAILS } from '../../../../redux/constants';
import { ARROWVIS_ZOOM } from '../../../../constants/user';

const useStyles = makeStyles((theme) => ({
  facilitymap: {
    position: 'relative',
  },
}));

export default function PipelineTariffMapComp(props) {
  const tariffmapRef = React.createRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);
  const ri = useSelector((state) => state.UserReducer.ri);

  const [pipelineview, setPipelineView] = React.useState({});

  const [facilitylayer, setFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const [facilitylabel, setFacilityLabel] = React.useState({
    symbol: {
      type: 'text',
      color: '#000000',
      // haloColor: '#d83435',
      // haloSize: '1px',
      font: {
        size: '12px',
        family: 'Arial',
        // style: 'italic',
        weight: 'normal',
      },
    },
    labelPlacement: 'above-center',
    labelExpressionInfo: {
      expression: '$feature.Facility',
    },
  });

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  /**
   * Load the initial map view on page load and assign it to the pipelineview variable.
   * It uses the ArcGIS API to load the map view and set the basemap to 'oceans'.
   * The map view is centered at [-99, 38] and has a zoom level of 4.
   *
   * @return {void}
   */
  const fnLoadMapView = () => {
    loadModules(['esri/Map', 'esri/views/MapView'], {
      css: true,
    }).then(([ArcGISMap, MapView]) => {
      const mapview = new MapView({
        container: 'tariffmapid',
        map: new ArcGISMap({
          basemap: 'oceans',
        }),
        center: [-99, 38],
        zoom: 4,
      });

      setPipelineView(mapview);
    });
  };

  /**
   * Fetches pipeline map data from the server and adds the pipeline map layer to the map.
   * It is called when the component mounts and when the pipeline ID changes.
   *
   * @return {void}
   */
  const fnFetchPipelineMapData = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        pipelineid: props.pipelineid,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchpipelinedatabyid', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        fnAddPipeline(response.data[0].data, 'single');

        if (props.jointpipelines.length > 0) {
          fnFetchJointPipelinesMapData();
        }
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Fetches joint pipeline map data from the server and adds the pipelines to the map.
   * It is called when the component mounts and when the joint pipeline IDs change.
   *
   * @return {Promise<void>} A promise that resolves when the pipelines are fetched and added to the map.
   */
  const fnFetchJointPipelinesMapData = async () => {
    dispatch(displaySpinner(true));

    try {
      const queryDetails = {
        pipelineids: props.jointpipelines.toString(),
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post(
        '/api/fetchmultiplepipelinesdatabyid',
        queryDetails,
        options,
      );

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        fnAddPipeline(response.data[0].data, 'multiple');
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  /**
   * Take a snapshot of the map and download it as a PNG image.
   *
   * @param {HTMLElement} domElement - The DOM element that triggered the download.
   */
  const fnMapSnapshot = (domElement) => {
    pipelineview
      .takeScreenshot()
      .then((screenshot) => {
        // Create a link element
        const link = document.createElement('a');
        // Set the download attribute with filename
        link.download = 'map.png';
        // Set the href to the screenshot data URL
        link.href = screenshot.dataUrl;
        // Append the link to the body
        document.body.appendChild(link);
        // Programmatically click the link to trigger the download
        link.click();
        // Remove the link from the DOM
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Failed to take screenshot:', error);
      });
  };

  const fnHandleBaseMapChange = (value) => {
    pipelineview.map.basemap = value;
  };

  const [zoomchanged, setZoomChanged] = React.useState(false);
  const onZoomChange = (newValue, oldValue, propertyName, target) => {
    // View just finished updating. Get the new zoom value from the view
    if (!pipelineview.updating && pipelineview.zoom >= ARROWVIS_ZOOM) {
      if (!zoomchanged) {
        setZoomChanged(true);
      }
    }

    if (!pipelineview.updating && pipelineview.zoom < ARROWVIS_ZOOM) {
      setZoomChanged(false);
    }
  };

  const [pipelinelayer, setPipelineLayer] = React.useState({
    properties: {},
    binded: false,
  });

  const [jointpipelinelayer, setJointPipelineLayer] = React.useState({
    properties: {},
    binded: false,
  });

  /**
   * Adds a pipeline feature layer to the map.
   *
   * @param {Array} data - The data for the pipeline feature layer.
   * @param {string} type - The type of the pipeline feature layer.
   */
  const fnAddPipeline = (data, type) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: PipelineChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Section',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'OperatorName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Direction',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'CommodityHandled',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function PipelineChange(feature) {
        const div = document.createElement('div');

        div.innerHTML =
          'Pipeline Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Section Name : <b>' +
          feature.graphic.attributes.Section +
          '</b> </br> Direction : <b>' +
          feature.graphic.attributes.Direction +
          '</b> </br>Facility Type : <b>' +
          feature.graphic.attributes.PlantType +
          '</b> </br> Operator Name : <b>' +
          feature.graphic.attributes.OperatorName +
          '</b> </br> CommodityHandled : <b>' +
          feature.graphic.attributes.CommodityHandled +
          '</b>';
        return div;
      }

      const pipelinegraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.PipelineName,
            Section: storage.PipelineSectionName,
            CommodityHandled: storage.CommodityHandled,
            PlantId: storage.PipelineHeaderId,
            PlantType: 'Pipeline',
            OperatorName: storage.OperatorName,
            Direction: storage.Direction,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as UniqueValueRenderer
        field: 'CommodityHandled',
        fieldDelimiter: ', ',
        defaultSymbol: {
          type: 'simple-line', // default SimpleLineSymbol
        },
        uniqueValueInfos: facilitycolors.FullPipelineProps,
      };

      const jpipecolor = [127, 130, 130, 255];
      const jointrenderer = {
        type: 'simple', // autocasts as new SimpleRenderer()
        symbol: {
          type: 'simple-line', // autocasts as new SimpleMarkerSymbol()
          color: jpipecolor,
          width: '1.5px',
        },
      };

      const pipelinelayer = new FeatureLayer({
        // source: features,
        source: pipelinegraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Section',
            type: 'string',
          },
          {
            name: 'CommodityHandled',
            type: 'string',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'OperatorName',
            type: 'string',
          },
          {
            name: 'Direction',
            type: 'string',
          },
        ],

        popupTemplate,
        geometryType: 'polyline',
        renderer: type === 'single' ? renderer : jointrenderer,
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      if (type === 'single') {
        setPipelineLayer({ properties: pipelinelayer, binded: false });
      } else {
        setJointPipelineLayer({ properties: pipelinelayer, binded: false });
      }
    });
  };

  const [pipelinefacilitylayer, setPipelineFacilityLayer] = React.useState({
    properties: {},
    binded: false,
  });
  /**
   * Adds pipeline facilities to the map
   * @param {Array<Object>} data - The array of pipeline facilities objects
   */
  const fnAddPipelineFacilities = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer]) => {
      const moreinfoAction = {
        title: 'More Info',
        id: 'more-info',
      };

      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: FacilityChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'AssemblyType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PlantType',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Longitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'Latitude',
            format: {
              digitSeparator: true,
              places: 2,
            },
          },
          {
            fieldName: 'PlantId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineHeaderId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'PipelineProduct',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SectionId',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'SectionName',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
        actions: [moreinfoAction],
      });

      function FacilityChange(feature) {
        const div = document.createElement('div');

        div.innerHTML =
          'Facility Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Facility Type : <b>' +
          feature.graphic.attributes.AssemblyType +
          '</b> </br> Latitude : <b>' +
          feature.graphic.attributes.Latitude +
          '</b> </br> Longitude : <b>' +
          feature.graphic.attributes.Longitude +
          '</b> </br> Pipeline Product : <b>' +
          feature.graphic.attributes.PipelineProduct +
          '</b>';
        return div;
      }

      const latlongdata = linq
        .from(data)
        .where((a) => a.Longitude !== null && a.Latitude !== null)
        .toArray();

      pipelineview.center = [latlongdata[0].Longitude, latlongdata[0].Latitude];
      pipelineview.zoom = 5;

      const facilitygraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.Facility,
            AssemblyType: storage.PlantType,
            Latitude: storage.Latitude,
            Longitude: storage.Longitude,
            PlantId: storage.PlantId,
            PlantType: 'Pipeline Assembly',
            PipelineHeaderId: storage.PipelineHeaderId,
            PipelineName: storage.PipelineName,
            PipelineProduct: storage.PipelineProduct === undefined ? '' : storage.PipelineProduct,
            SectionId: storage.SectionId,
            SectionName: storage.SectionName,
          },
          geometry: {
            latitude: storage.Latitude,
            longitude: storage.Longitude,
            type: 'point',
          },
        });
      });

      const renderer = {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        field: 'AssemblyType',
        defaultSymbol: {
          type: 'simple', // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
            size: 7,
            color: facilitycolors.PipelineAssembliesColor,
            outline: {
              // autocasts as new SimpleLineSymbol()
              width: 1,
              color: '#000000',
            },
          },
        },
        uniqueValueInfos: facilitycolors.PipelineFacilitiesProps,
      };

      const facilitylayer = new FeatureLayer({
        source: facilitygraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'AssemblyType',
            type: 'string',
          },
          {
            name: 'PlantType',
            type: 'string',
          },
          {
            name: 'Latitude',
            type: 'double',
          },
          {
            name: 'Longitude',
            type: 'double',
          },
          {
            name: 'PlantId',
            type: 'integer',
          },
          {
            name: 'PipelineHeaderId',
            type: 'integer',
          },
          {
            name: 'PipelineName',
            type: 'string',
          },
          {
            name: 'PipelineProduct',
            type: 'string',
          },
          {
            name: 'SectionId',
            type: 'integer',
          },
          {
            name: 'SectionName',
            type: 'string',
          },
        ],
        popupTemplate,
        renderer,
        labelingInfo: [facilitylabel],
        minScale: 11555811.09,
      });

      setPipelineFacilityLayer({ properties: facilitylayer, binded: false });
    });
  };

  /**
   * Event handler for the 'trigger-action' event of the popup.
   * This function is called when the user clicks on the 'more-info' action
   * in the popup.
   *
   * @param {Object} event - The event object.
   */
  const fnHandlePopupAction = (event) => {
    if (event.action.id === 'more-info') {
      // //console.log(view.popup.selectedFeature.attributes.Facility);
      let planttype = pipelineview.popup.selectedFeature.attributes.PlantType;
      if (pipelineview.popup.selectedFeature.attributes.PlantType === 'Pipeline Assembly') {
        if (
          assemblies.palist.indexOf(pipelineview.popup.selectedFeature.attributes.AssemblyType) ===
          -1
        ) {
          planttype = pipelineview.popup.selectedFeature.attributes.AssemblyType;
        }
      }

      const templatevalues = {
        index: planttype,
        id: pipelineview.popup.selectedFeature.attributes.PlantId,
        name: pipelineview.popup.selectedFeature.attributes.Facility,
      };

      dispatch({
        type: ADD_TEMPLATEDETAILS,
        payload: {
          templatevalues,
        },
      });

      // Get the screen width and height
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      window.open(
        `${window.location.origin}/fi/facilityinfo`, // URL for the Power BI dashboard
        '_blank', // open in a new window
        `width=${screenWidth},height=${screenHeight}`, // set the window size
      );
    }
  };

  /**
   * Fetches the tariff sections for a given node ID.
   *
   * @param {number} nodeid - The ID of the node.
   * @return {Promise<void>} A promise that resolves when the tariff sections are fetched.
   */
  const fnFetchTariffSections = async (nodeid) => {

    
    try {
      dispatch(displaySpinner(true));

      pipelineview.map.remove(tarifflayer.properties);

      setTariffLayer({
        properties: {},
        binded: false,
      });

      const queryDetails = {
        pipelineid: props.pipelineid,
        nodeid,
        type: props.pipelinetype,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchtariffsections', queryDetails, options);

      dispatch(displaySpinner(false));

      if (response.data[0] !== undefined && response.data[0].status === 'error') {
        setStatusBase({
          show: true,
          message: 'An error occurred during execution.',
          variant: 'error',
        });
      } else if (response.data[0].status === 'no records found') {
        setStatusBase({
          show: true,
          message: 'No records found',
          variant: 'info',
        });
      } else {
        fnAddTariffSections(response.data[0].data);
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [tarifflayer, setTariffLayer] = React.useState({
    properties: {},
    binded: false,
  });
  /**
   * Adds tariff sections to the map.
   *
   * @param {Array} data - The data containing the tariff sections.
   */
  const fnAddTariffSections = (data) => {
    loadModules(
      [
        'esri/Graphic',
        'esri/layers/FeatureLayer',
        'esri/PopupTemplate',
        'esri/renderers/UniqueValueRenderer',
        'esri/Color',
      ],
      { css: true },
    ).then(([Graphic, FeatureLayer, PopupTemplate, UniqueValueRenderer, Color]) => {
      const popupTemplate = new PopupTemplate({
        title: '{Facility}',
        outFields: ['*'],
        content: PipelineChange,
        fieldInfos: [
          {
            fieldName: 'Facility',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
          {
            fieldName: 'Section',
            format: {
              digitSeparator: true,
              places: 0,
            },
          },
        ],
      });

      function PipelineChange(feature) {
        const div = document.createElement('div');

        div.innerHTML =
          'Pipeline Name : <b>' +
          feature.graphic.attributes.Facility +
          '</b> </br> Section Name : <b>' +
          feature.graphic.attributes.Section +
          '</b>';
        return div;
      }

      const pipelinegraphics = data.map((storage) => {
        return new Graphic({
          attributes: {
            ObjectId: shortid.generate(),
            Facility: storage.PipelineName,
            Section: storage.PipelineSectionName,
          },
          geometry: {
            type: 'polyline',
            paths:
              typeof storage.Coords === 'object'
                ? storage.Coords.coordinates
                : JSON.parse(storage.Coords).coordinates,
          },
        });
      });

      const tarifflayer = new FeatureLayer({
        // source: features,
        source: pipelinegraphics,
        objectIdField: 'ObjectId', // This must be defined when creating a layer from `Graphic` objects
        fields: [
          {
            name: 'ObjectId',
            type: 'oid',
          },
          {
            name: 'Facility',
            type: 'string',
          },
          {
            name: 'Section',
            type: 'string',
          },
        ],

        popupTemplate,
        geometryType: 'polyline',
        renderer: {
          type: 'simple', // autocasts as new SimpleRenderer()
          symbol: {
            type: 'simple-line', // autocasts as new SimpleMarkerSymbol()
            color: props.pipelinetype === 'gas' ? 'green' : 'red',
            width: 2,
          },
        },
        spatialReference: {
          wkid: 4326,
        },
        labelingInfo: [facilitylabel],
      });

      setTariffLayer({ properties: tarifflayer, binded: false });
    });
  };

  useEffect(() => {
    fnLoadMapView();
  }, []);

  useEffect(() => {
    if (pipelinelayer.properties.visible !== undefined) {
      // //console.log(zoomchanged);

      if (zoomchanged) {
        const zoomrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'Direction',
          field2: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.PipeineProps,
        };

        pipelinelayer.properties.renderer = zoomrenderer;
      }

      if (!zoomchanged) {
        const normalrenderer = {
          type: 'unique-value', // autocasts as UniqueValueRenderer
          field: 'CommodityHandled',
          fieldDelimiter: ', ',
          defaultSymbol: {
            type: 'simple-line', // default SimpleLineSymbol
          },
          uniqueValueInfos: facilitycolors.FullPipelineProps,
        };

        pipelinelayer.properties.renderer = normalrenderer;
      }
    }
  }, [zoomchanged]);

  useEffect(() => {
    if (pipelinelayer.binded === false && pipelinelayer.properties.visible !== undefined) {
      pipelineview.map.add(pipelinelayer.properties);

      setPipelineLayer({ ...pipelinelayer, binded: true });
    }
  }, [pipelinelayer]);

  useEffect(() => {
    if (
      jointpipelinelayer.binded === false &&
      jointpipelinelayer.properties.visible !== undefined
    ) {
      pipelineview.map.add(jointpipelinelayer.properties);

      setJointPipelineLayer({ ...jointpipelinelayer, binded: true });
    }
  }, [jointpipelinelayer]);

  useEffect(() => {
    if (tarifflayer.binded === false && tarifflayer.properties.visible !== undefined) {
      pipelineview.map.add(tarifflayer.properties);

      setTariffLayer({ ...tarifflayer, binded: true });
    }
  }, [tarifflayer]);

  useEffect(() => {
    if (
      pipelinefacilitylayer.binded === false &&
      pipelinefacilitylayer.properties.visible !== undefined
    ) {
      pipelineview.map.add(pipelinefacilitylayer.properties);
      // setToggleState({ ...togglestate, pipeline: true });
      setPipelineFacilityLayer({ ...pipelinefacilitylayer, binded: true });
    }
  }, [pipelinefacilitylayer]);

  useEffect(() => {
    if (pipelineview.map !== undefined) {
      loadModules(['esri/core/watchUtils'], {
        css: true,
      }).then(([watchUtils]) => {
        watchUtils.watch(pipelineview, 'updating', onZoomChange);
      });
    }
  }, [pipelineview]);

  useEffect(() => {
    if (props.pipelinefacilities.length > 0 && pipelineview.map !== undefined) {
      fnAddPipelineFacilities(props.pipelinefacilities);
      fnFetchPipelineMapData();

      pipelineview.popup.on('trigger-action', fnHandlePopupAction);
    }
  }, [props.pipelinefacilities, pipelineview]);

  useEffect(() => {
    if (pipelineview.map !== undefined && props.nodeid > 0) {
      fnFetchTariffSections(props.nodeid);
    }
  }, [props.nodeid]);

  return (
    <>
      <Paper className={classes.facilitymap}>
        <div
          className="pipelinefacilitywebmap"
          id="tariffmapid"
          style={{ height: props.maxheight === '' ? '500px' : props.maxheight, width: '100%' }}
          ref={tariffmapRef}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          sx={{
            position: {
              xs: 'absolute',
              lg: 'absolute',
            },
            top: '87%',
            width: '20%',
          }}
        >
          {pipelinefacilitylayer.binded === true && pipelineview.map !== undefined && (
            <>
              <Tooltip title="Map Snapshot">
                <Fab
                  size="medium"
                  color="mapsnapshot"
                  aria-label="mapsnapshot"
                  onClick={() => fnMapSnapshot(tariffmapRef.current)}
                >
                  <MapIcon sx={{ fontSize: '1.6rem' }} />
                </Fab>
              </Tooltip>
              <BaseMapsIconComp fnHandleBaseMapChange={fnHandleBaseMapChange} />
            </>
          )}
        </Grid>
      </Paper>

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      />
    </>
  );
}
