/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/self-closing-comp */
import { ThemeProvider } from '@mui/material/styles';

import React, { Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import filedownload from 'js-file-download';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Typography,
  Paper,
} from '@mui/material';

import MUIDataTable from 'mui-datatables';

import linq from 'linq';

import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';

// Api
import axios from 'axios';

// Redux imports
import { useDispatch, useSelector } from 'react-redux';

import AlertNotificationComp from '../../../../components/notification/AlertNotificationComp';
import withRouter from '../../../../components/router/WithRouter';
import { getMuiTableTheme } from '../../../../constants/muitabletheme';
import { displaySpinner } from '../../../../redux/spinner/Action';
import PipelineTariffMapComp from './PipelineTariffMapComp';
import { DEMO_USER } from '../../../../constants/user';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OilTariffTableComp(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.UserReducer.token);
  const role = useSelector((state) => state.UserReducer.userrole);

  const mapref = React.useRef(null);

  const [tariffdata, setTariffData] = React.useState([]);

  const [status, setStatusBase] = React.useState({
    show: false,
    message: '',
    variant: 'info',
  });

  const options = {
    print: false,
    selectableRows: 'none',
    resizableColumns: true,
    elevation: 0,
    download: role !== DEMO_USER,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    rowHover: true,
  };

  const [selectednode, setSelectedNode] = React.useState(0);
  const [points, setPoints] = React.useState({
    origin: '',
    destination: '',
  });

  const tariffoptions = {
    print: false,
    resizableColumns: true,
    elevation: 0,
    download: role !== DEMO_USER,
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    // onRowClick: (rowData, rowMeta) => fnTariffRecordClick(rowData, rowMeta),
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    onRowSelectionChange: (rowData) => {
      // console.log(rowData);
      // console.log(tariffdata[rowData[0].index].LiquidTariffNodeId);
      // this.setState({ rowsSelected: rowsSelected });
      if (
        points.origin !== tariffdata[rowData[0].dataIndex].PointOfOrigin ||
        points.destination !== tariffdata[rowData[0].dataIndex].PointOfDestination
      ) {
        setPoints({
          origin: tariffdata[rowData[0].dataIndex].PointOfOrigin,
          destination: tariffdata[rowData[0].dataIndex].PointOfDestination,
        });

        setSelectedNode(tariffdata[rowData[0].dataIndex].LiquidTariffNodeId);

        mapref.current.scrollIntoView();
      }
    },
  };

  const fnShowErrorMessage = () => {
    setStatusBase({ show: false, message: '', variant: 'info' });
  };

  const tariffcolumns = [
    {
      name: 'OriginPipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'OriginPipelineId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'OperatorName',
      options: {
        filter: true,
      },
    },
    {
      name: 'OwnerName',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfOrigin',
      options: {
        filter: true,
      },
    },
    {
      name: 'OriginCounty',
      options: {
        filter: true,
      },
    },
    {
      name: 'OriginStateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'DestinationPipelineName',
      options: {
        filter: true,
      },
    },
    {
      name: 'DestinationPipelineId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'PointOfDestination',
      options: {
        filter: true,
      },
    },
    {
      name: 'DestinationCounty',
      options: {
        filter: true,
      },
    },
    {
      name: 'DestinationStateName',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidTariffNumber',
      options: {
        filter: true,
      },
    },
    {
      name: 'EffectiveDate',
      options: {
        filter: true,
      },
    },
    {
      name: 'EndDate',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidTariffStatus',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidTariffRateType',
      options: {
        filter: true,
      },
    },
    {
      name: 'TermYear',
      options: {
        filter: true,
      },
    },
    {
      name: 'BPDMin',
      options: {
        filter: true,
      },
    },
    {
      name: 'BPDMax',
      options: {
        filter: true,
      },
    },
    {
      name: 'AcreageDedicationMinAcres',
      options: {
        filter: true,
      },
    },
    {
      name: 'AcreageDedicationMaxAcres',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidRateCentsPerBbl',
      options: {
        filter: true,
      },
    },
    {
      name: 'SurchargeCentsPerBbl',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidFuelType',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidTariffNodeId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
  ];

  const origindestioptions = {
    print: false,
    resizableColumns: true,
    elevation: 0,
    download: false,

    // onRowClick: (rowData, rowMeta) => fnTariffRecordClick(rowData, rowMeta),
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectableRowsHideCheckboxes: true,
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      // console.log(rowData);
      // console.log(tariffdata[rowData[0].index].LiquidTariffNodeId);
      // this.setState({ rowsSelected: rowsSelected });

      // console.log(origindestidata);

      if (origindestidata[rowsSelected] && origindestidata[rowsSelected].LiquidTariffNodeId) {
        setPoints({
          origin: origindestidata[rowsSelected].PointOfOrigin,
          destination: origindestidata[rowsSelected].PointOfDestination,
        });

        setSelectedNode(origindestidata[rowsSelected].LiquidTariffNodeId);
      }
    },
    // onCellClick: (colData, colIndex, rowIndex, dataIndex) => {},
    tableBodyMaxHeight: '500px',
  };

  const origindesticolumns = [
    {
      name: 'PointOfOrigin',
      options: {
        filter: true,
      },
    },
    {
      name: 'PointOfDestination',
      options: {
        filter: true,
      },
    },
    {
      name: 'LiquidTariffNodeId',
      options: {
        filter: true,
        display: false,
        download: false,
      },
    },
    {
      name: 'Full Tariff',
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fragment>
              <Link
                sx={{ cursor: 'pointer' }}
                onClick={() => fnDownloadSelectedTariff(value, tableMeta, updateValue)}
              >
                <DownloadIcon />
              </Link>
            </Fragment>
          );
        },
      },
    },
  ];

  const [jointpipelines, setJointPipelines] = React.useState([]);
  const [origindestidata, setOriginDestiData] = React.useState([]);
  /**
   * Fetches oil tariff data from the server based on the provided title.
   * Displays a spinner while fetching data and displays an error message if an error occurs.
   *
   * @returns {Promise<void>} A promise that resolves when the data is fetched successfully.
   */
  const fnFetchOilTariffData = async () => {
    dispatch(displaySpinner(true));

    try {
      const data = {
        origintype: null,
        originvalue: null,
        destinationtype: 'OriginPipelineName',
        destinationvalue: props.title,
      };

      const options = {
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      };

      const response = await axios.post('/api/fetchoiltarifftabledata', data, options);

      dispatch(displaySpinner(false));

      if (response.data[0].status.code === undefined) {
        if (response.data[0].status === 'error') {
          setStatusBase({
            show: true,
            message: 'Error while fetching records',
            variant: 'error',
          });
        } else if (response.data[0].status == 'no records found') {
          setStatusBase({
            show: true,
            message: 'Oil Tariff data not found',
            variant: 'info',
          });
        } else if (response.data[0].data.length > 0) {
          setTariffData(response.data[0].data);

          const groupeddata = [];
          const od_combis = [];

          response.data[0].data.forEach((tarf) => {
            const combi = `${tarf.PointOfOrigin} - ${tarf.PointOfDestination} - ${tarf.LiquidTariffNodeId}`;

            if (od_combis.indexOf(combi) === -1 && tarf.EndDate === null) {
              od_combis.push(combi);
              const tdata = {
                PointOfOrigin: tarf.PointOfOrigin,
                PointOfDestination: tarf.PointOfDestination,
                LiquidTariffNodeId: tarf.LiquidTariffNodeId,
              };

              groupeddata.push(tdata);
            }
          });

          setOriginDestiData(groupeddata);

          const multiplepipelines = linq
            .from(response.data[0].data)
            .where(
              (a) =>
                a.OriginPipelineId !== props.pipelineid ||
                a.DestinationPipelineId !== props.pipelineid,
            )
            .toArray();

          if (multiplepipelines.length > 0) {
            const jpipes = [];

            multiplepipelines.forEach((e) => {
              if (e.OriginPipelineId !== props.pipelineid && !jpipes.includes(e.OriginPipelineId)) {
                jpipes.push(e.OriginPipelineId);
              }

              if (
                e.DestinationPipelineId !== props.pipelineid &&
                !jpipes.includes(e.DestinationPipelineId)
              ) {
                jpipes.push(e.DestinationPipelineId);
              }
            });

            setJointPipelines(jpipes);
          }
        }
      } else {
        setStatusBase({
          show: true,
          message: 'Error while fetching tariff data. Please try again.',
          variant: 'error',
        });
      }
    } catch (error) {
      // Hide the spinner and handle errors
      dispatch(displaySpinner(false));

      let errorMessage = 'Error occurred while fetching data';

      if (error.response && error.response.status === 500) {
        errorMessage = 'Server error occurred. Please try again later.';
        navigate('/', { replace: true });
      }

      // Set the error message state
      setStatusBase({
        show: true,
        message: errorMessage,
        variant: 'error',
      });
    }
  };

  const [componentwindow, setComponentWindow] = React.useState(false);

  const fnOpenComponent = () => {
    // this.setState({ componentwindow: true, selectedgasplantid: record.GasPlantId });
    setComponentWindow(true);
  };

  const fnCloseComponent = () => {
    setComponentWindow(false);
  };

  // Download selected tariff data
  const fnDownloadSelectedTariff = (value, tableMeta, updateValue) => {
    const nodeid = tableMeta.tableData[tableMeta.rowIndex].LiquidTariffNodeId;
    // console.log(nodeid);

    const tariffdatabynodeid = linq
      .from(tariffdata)
      .where((a) => a.LiquidTariffNodeId === nodeid)
      .toArray();

    const fields = Object.keys(tariffdatabynodeid[0]);

    // const pos = fields.findIndex((str) => str.includes('Id'));

    // fields.splice(pos, 1);

    const replacer = (key, rval) => {
      return rval === null ? '' : rval;
    };

    // const csv = tariffdatabynodeid.map(function (row) {
    //   return fields
    //     .map(function (fieldName) {
    //       if (fieldName !== 'LiquidTariffNodeId' || fieldName !== 'Coords') {
    //         return JSON.stringify(row[fieldName], replacer);
    //       }
    //     })
    //     .join(',');
    // });
    let csv = tariffdatabynodeid.map((row) => {
      return fields
        // .filter((fieldName) => fieldName !== 'LiquidTariffNodeId' && fieldName !== 'Coords')
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',');
    });

    csv.unshift(fields.join(',')); // add header column
    csv = csv.join('\r\n');

    filedownload(csv, 'tariff.csv');
  };

  useEffect(() => {
    if (props.title !== '') {
      fnFetchOilTariffData();
    }
  }, []);

  return (
    <>
      {tariffdata.length > 0 && (
        <>
          <Button sx={{ ml: '10px' }} onClick={fnOpenComponent} size="small">
            Map Tariff Explorer: Click and Uncover Section Details
          </Button>

          <ThemeProvider theme={getMuiTableTheme}>
            <MUIDataTable data={tariffdata} columns={tariffcolumns} options={options} />
          </ThemeProvider>
        </>
      )}

      <AlertNotificationComp
        variant={status.variant}
        onClose={fnShowErrorMessage}
        message={status.message}
        display={status.show}
      ></AlertNotificationComp>
      <Dialog
        fullScreen
        // maxWidth="lg"
        open={componentwindow}
        onClose={fnCloseComponent}
        TransitionComponent={Transition}
      >
        <DialogTitle id="customized-dialog-title" onClose={fnCloseComponent}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" color="textprimary">
                Oil Tariff Explorer -{' '}
                {tariffdata.length > 0 ? tariffdata[0].OriginPipelineName : ''}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={fnCloseComponent}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowY: 'unset !important' }}>
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Grid item xs={6}>
                <Paper>
                  <MUIDataTable
                    data={origindestidata}
                    columns={origindesticolumns}
                    options={origindestioptions}
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <div ref={mapref} style={{ width: '98.7%', margin: '10px', marginTop: '0' }}>
                  <PipelineTariffMapComp
                    facilitydata={props.facilitydata}
                    pipelinetype="liquid"
                    nodeid={selectednode}
                    pipelineid={props.pipelineid}
                    jointpipelines={jointpipelines}
                    pipelinefacilities={props.pipelinefacilities}
                    maxheight="600px"
                  />
                </div>
              </Grid>
            </Grid>
            {/* <MUIDataTable data={tariffdata} columns={tariffcolumns} options={tariffoptions} />
             */}
          </>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withRouter(OilTariffTableComp);
